import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Navbar from "../../home-module/navbar-page/Navbar";

const S2gPage = ({name}) => {

  const navigate = useNavigate();

  const handlenow = () => {
    navigate('/login')
  }

  return (
    <div className="smartCard-page">
      <Navbar />
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <span>{`${name}  >  S2G`}</span>
                <h1 className="banner-text-gradient">S2G</h1>
                <h6>
                  Government procures Innovation | Public Sector Innovation
                </h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn-laun">Get in Touch</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage mt-5">
                <h4>What is ‘S2G Buy Day’ </h4><h4 >(Startup to Government)?</h4>
                <p className="mt-3">
                A unique market access initiative with the objective of supporting entrepreneurs in direct procurement of products / services from startups by various Government departments. This initiative injects innovation in the government departments which accelerates the efficiency and efficacy of several schemes.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="s2g-crd-img mt-5">
                <img
                  className="s2g-imag"
                  src={"/images/S2G-buyday.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>

          <div className="how-works-div">
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="works-section mt-5">
                  <h4>How it Works?</h4>
                  <p>
                  Here are the detailed steps of the S2G process for startups.
                  </p>
                </div>
                <div className="track"></div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <div className="layer-bg-smcrd">
                  <div className="works-mesh-layer"></div>
                  <div className="s2g-how-works-details">
                    <div className="sm-crd-process">
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/smcrd-reg.png"}
                              alt="registration"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              01 <span>EOI:</span>
                            </h3>
                            <p>
                              Express your interest in participating in S2G as
                              announced by StartupTN
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/smcrd.png"}
                              alt="signup"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              02 <span>Submit your Business Model :</span>
                            </h3>
                            <p>
                              Submit your business model and how you can provide
                              innovative solutions to the State departments
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/fill-application.png"}
                              alt="fill-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              03 <span>Pitch Your Idea:</span>
                            </h3>
                            <p> Pitch your idea to Senior Officials </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/submit-application.png"}
                              alt="submit-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <div>
                              <h3>
                                04 <span> Support to Government:</span>
                              </h3>
                            </div>
                            <div>
                              <p>Support the Government and get innovative.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/track-application.png "}
                              alt="track-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              05 <span>Track Your Application:</span>
                            </h3>
                            <p>
                              Keep track of your application status right there
                              on the website.
                            </p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
        <div className="container-fluid">
          <div className="why-smartcrd-sec">
            <div className="s2g-page-details">
              <h6>Model</h6>
              <p>
              In this exceptional model, the Government departments will publish their requirements in the format of problem statements. Capable startups will be able to sell their innovative products / services as solutions to that particular problem statement.
              </p>
            </div>
            <div className="s2g-page-details">
              <h6>Purchase Amount</h6>
              <p>
                With regard to the above, Government departments and state
                public sector undertakings can make a purchase up to Rs 50 lakhs
                from Tamil Nadu based startups.
              </p>
            </div>
            <div className="s2g-page-details">
              <h6>Organized By</h6>
              <p>
              S2G Buy Day is organized by two or three departments or PSUs every month with problem statements. The startups present their solutions and shortlisted ones are issued the orders.
              </p>
            </div>
            <div className="s2g-page-details">
              <h6>Priority</h6>
              <p>
                Thus, S2G Buy Day gives priority to startups and relieves them
                from competing with bigger market players. It further eases the
                process of doing business with Government departments and PSUs.
              </p>
            </div>
          </div>

          <div className="join-us-now-sec">
            <Container>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="join-us-now-box">
                    <div className="join-us-now-content">
                      <h3>S2G - Boosting Innovation</h3>
                      <p>
                        Direct Startup Procurement for Government Efficiency
                      </p>
                      <Button
                        variant="contained"
                        size="large"
                        className="join-now-btn"
                        onClick={handlenow}
                      >
                        Join us Now
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default S2gPage;
