import {
  Button,
  Grid,
  Pagination,
  Stack,
  TablePagination,
} from "@mui/material";
import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import Smartcardfilter from "./Smartcardfilter";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";

const Smartcardlist = () => {
  const {
    navigator,
    setPageLoading,
    setShowViewbtn,
    setEditBtn,
    setUserProfileId,
    setUserProfileParsona,
  } = useContext(DataContext);

  const [searchInputValue, setSearchInputValue] = useState();

  const [smartcardList, setSmartcardList] = useState([]);
  const [page, setPage] = useState(0);
  const [filterVal, setFilterVal] = useState(0);
  const [selectedPersonas, setSelectedPersonas] = useState("All");
  const [selectedProfileType, setSelectedProfileType] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  useDidMountEffect(() => {
    listApi();
  }, [searchInputValue, filterVal, selectedProfileType, rowsPerPage, page]);

  const listApi = () => {
    setSmartcardList([]);
    setPageLoading(true);

    var data = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      searchString: searchInputValue,
      smartcardstatus: filterVal,
      // persona: selectedPersonas,
      profileType: selectedProfileType,
    };

    apiService("smartcard/admin/list", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setCount(result.data.count);
          if (result.data.responsemodelList) {
            var data = result.data.responsemodelList;
            setSmartcardList(data);
            data.forEach((element) => {
              if (element.email) {
                element.email = cryptodecryptJS(element.email);
              }
              if (element.phone) {
                element.phone = cryptodecryptJS(element.phone);
              }
            });
          }
        }
      })
      .catch((err) => {});
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleUpdateStatus = (id, value) => {
    setPageLoading(true);
    var data = {
      smartcardstatus: value,
      id: id,
    };
    apiService("userprofile/smartcard/status/save", data, "post")
      .then((res) => {
        if (res && res.data && res.data.responseStatus === "Success") {
          setPageLoading(false);
          listApi();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const handleProfileNavigate = (id, name) => {
  //   setUserProfileId(id)
  //   setUserProfileParsona(name)
  //   setEditBtn(false)
  //   setShowViewbtn(0)
  //   debugger
  //   Number(localStorage.setItem("userpersonaId", id));
  //   localStorage.setItem("userpersona", name)
  //   debugger
  //   if (name === "INVESTOR") {
  //     navigator("/investor/profile");
  //   } else if (name === "STARTUP") {
  //     navigator("/startup/profile");
  //   } else if (name === "MENTOR") {
  //     navigator("/mentor/profile");
  //   } else if (name === 'INNOVATOR') {
  //     navigator('/innovator/profile')
  //   } else if (name === 'STARTUP-ECO') {
  //     navigator('/ecosystem/profile')
  //   } else if (name === 'STARTUPS-SERVPROV') {
  //     navigator('/service-provider/profile')
  //   } else if (name === 'ASPIRANT') {
  //     navigator('/aspirant/profile')
  //   }
  // }
  const handleProfileNavigate = (value) => {
    setEditBtn(false);
    setShowViewbtn(0);
    window.open(value, "_blank");
    debugger;
  };
  return (
    <div>
      <Smartcardfilter
        setSearchInputValue={setSearchInputValue}
        filterVal={filterVal}
        setFilterVal={setFilterVal}
        page={page}
        smartcardList={smartcardList}
        searchInputValue={searchInputValue}
        selectedPersonas={selectedPersonas}
        setSelectedPersonas={setSelectedPersonas}
        rowsPerPage={rowsPerPage}
        selectedProfileType={selectedProfileType}
        setSelectedProfileType={setSelectedProfileType}
      />
      <div className="event-datas-list py-5">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={4}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="table_main_div">
              {smartcardList.length > 0 ? (
                <>
                  <TableContainer component={Paper}>
                    <TablePagination
                      component="div"
                      count={count}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      style={{ float: "right", margin: "0px" }}
                      sx={{ margin: 0 }}
                    />

                    <Table
                      sx={{
                        borderCollapse: "separate",
                        borderSpacing: "0 10px",
                      }}
                    >
                      <TableHead>
                        <TableRow className="titles1">
                          <TableCell>Name</TableCell>
                          <TableCell>Mail ID</TableCell>
                          <TableCell>Phone Number</TableCell>
                          {selectedProfileType === 0 && (
                            <TableCell>Profile Type</TableCell>
                          )}
                          <TableCell align="center">Submitted At</TableCell>
                          <TableCell align="center">Status</TableCell>

                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {smartcardList &&
                          smartcardList.map((list, i) => (
                            <>
                              <TableRow
                                key={i}
                                sx={{
                                  position: "relative",
                                  "&:after": {
                                    content: '""',
                                    position: "absolute",
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    borderRadius: "20px",
                                    pointerEvents: "none",
                                    marginBottom: "-1px",
                                  },
                                  "& > *": {
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    "&:last-of-type": {
                                      borderTopRightRadius: "20px",
                                      borderBottomRightRadius: "20px",
                                    },
                                  },
                                  marginBottom: "10px",
                                }}
                                className="Sup-list"
                              >
                                <TableCell className="list-1">
                                  <div
                                    onClick={() =>
                                      handleProfileNavigate(list.website)
                                    }
                                  >
                                    <span className="list-1-clr">
                                      {/* {list.name ? list.name : '-'} */}
                                      {list.name ? (
                                        list.name.length > 15 ? (
                                          <>{list.name.slice(0, 15)}...</>
                                        ) : (
                                          <>{list.name}</>
                                        )
                                      ) : (
                                        <p>-</p>
                                      )}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <span className="list-ran">
                                    {list.email ? list.email : "-"}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span className="list-ran">
                                    {list.phone ? list.phone : "-"}
                                  </span>
                                </TableCell>
                                {selectedProfileType === 0 && (
                                  <TableCell>
                                    <span className="list-ran">
                                      {list.role ? list.role : "-"}
                                    </span>
                                  </TableCell>
                                )}
                                <TableCell align="center">
                                  {list.smartcardAppliedTime
                                    ? list.smartcardAppliedTime
                                    : "-"}
                                </TableCell>
                                <TableCell align="center">
                                  <span className="list2-ran">
                                    {list.smartcardstatus === 0 && <p>-</p>}
                                    {list.smartcardstatus === 1 && (
                                      <p className="list-5-clr-smcr">
                                        Requested
                                      </p>
                                    )}
                                    {list.smartcardstatus === 2 && (
                                      <p className="list-6-clr-smcr">
                                        Under Process
                                      </p>
                                    )}
                                    {list.smartcardstatus === 3 && (
                                      <p className="list-5-clr-smc">
                                        Delivered
                                      </p>
                                    )}
                                  </span>
                                </TableCell>

                                <TableCell align="center">
                                  <span className="list2-ran">
                                    {list.smartcardstatus === 0 && <p>-</p>}
                                    {list.smartcardstatus === 1 && (
                                      <Button
                                        className="button-smart"
                                        variant="contained"
                                        onClick={() =>
                                          handleUpdateStatus(list.id, 2)
                                        }
                                        sx={{ textTransform: "none" }}
                                      >
                                        Mark it as Under Process
                                      </Button>
                                    )}
                                    {list.smartcardstatus === 2 && (
                                      <Button
                                        className="button-smart"
                                        variant="contained"
                                        onClick={() =>
                                          handleUpdateStatus(list.id, 3)
                                        }
                                        sx={{ textTransform: "none" }}
                                      >
                                        Mark it as Delivered
                                      </Button>
                                    )}
                                    {list.smartcardstatus === 3 && <p>-</p>}
                                  </span>
                                </TableCell>
                              </TableRow>
                              <span className="mt-2 mr-3"> </span>
                            </>
                          ))}
                      </TableBody>
                    </Table>

                    <TablePagination
                      component="div"
                      count={count}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      style={{ float: "right", margin: "0px" }}
                      sx={{ margin: 0 }}
                    />
                  </TableContainer>
                </>
              ) : (
                <>
                  <div className="text-center nodatacss_admin">
                    <h5 className="mb-4">Smartcard List not found</h5>
                    <div>
                      <img
                        src="/images/blue horizontal.png"
                        alt="blue horizontal.png"
                        className="logo_dash img-fluid"
                      />
                    </div>
                    <div className="mt-5">
                      <h3 className="title_p opa">StartupTN 3.0</h3>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Smartcardlist;
