import React, { useState } from "react";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import { Container, Grid } from "@mui/material";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";
import { useContext } from "react";
import DataContext from "../../../context/DataContext";

const MapSection = () => {
  const { t } = useContext(DataContext);
  const lang = localStorage.getItem("lang");
  const [selectedHub, setselectedHub] = useState({});

  // useDidMountEffect(() => {
  //   mapGetApi();
  // }, [lang]);
  useDidMountEffect(() => {
    mapGetApi();
    // const defaultDistrict = lang === "en" ? "chennai" : "சென்னை";
    // setSelectedDistrict(selectedHub ? selectedHub : defaultDistrict);
    // setDistrictName(selectedHub ? selectedHub : defaultDistrict);
  }, [lang]);

  const [districtMapDetails, setDistrictMapDetails] = useState([]);
  // const mapGetApi = () => {
  //   const req = {
  //     lang: lang === "en" ? "ENG" : "TAM",
  //   };
  //   apiService("hub/list", req, "unauthpost")
  //     .then((result) => {
  //       if (result && result.data && result.data.hubs) {
  //         setDistrictMapDetails(result.data.hubs);
  //         const defaultHubName = lang === "en" ? "chennai" : "சென்னை";
  //         const filtered = result.data.hubs.filter((item) =>
  //           item.name.toLowerCase().includes(defaultHubName)
  //         );
  //         setselectedHub(filtered[0] || {});  
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching hub list:", err);
  //     });
  // };
  const mapGetApi = () => {
    var req = {
      lang: lang === "en" ? "ENG" : "TAM",
    };
    apiService("hub/list", req, "unauthpost")
      .then((result) => {
        if (result && result.data && result.data.hubs) {
          setDistrictMapDetails(result.data.hubs);
          let selectDIS = lang === "en" ? selectedDistrict : selectedTamilDistrict
          let defauldDIS = lang === "en" ? "chennai" : "சென்னை"
          const filtered = result.data.hubs.filter((item) =>
            item.name
              .toLowerCase()
              .includes(selectDIS ? selectDIS : defauldDIS)
          );
          setselectedHub(filtered[0]);
          setLangName(lang === "en" ? selectedDistrict : selectedTamilDistrict)
          debugger;
          // result.data.hubs.forEach(element => {

          //     if (element.name === 'chennai') {
          //         setselectedHub(element)
          //     }
          // });
        }
      })
      .catch((err) => { });
  };
  const [selectedDistrict, setSelectedDistrict] = useState(
    "chennai"
  );
  const [selectedTamilDistrict, setSelectedTamilDistrict] = useState(
    "சென்னை"
  );
  const [districtName, setDistrictName] = useState(
    "chennai"
  );
  const [districtTamilName, setDistrictTamilName] = useState(
    "சென்னை"
  );
  const [langName, setLangName] = useState(
    lang === "en" ? "chennai" : "சென்னை"
  );
  const handleDistrict = (value, tamValue) => {
    debugger;
    setSelectedDistrict(value);
    setSelectedTamilDistrict(tamValue)
    setDistrictName(value);
    setDistrictTamilName(tamValue)
    setLangName(lang === "en" ? value : tamValue)
    const filtered = districtMapDetails.filter((item) =>
      item.name.toLowerCase().includes(lang === "en" ? value.toLowerCase() : tamValue)
    );
    setselectedHub(filtered[0]);
    debugger;
    // debugger
    // districtMapDetails.forEach(element => {

    //     if (element.name === value) {
    //         setselectedHub(element)
    //     }
    // });
  };
  const handleMouseEnter = (value, tamValue) => {
    setSelectedDistrict(value);
    setSelectedTamilDistrict(tamValue)
    setLangName(lang === "en" ? value : tamValue)
  };
  const handleMouseLeave = () => {
    setSelectedDistrict(districtName);
    setSelectedTamilDistrict(districtTamilName)
    setLangName(lang === "en" ? districtName : districtTamilName)

  };
  const [filterMapValue, setFilterMapValue] = useState("");
  const handleFilterMap = (value) => {
    setFilterMapValue(value);
  };

  return (
    <section className="presence-section">
      <div className="presence">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Container>
              <div className="map-div">
                <div className="map-div-bg">
                  <img
                    src="/images/map/map-bg.png"
                    alt=""
                    className="img-fluid map-bg"
                  />
                  <img
                    src="/images/map/Thiruvallur.png"
                    alt=""
                    className="img-fluid Thiruvallur"
                  />
                  <img
                    src="/images/map/Chennai.png"
                    alt=""
                    className="img-fluid Chennai c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("chennai", "சென்னை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Chengalpattu.png"
                    alt=""
                    className="img-fluid Chengalpattu"
                  />
                  <img
                    src="/images/map/Kancheepuram.png"
                    alt=""
                    className="img-fluid Kancheepuram"
                  />
                  <img
                    src="/images/map/Viluppuram.png"
                    alt=""
                    className="img-fluid Viluppuram"
                  />
                  <img
                    src="/images/map/Mayiladuthurai.png"
                    alt=""
                    className="img-fluid Mayiladuthurai"
                  />
                  <img
                    src="/images/map/Ariyalur.png"
                    alt=""
                    className="img-fluid Ariyalur"
                  />
                  <img
                    src="/images/map/Perambalur.png"
                    alt=""
                    className="img-fluid Perambalur"
                  />
                  <img
                    src="/images/map/Ranipet.png"
                    alt=""
                    className="img-fluid Ranipet"
                  />
                  <img
                    src="/images/map/Vellore.png"
                    alt=""
                    className="img-fluid Vellore"
                  />
                  <img
                    src="/images/map/Tiruvannamalai.png"
                    alt=""
                    className="img-fluid Tiruvannamalai"
                  />
                  <img
                    src="/images/map/Tirupathur.png"
                    alt=""
                    className="img-fluid Tirupathur"
                  />
                  <img
                    src="/images/map/Krishnagiri.png"
                    alt=""
                    className="img-fluid Krishnagiri c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("hosur", "ஓசூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Dharmapuri.png"
                    alt=""
                    className="img-fluid Dharmapuri"
                  />
                  <img
                    src="/images/map/Kallakurichi.png"
                    alt=""
                    className="img-fluid Kallakurichi"
                  />
                  <img
                    src="/images/map/Cuddalore.png"
                    alt=""
                    className="img-fluid Cuddalore c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("cuddalore", "கடலூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Salem.png"
                    alt=""
                    className="img-fluid Salem c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("salem", "சேலம்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Erode.png"
                    alt=""
                    className="img-fluid Erode c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("erode", "ஈரோடு")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Nilgiris.png"
                    alt=""
                    className="img-fluid Nilgiris"
                  />
                  <img
                    src="/images/map/Thanjavur.png"
                    alt=""
                    className="img-fluid Thanjavur c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter(
                        "thanjavur", "தஞ்சாவூர்"
                      )
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Tiruvarur.png"
                    alt=""
                    className="img-fluid Tiruvarur"
                  />
                  <img
                    src="/images/map/Nagapattinam.png"
                    alt=""
                    className="img-fluid Nagapattinam"
                  />
                  <img
                    src="/images/map/Tiruchirappalli.png"
                    alt=""
                    className="img-fluid Tiruchirappalli c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter(
                        "tiruchirappalli", "திருச்சி"
                      )
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Namakkal.png"
                    alt=""
                    className="img-fluid Namakkal"
                  />
                  <img
                    src="/images/map/Karur.png"
                    alt=""
                    className="img-fluid Karur"
                  />
                  <img
                    src="/images/map/Tiruppur.png"
                    alt=""
                    className="img-fluid Tiruppur"
                  />
                  <img
                    src="/images/map/Coimbatore.png"
                    alt=""
                    className="img-fluid Coimbatore c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("coimbatore", "கோயம்புத்தூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Dindigul.png"
                    alt=""
                    className="img-fluid Dindigul"
                  />
                  <img
                    src="/images/map/Pudukkottai.png"
                    alt=""
                    className="img-fluid Pudukkottai"
                  />
                  <img
                    src="/images/map/Madurai.png"
                    alt=""
                    className="img-fluid Madurai c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter("madurai", "மதுரை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Theni.png"
                    alt=""
                    className="img-fluid Theni"
                  />
                  <img
                    src="/images/map/Sivagangai.png"
                    alt=""
                    className="img-fluid Sivagangai"
                  />
                  <img
                    src="/images/map/Ramanathapuram.png"
                    alt=""
                    className="img-fluid Ramanathapuram"
                  />
                  <img
                    src="/images/map/Virudhunagar.png"
                    alt=""
                    className="img-fluid Virudhunagar"
                  />
                  <img
                    src="/images/map/Tenkasi.png"
                    alt=""
                    className="img-fluid Tenkasi"
                  />
                  <img
                    src="/images/map/Thoothukudi.png"
                    alt=""
                    className="img-fluid Thoothukudi"
                  />
                  <img
                    src="/images/map/Tirunelveli.png"
                    alt=""
                    className="img-fluid Tirunelveli c-pointer"
                    onMouseEnter={() =>
                      handleMouseEnter(
                        "tirunelveli", "திருநெல்வேலி"
                      )
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Kanyakumari.png"
                    alt=""
                    className="img-fluid Kanyakumari"
                  />

                  <img
                    src="/images/map/Chennai_hub.png"
                    alt=""
                    className="img-fluid Chennai_hub c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "chennai" : "சென்னை")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("chennai", "சென்னை")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("chennai", "சென்னை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Hosur_hub.png"
                    alt=""
                    className="img-fluid Hosur_hub c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "hosur" : "ஓசூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("hosur", "ஓசூர்")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("hosur", "ஓசூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Cuddalore_hub.png"
                    alt=""
                    className="img-fluid Cuddalore_hub c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "cuddalore" : "கடலூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("cuddalore", "கடலூர்")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("cuddalore", "கடலூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Salem_hub.png"
                    alt=""
                    className="img-fluid Salem_hub c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "salem" : "சேலம்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("salem", "சேலம்")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("salem", "சேலம்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Erode_hub.png"
                    alt=""
                    className="img-fluid Erode_hub c-pointer"
                    style={{
                      display:
                        langName === (lang === "en" ? "erode" : "ஈரோடு")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("erode", "ஈரோடு")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("erode", "ஈரோடு")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Coimbatore_hub.png"
                    alt=""
                    className="img-fluid Coimbatore_hub c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "coimbatore" : "கோயம்புத்தூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("coimbatore", "கோயம்புத்தூர்")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("coimbatore", "கோயம்புத்தூர்")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Tiruchirappalli_hub.png"
                    alt=""
                    className="img-fluid Tiruchirappalli_hub c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "tiruchirappalli" : "திருச்சி")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict(
                        "tiruchirappalli", "திருச்சி"
                      )
                    }
                    onMouseEnter={() =>
                      handleMouseEnter(
                        "tiruchirappalli", "திருச்சி"
                      )
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Thanjavur_hub.png"
                    alt=""
                    className="img-fluid Thanjavur_hub c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "thanjavur" : "தஞ்சாவூர்")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("thanjavur", "தஞ்சாவூர்")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter(
                        "thanjavur", "தஞ்சாவூர்"
                      )
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Madurai_hub.png"
                    alt=""
                    className="img-fluid Madurai_hub c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "madurai" : "மதுரை")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict("madurai", "மதுரை")
                    }
                    onMouseEnter={() =>
                      handleMouseEnter("madurai", "மதுரை")
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                  <img
                    src="/images/map/Tirunelveli_hub.png"
                    alt=""
                    className="img-fluid Tirunelveli_hub c-pointer"
                    style={{
                      display:
                        langName ===
                          (lang === "en" ? "tirunelveli" : "திருநெல்வேலி")
                          ? "block"
                          : "none",
                    }}
                    onClick={() =>
                      handleDistrict(
                        "tirunelveli", "திருநெல்வேலி"
                      )
                    }
                    onMouseEnter={() =>
                      handleMouseEnter(
                        "tirunelveli", "திருநெல்வேலி"
                      )
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                </div>
              </div>
              <div className="map-filiter-home">
                <div
                  className="map-filter-content mr-3 "
                  onClick={() => handleFilterMap("Hub")}
                >
                  <div
                    className={
                      filterMapValue === "Hub"
                        ? "seletedHub defauildRound"
                        : "seletedHub defauildRound"
                    }
                  ></div>
                  <span className="lang">{t("Map.selectedhub")}</span>
                </div>
                <div
                  className="map-filter-content mr-3 "
                  onClick={() => handleFilterMap("DistrictsMap")}
                >
                  <div
                    className={
                      filterMapValue === "DistrictsMap"
                        ? "seletedDistrictsMapped defauildRound"
                        : "seletedDistrictsMapped defauildRound"
                    }
                  ></div>
                  <span>{t("Map.districtsmapped")}</span>
                </div>
                <div
                  className="map-filter-content mr-3 "
                  onClick={() => handleFilterMap("Hubs")}
                >
                  <div
                    className={
                      filterMapValue === "Hubs"
                        ? "seletedHubs defauildRound"
                        : "seletedHubs defauildRound"
                    }
                  ></div>
                  <span>{t("Map.hubs")}</span>
                </div>
                <div
                  className="map-filter-content mr-3 "
                  onClick={() => handleFilterMap("seletedDistricts")}
                >
                  <div
                    className={
                      filterMapValue === "seletedDistricts"
                        ? "seletedDistricts defauildRound"
                        : "seletedDistricts defauildRound"
                    }
                    style={{ width: '15px' }}
                  ></div>
                  <span>{t("Map.districts")}</span>
                </div>
              </div>
            </Container>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="map-content">
              <h2 className="heading-line">
                <span className="heading-line_span"></span>{" "}
                <span className="chennai-text">
                  {selectedHub && selectedHub.name}
                </span>
              </h2>

              <p className="map-detail-parag">
                {selectedHub && selectedHub.brief}
              </p>
              <p>
                {t("Map.districts")} -{" "}
                <span className="district_span">
                  {selectedHub && selectedHub.districts}
                </span>
              </p>
              <div className="map-boxdetails">
                <div className="map-det-flex">
                  <div className="map-boxdetails-div">
                    <p id='startUpCount'>{selectedHub && selectedHub.startups}</p>
                    <p >{t("Map.startups")}</p>
                  </div>
                  <div className="map-boxdetails-div">
                    <p id='startUpCount'>{selectedHub && selectedHub.universities}</p>
                    <p>{t("Map.educationalinstitutions")}</p>
                  </div>
                  <div className="map-boxdetails-div">
                    <p id='startUpCount'>{selectedHub && selectedHub.incubationCenter}</p>
                    <p>{t("Map.incubationcenters")}</p>
                  </div>
                </div>
                <div className="map-det-flex">
                  <div>
                    <div className="map-boxdetailseds-div mb-3">
                      <p>{selectedHub && selectedHub.address}</p>
                    </div>
                    <div className="hub-details">
                      <div className="hub_img-div">
                        <img
                          src={
                            selectedHub && selectedHub.inchargeLogoName
                              ? selectedHub && selectedHub.inchargeLogoName
                              : "/images/user.png"
                          }
                          alt="hub-img"
                          className="hub_img"
                        />
                      </div>
                      <div className="hub_name_details">
                        <p className="hubname mb-0">
                          {selectedHub && selectedHub.name} &nbsp;
                          {t("Map.incharge")}
                        </p>
                        <p className="hubhead mb-0">
                          {selectedHub && selectedHub.inchargeName}
                        </p>
                        {selectedHub && selectedHub.inchargEmail && (
                          <p className="hubmail mb-0">
                            <img src="/images/hub-mail.png" alt="hubmailid" />{" "}
                            {cryptodecryptJS(selectedHub.inchargEmail)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="map-boxdetailseds-div-map ">
                    <iframe
                      src={selectedHub && selectedHub.mapLink}
                      width="100"
                      height="200"
                      style={{ border: 0 }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      title="Map location of selected hub"
                      aria-label="Map location of selected hub"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default MapSection;
