import React, { useContext, useEffect, useState } from "react";
import { Grid, Card, Stack, Pagination } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Pagination as SwiperPagination } from "swiper/modules";
import "swiper/css";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import DataContext from "../../../../context/DataContext";
import { useParams } from "react-router-dom";
import { LiaCheckDoubleSolid } from "react-icons/lia";

const SmartProfilePage = () => {
  const { navigator, setPageLoading, getApi, userProfileId, para, listDatas, setListDatas, ratelist, ratinglist, ratingData } = useContext(DataContext)
  const { id } = useParams()
  let smartCardKey = localStorage.getItem('smartCard')
  let useIds = localStorage.getItem('id')
  let idsmartcrd = localStorage.getItem('joinusId')
  var token = localStorage.getItem("jwttoken");
  const [showFull, setShowFull] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  let ids
  if (id !== null) {
    ids = atob(id)
  }
  useDidMountEffect(() => {
    getApi();
    ratelist()
    portfolioApi()
  }, [userProfileId,]);


  const toggleReadMore = () => {
    setShowFull(!showFull);
  };
  // const fullText = "Apple Inc. is a global Apple Inc. is a globalApple Inc. is a globalApple Inc. is a global";
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const wordLimit = 30;
  const displayText = showFull ? para : truncateText(para, wordLimit);
  const isTruncated = para.split(" ").length > wordLimit;

  useDidMountEffect(() => {
    getSmartCardApi()
    listApi()
  }, [page])

  useEffect(() => {
    size();
  }, []);

  const [screenSize, setScreenSize] = useState(4);
  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(1);
    } else {
      setScreenSize(1);
    }
  };
  // const [smartCardData, setSmartCardData] = useState([])
  const [privilegesData, setPrivilegesData] = useState([])
  const getSmartCardApi = () => {
    apiService(`smartcard/get?id=${ids}`, '', 'get').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        if (result.data.privileges) {
          // setSmartCardData(result.data);
          setPrivilegesData(result.data.privileges)
        }
      }
    }).catch((err) => {

    });
  }
  const listApi = () => {

    setListDatas([])
    setPageLoading(true)
    var req = {
      listSize: 6,
      smartcardId: Number(idsmartcrd),
      pageNumber: page,
      requestedUserId: Number(useIds),
    }
    apiService(`smartcard/product/list`, req, "post")
      .then((result) => {
        setPageLoading(false)

        if (result && result.data) {
          setTotalPage(result.data.totalPages)
          if (result.data.products) {
            var data = result.data.products;
            console.log(data)
            setListDatas(data)
          }
        }

      })
      .catch((err) => {
        console.log(err)
      });

  };
  const handleCarts = (val) => {
    if (token) {
      setPageLoading(true)
      var addchar = val.additionalChargePrice ? val.additionalChargePrice : false
      const data = {
        enableOfferDuration: val.enableOfferDuration,
        smartCardProductServiceId: Number(val.id),
        requestedUserId: Number(useIds),
        smartCardId: Number(val.smartcardId),
        name: val.name,
        priceRangeFrom: Number(val.priceRangeFrom ? val.priceRangeFrom : ""),
        priceRangeTo: Number(val.priceRangeTo ? val.priceRangeTo : ""),
        priceType: val.priceType,
        smartcardPriceFrom: Number(val.smartcardPriceFrom),
        smartcardPriceTo: Number(val.smartcardPriceTo ? val.smartcardPriceTo : ""),
        discount: val.discount !== 0 ? val.discount : (val.smartcardPriceFrom ? val.smartcardPriceTo : null),
        offerTime: val.offerTime,
        offerDuration: val.offerDuration,
        additionalCharges: addchar,
        quantity: 1,
      };
      apiService('smartcard/servicerequest/cart/add', data, "post")
        .then((result) => {
          setPageLoading(false)
          if (result && result.data.responseStatus === 'Success') {
            navigator(`/smartcard/${btoa(id)}/addCart`)
          }
        }).catch((err) => {
          console.log(err);

        });
    } else {
      localStorage.setItem('smartCard', '')
      const currentPath = window.location.pathname;
      localStorage.setItem('currentPath', currentPath);
      navigator('/login')
    }
  }
  const [PortfolioData, setPortfolioData] = useState([])
  const portfolioApi = () => {
    setPageLoading(true)
    var req = {
      pageNumber: page,
      listSize: 100,
      smartcardId: Number(idsmartcrd)
    }
    apiService('smartcard/servicerequest/portfolio/list', req, "post")
      .then((res) => {
        setPageLoading(false)
        if (res && res.data && res.data.portfolios) {
          setPortfolioData(res.data.portfolios)
        }
      }).catch((err) => {
        console.log(err);

      });
  }
  const handlepageChange = (event, value) => {
    setPage(value);
  };

  const [isFullTextVisible, setIsFullTextVisible] = useState({});
  const truncateTexts = (text, charLimit) => {
    return text.length > charLimit ? text.substring(0, charLimit) + "..." : text;
  };
  // Toggle function for each item
  const toggleText = (index) => {
    setIsFullTextVisible(prevState => ({
      ...prevState,
      [index]: !prevState[index], // Toggle visibility for the specific item
    }));
  };
  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={3}
      >
        <Grid item xs={12} sm={12} >
          <div className="investment-whole-div">
            <div className="profile-box2">
              <h5>About</h5>
              <p className="para">
                {displayText}
                {isTruncated && (
                  <span
                    onClick={toggleReadMore}
                    style={{ cursor: "pointer", color: "#7F9AD2" }}
                  >
                    {showFull ? " Read Less" : " Read More..."}
                  </span>
                )}
              </p>
            </div>
            {
              listDatas.length > 0 ? (
                <div className=" mt-4">
                  <div className="profile-box-joincrd-3">
                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="join-grey-box">
                          <span>Products / Services</span>
                        </div>
                      </Grid>
                      {listDatas && listDatas.map((item, index) => (
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
                          <div className={'join-cards'}>
                            <div className="crd-j-tit">
                              <span><b>{item.name}</b></span>
                              <p>{item.description}</p>
                            </div>
                            <div className="pricing-details my-3">
                              {item.priceRangeTo ? (<p>Market Price: <span>Rs. &nbsp;{item.priceRangeFrom} - {item.priceRangeTo}</span></p>) : (<p>Market Price: <span>Rs. &nbsp;{item.priceRangeFrom}</span></p>)}

                              {
                                item.priceType === "Fixed" && item.offerType === "Discount" && (
                                  <>
                                    <p>
                                      Smart Card Price:
                                      <span className='free-join'>
                                        {
                                          `Rs. ${item.discountedPriceFrom}`}
                                      </span>
                                    </p>
                                  </>
                                )
                              }
                              {
                                item.priceType === "Range" && item.offerType === "Discount" && (
                                  <>
                                    <p>
                                      Discount :
                                      <span className='free-join'>
                                        <span className='free-join'>

                                          {item.discount} %

                                        </span>
                                      </span>
                                    </p>
                                  </>
                                )
                              }
                              {
                                item.priceType === "Fixed" && item.offerType === "Price Cut" && (
                                  <>
                                    <p>
                                      Smart Card Price:
                                      <span className='free-join'>
                                        {
                                          `Rs. ${item.discountedPriceFrom}`}
                                      </span>
                                    </p>
                                  </>
                                )
                              }
                              {
                                item.priceType === "Range" && item.offerType === "Price Cut" && (
                                  <>
                                    {item.discountedPriceTo > 0 ? (<p>
                                      Smart Card Price:
                                      <span className='free-join'>

                                        Rs. {item.discountedPriceFrom} - {item.discountedPriceTo}
                                      </span>
                                    </p>) : (<p>
                                      Smart Card Price:
                                      <span className='free-join'>

                                        Rs. {item.discountedPriceFrom}
                                      </span>
                                    </p>)}
                                  </>
                                )
                              }
                              {
                                item.offerType === "Free" && (
                                  <>
                                    <p>
                                      Smart Card Price:
                                      <span className='free-join'>
                                        Free
                                      </span>
                                    </p>
                                  </>
                                )
                              }
                              <p>
                                Offer Duration: {item.enableOfferDuration !== false ? (
                                  <span>{item.offerTime}&nbsp;{item.offerDuration}</span>
                                ) : (
                                  <span>-</span>
                                )}
                              </p>

                              <p style={{ borderBottom: 'none', height: '70px' }}>
                                {/* {item.additionalChargeDesc ? `${item.additionalChargeDesc} (Additional Charges)` : "Additional Charges"} */}
                                {item.additionalChargeDesc ?  (
                                  <>
                                    <p style={{ borderBottom: 'none' }}>
                                      <div style={{width:'177px'}}>
                                      {isFullTextVisible[index] ? item.additionalChargeDesc : truncateTexts(item.additionalChargeDesc, 10)}
                                      <span>(Additional Charges)</span>
                                      <div>
                                      {item.additionalChargeDesc.length > 10 &&(<span
                                        onClick={() => toggleText(index)}
                                        style={{ cursor: "pointer", color: "#7F9AD2", }}
                                      >
                                        {isFullTextVisible[index] ? " Read Less" : " Read More"}
                                      </span>)}
                                      </div>
                                      </div>
                                    </p>
                                  </>
                                ):("Additional Charges")}

                                {item.additionalCharges === false && (<span>
                                  {"NA"}
                                </span>)}

                                {item.additionalChargePrice ? (
                                  <div className='text-right'>
                                    {item.additionalChargeDesc && item.additionalChargePrice ? `Rs.${item.additionalChargePrice}` : ""}
                                  </div>) : (<></>)}
                              </p>

                            </div>
                            {
                              smartCardKey !== 'join' && (
                                <div className="text-center">
                                  {item.productAvailed === false ? (<button className='ed-joi-btns' onClick={() => handleCarts(item)}>Add to Cart</button>) : (<button className='ed-joi-btns1'><LiaCheckDoubleSolid />Added to Cart</button>)}
                                </div>
                              )}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                    <div className="event-pagination">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPage}
                          page={page}
                          onChange={handlepageChange}
                        />
                      </Stack>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="profile-box3 mt-2">
                  <div className="text-center">
                    <h5 style={{ fontWeight: "600" }}>No Data</h5>
                  </div>
                </div>
              )
            }

            <div className="Services-box">
              {/* <span>Portfolio Startups</span> */}
              <h5 style={{ fontWeight: "600" }} className="mb-3">Portfolio Startups</h5>
              {PortfolioData.length > 0 ? (
                <>
                  <Swiper
                    slidesPerView={screenSize}
                    spaceBetween={30}
                    navigation
                    loop
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    // pagination={{
                    //   clickable: true,
                    //   renderBullet: function (index, className) {
                    //     return `<span class="${className}" style="background-color: #020671;"></span>`;
                    //   },
                    // }}
                    // modules={[Autoplay,Navigation,Pagination]}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    {PortfolioData && PortfolioData.map((item, i) => (
                      <SwiperSlide>
                        <div className="event-box-surya">
                          <div className="service-card-back-layers">
                            <div className="layer-crd-back-img-inv">
                              <div className="service-card-back-profss">
                                <img
                                  src={item.logo ? item.logo : "/images/user.png"}
                                  alt="logo"
                                  className="smcrd-card-back-image"
                                />
                              </div>
                            </div>
                            <div className="card-content-offers">
                              <span>{item.name}</span>
                            </div>
                            <div
                              className="card-content-offers1"
                              style={{ visibility: item.website ? 'visible' : 'hidden' }}
                            >
                              <a href={item.website} target="_blank" rel="noopener noreferrer">
                                <img src={"/images/Page-1.png"} alt="globe" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </>
              ) : (<>
                <div className="text-center">
                  <h5 style={{ fontWeight: "600" }}>No Data</h5>
                </div>
              </>
              )}
            </div>
            <div className="investment-whole-div mt-3">
              <div className="profile-box2 mt-2">
                <h5 style={{ fontWeight: "600" }} className="mb-3">Ratings & Reviews</h5>
                <div style={{ width: "100%", overflowX: "auto" }}>

                  <div className="my-3">
                    {ratinglist.length > 0 ? (
                      <>
                        <h5 className="color-blue"><img src={"/images/star-rate.png"} alt="star" /> {ratingData} / 5 <span style={{ color: '#000', fontWeight: 100 }}>({ratinglist.length})</span></h5>
                        {ratinglist && ratinglist.map((item, i) => (
                          <div className="rate-boxshad mb-3" key={i}>
                            {/* <div className="mr-2">
                              <img src={item.logo ? item.logo : "/images/user.png"} className="review_logo2" alt="logo" />
                            </div>
                            <div>
                              <div className="rated-items">
                                <div>
                                  <h6>{item.name} </h6>
                                </div>
                                <div>
                                  <p className="ml-auto list-para mr-5"><img src={"/images/star-rate.png"} alt="star" /> {item.rating}</p>
                                </div>
                              </div>
                              <p className="mb-0 paras">{item.comment}</p>
                            </div> */}
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <div> <img src={item.logo ? item.logo : "/images/user.png"} className="review_logo2" alt="logo" /></div>
                                <div className="ml-3">
                                  <h6>{item.name} </h6>

                                </div>
                              </div>
                              <div> <p className="ml-auto list-para mr-5"><img src={"/images/star-rate.png"} alt="star" /> {item.rating}</p></div>
                            </div>
                            <p className="mb-0 paras1">{item.comment}</p>
                          </div>
                        ))}
                      </>
                    ) : (<>
                      <div className="profile-box3 mt-2">
                        <div className="text-center">
                          <h5 style={{ fontWeight: "600" }}>No Ratings & Reviews Are Available.</h5>
                        </div>
                      </div></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SmartProfilePage;
