import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, FormHelperText, InputBase, Menu, MenuItem, TextField, ToggleButton, Typography, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomDateRangePicker from "../../common-pages/CustomDateRangePicker";
import DataContext from "../../../context/DataContext";
import ExcelToMail from "../common-page/ExcelToMail";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "24px",
  backgroundColor: "rgb(255 255 255)",
  border: "1px solid #00000017",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#d9d9d9",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "23ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));
const Smartcardfilter = ({ smartcardList, setSearchInputValue, rowsPerPage, selectedPersonas, setSelectedPersonas, setSortBy, setFilterVal, filterVal, page, searchInputValue,selectedProfileType, setSelectedProfileType }) => {
  
  const { hasPrivilege } = useContext(DataContext);
  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };
  const smartcardFilter = [
    { id: 0, status: "All" },
    { id: 1, status: "Requested" },
    { id: 2, status: "Under Process" },
    { id: 3, status: "Delivered" }
  ];
  const personas = [
    { id: 0, personaName: "All" },
    { id: 1, personaName: "DPIIT Startup" },
    { id: 2, personaName: "Non-DPIIT Startup" },
    { id: 3, personaName: "Aspirant" },
  ];
  const handleDepartment = (id) => {
    setFilterVal(id);
  };
  const handlePersoans = (value) => {
    var persona = personas.filter((list) => list.personaName === value)
    setSelectedPersonas(value);
    setSelectedProfileType(persona[0].id)
  };

  return (
    <div>
      <div className="double-clr">
        <div className="frt-gry">
          <div className="main-tab-adminpage">
            <NavLink className="report-sup-btn" >
              SmartCard List
            </NavLink>
          </div>
          <div></div>
        </div>
        <div className="sec-clrk">
          {
            hasPrivilege('51') && (
              <>
                <div className="sec-btn-bg">
                  <Autocomplete
                    size="small"
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    value={smartcardFilter.find(option => option.id === filterVal) || null}
                    options={smartcardFilter}
                    getOptionLabel={(option) => option.status}
                    onChange={(e, selectedOption) => {
                      handleDepartment(selectedOption ? selectedOption.id : null);
                    }}
                    className="meetingListDropdown"
                    renderInput={(params) => <TextField {...params} label="Select Status" />}
                  />
                </div>
                <div className="sec-btn-bg">
                  <Autocomplete
                    size="small"
                    disableClearable
                    disablePortal
                    options={personas.map((name) => name.personaName)}
                    // options={personas}
                    // getOptionLabel={(option) => option.personaName}
                    value={selectedPersonas}
                    className="meetingListDropdown"
                    onChange={(e, selectedOptions) => {
                      handlePersoans(selectedOptions);
                    }}
                    renderInput={(params) => <TextField {...params} label="Select Profile Type" />}
                  />
                </div>
              </>
            )
          }
          <div className="sec-filtersk ml-auto">
            <div className="search-div">
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search by Name"
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleSearch}
                  type="search"
                />
              </Search>
            </div>
          </div>
          {
            smartcardList.length > 0 && (
              <div className='mr-3'>
                <ExcelToMail name="smartcard" rowsPerPage={rowsPerPage} page={page} searchInputValue={searchInputValue} filterVal={filterVal} selectedProfileType={selectedProfileType} />
              </div>
            )
          }
        </div>
      </div>

    </div>
  );
};
export default Smartcardfilter