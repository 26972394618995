import React from 'react';
import { Dialog, DialogContent, Button, Grid, TextField, FormHelperText, FormControl, Autocomplete, InputAdornment, InputLabel, Select, MenuItem } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import apiService from '../../../../api/apiService';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import { useState } from 'react';
import DataContext from '../../../../context/DataContext';
import { useContext } from 'react';
import cryptoJS from '../../../../hooks/cryptoJS';
import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import notifyService from '../../../../api/notifySerivce';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ThiruvizhaLogin from './ThiruvizhaLogin';
import axios from 'axios';
import Thiruvizha24ProfileUpdate from './Thiruvizha24ProfileUpdate';

const Thiruvizha24DetailsModal = ({ onClose, openTypeValue, handleProfileClose }) => {
    var token = localStorage.getItem("jwttoken");
    const [formData, setFormData] = useState({
        founders: [{}]
    });
    const { handleSubmit, control, setValue, getValues, reset, clearErrors, formState: { errors } } = useForm({ values: formData })
    const { navigator, validateNumberonly, setPageLoading, validateEmail, validatePhoneNumber, fastUrlPath, isWhitespace, validatePincode } = useContext(DataContext);
    const [tabValue, setTabValue] = useState(openTypeValue === 'Free' ? 2 : 1)
    const {
        fields: founderFields,
        append: founderAppend,
        remove: founderRemove,
    } = useFieldArray({
        control,
        name: "founders",
    });
    const validatePANNumber = (value) => {
        const panRegex = /^[A-Z,a-z]{5}[0-9]{4}[A-Z,a-z]$/;
        const isValid = panRegex.test(value);
        return isValid || 'Please enter a valid PAN';
    }

    const checkAlphanumeric = (e) => {
        if (!/[0-9A-Za-z]/.test(e.key)) {
            e.preventDefault();
        }
    };

    useDidMountEffect(() => {
        // handleNextClick(57);
        dropdownApi();
        countrydropdownApi();
        statedropdownApi();
        DesignationApi()
        // setValue("country", "India");
        // setValue("state", "Tamil Nadu");
    }, [])
    const [designation, setDesignation] = useState([]);
    // let designationsValue
    const DesignationApi = () => {
        apiService(`admin/dropdownvalues/get?key=${"designation"}`, "", "unauthget")
            .then((result) => {
                if (result && result.data) {
                    if (result.data.dropDownValues.values) {
                        setDesignation(result.data.dropDownValues.values);
                        // designationsValue = result.data.designations
                    }
                }
            })
            .catch((err) => { });
    };
    const [district, setDistrict] = useState([]);
    const dropdownApi = () => {
        var req={
            lang:"ENG"
          }
          apiService(`home/district/list1`,req, "post")
            .then((result) => {
                if (result) {
                    if (result.data) {
                        if (result.data.districts) {
                            var values = result.data.districts;
                            setDistrict(values);
                        }
                    }
                }
            })
            .catch((err) => { });
    };
    const [country, setCountry] = useState([]);
    const countrydropdownApi = () => {
        apiService(`admin/dropdownvalues/get?key=${"country"}`, "", "unauthget")
            .then((result) => {
                if (result) {
                    if (result.data) {
                        if (result.data.dropDownValues) {
                            var dropDown = result.data.dropDownValues;
                            setCountry(dropDown.values);
                        }
                    }
                }
            })
            .catch((err) => { });
    };
    const [states, setStates] = useState([]);
    const statedropdownApi = () => {
        apiService(`admin/dropdownvalues/get?key=${"states"}`, "", "unauthget")
            .then((result) => {
                if (result) {
                    if (result.data) {
                        if (result.data.dropDownValues) {
                            var dropDown = result.data.dropDownValues;
                            setStates(dropDown.values);
                        }
                    }
                }
            })
            .catch((err) => { });
    };
    var id = localStorage.getItem("id");
    var type = localStorage.getItem('type')
    useDidMountEffect(() => {
        if (id !== null && openTypeValue === 'Delegate' && type !== 'NA') {
            getApi();
        }
    }, [])

    const getApi = () => {
        setPageLoading(true)
        setFormData({})
        var id = localStorage.getItem("id");
        var type = localStorage.getItem('type')
        setPanTickIcon(0)
        apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    var data = result.data;
                    if (data.pan && data.pan !== "") {
                        data.pan = cryptodecryptJS(data.pan);                        
                        if (data.pan.trim() !== '') {
                            setPanTickIcon(1);
                            setValue("pan", data.pan);
                        }else{
                            data.pan=''
                        }

                    } else if (data.gstNumber) {
                        data.pan = data.gstNumber;
                        // setValue("pan", gstNo);
                        setGstSet(true)
                        setPanTickIcon(0);
                    }

                    if (data.cin) {
                        data.cin = cryptodecryptJS(data.cin)
                    }
                    if (data.email) {
                        data.email = cryptodecryptJS(data.email)
                    }
                    if (data.phone) {
                        data.phone = cryptodecryptJS(data.phone)
                    }

                    // 
                    // if (tabValue === 1) {
                    if (data.founders) {
                        data.founders.forEach(element => {
                            if (element.email) {
                                element.email = cryptodecryptJS(element.email);
                            }
                            if (element.phone) {
                                element.phone = cryptodecryptJS(element.phone);
                            }
                            element.emailVerify = true
                            element.food = element.food === 'NA' ? 'Veg' : element.food
                        })
                    } 
                    // else {
                    //     var req = {
                    //         id: 0,
                    //         type: localStorage.getItem("role"),
                    //         name: data.name,
                    //         email: data.email,
                    //         phone: data.phone,
                    //         designation: "",
                    //         emailVerify: true,
                    //         food: 'Veg',
                    //     }
                    //     data.founders = [req]
                    // }

                    localStorage.setItem('email', cryptoJS(data.email))
                    data.country = data.country ? data.country : "India"
                    data.state = data.state ? data.state : "Tamil Nadu"

                    // console.log(data);


                    setFormData(data)
                }

            })
            .catch((err) => { });

    };

    const handleSelectCountryType = (value) => {
        setValue("country", value);
        clearErrors("country");
        setValue("otherCountry", "");
        clearErrors("otherCountry");
        if (value === "India") {
            setValue("state", "Tamil Nadu");
        } else {
            setValue("state", "");
            clearErrors("state");
        }
        setValue("district", "");
        clearErrors("district");
        setValue("pincode", "");
        clearErrors("pincode");
    };
    const handleSelectState = (value) => {
        setValue("state", value);
        clearErrors("state");
        setValue("district", "");
        clearErrors("district");
        setValue("pincode", "");
        clearErrors("pincode");
    };
    const handleSelectDistrict = (value) => {
        setValue("district", value);
        clearErrors("district");
    };
    const addFounderBtn = () => {
        var value = getValues('founders')
        console.log(value);
        if (openTypeValue === 'Delegate') {
            if (value === undefined || (value[value.length - 1].id !== 0 && value[value.length - 1].emailVerify === true)) {
                founderAppend({
                    id: 0,
                    type: localStorage.getItem("role"),
                    name: "",
                    email: "",
                    phone: "",
                    designation: "",
                    emailVerify: ''
                });
            } else if (value[value.length - 1].id === 0 && value[value.length - 1].emailVerify === true) {
                founderAppend({
                    id: 0,
                    type: localStorage.getItem("role"),
                    name: "",
                    email: "",
                    phone: "",
                    designation: "",
                    emailVerify: ''
                });
            } else {
                // 
                if (value[value.length - 1].emailVerify === "") {
                    notifyService('danger', 'Error', 'Please fill the mandatory fields')

                } else {


                    notifyService('danger', 'Error', 'Please verify email Id')
                }

            }
        } else {
            founderAppend({
                id: 0,
                type: localStorage.getItem("role"),
                name: "",
                email: "",
                phone: "",
                designation: "",
                emailVerify: ''
            });
        }


    };
    const handleSelectDesignation = (value, index) => {
        setValue(`founders.${index}.designation`, value);
        clearErrors(`founders.${index}.designation`);
    };
    const founderDetailsRemove = (index) => {
        var id = getValues(`founders.${index}.id`);
        if (id !== 0 && id !== undefined && openTypeValue === 'Delegate') {
            apiService(`userprofile/founder/remove?id=${id}`, '', 'delete').then((result) => {
                if (result && result.data) {
                    founderRemove(index);
                    var val = getValues()
                    setFormData(val)
                    console.log(val);
                }
            }).catch((err) => {
            });
        } else {
            founderRemove(index);
            var val = getValues()
            setFormData(val)
            console.log(val);
        }
        // var founder = getValues(`founders`)
        // if (founder.length === 0) {
        //     founderAppend({
        //         id: 0,
        //         type: localStorage.getItem("role"),
        //         name: "",
        //         email: "",
        //         phone: "",
        //         designation: "",
        //         emailVerify: ''
        //     })
        // }
        // var val = getValues()
        // setFormData(val)
        // console.log(val);

        // debugger
    };
    const [panTickIcon, setPanTickIcon] = useState(0);
    // const [startupNameView, setStartupNameView] = useState();
    const [gstSet, setGstSet] = useState(false)
    const handlePanChange = (e) => {
        var panNumber = e.target.value
        if (panNumber.length === 10) {
            clearErrors('gstAuthorizedName')
            var req = {
                pan: cryptoJS(panNumber)
            }
            apiService('pan/validate', req, 'post').then((result) => {
                // console.log(result.data.yearOfReg);
                if (result && result.data && result.data.response.responseStatus === 'Success') {
                    setPanTickIcon(1)
                    // var names = getValues('name')
                    // if (result.data.yearOfReg !== 0) {
                    //     setValue('yearOfReg', result.data.yearOfReg)
                    //     clearErrors('yearOfReg', '')
                    // }
                    // if (result.data.name !== undefined) {
                    //     // setStartupNameView(result.data.name)
                    //     localStorage.setItem('startupName', result.data.name)
                    //     // localStorage.setItem('panNumber',result.data.pan)
                    //     if (names === result.data.name) {
                    //         // setStartupNameShow(false)
                    //         setPanTickIcon(1)
                    //         // setStartupTickIcon(1)
                    //     }
                    //     else {
                    //         // setStartupNameShow(true)
                    //     }
                    // } else {
                    //     localStorage.removeItem('startupName')
                    //     // setStartupNameView('')
                    //     // setStartupNameShow(null)
                    //     setPanTickIcon(0)
                    //     // setStartupTickIcon(0)
                    // }
                } else {
                    setPanTickIcon(2)
                }
            }).catch(() => {
                setPanTickIcon(0)
            });
            setGstSet(false)
        } else if (panNumber.length > 10) {
            setGstSet(true)
            setPanTickIcon(0)
        } else {
            setGstSet(false)
            setPanTickIcon(0)
        }
    };
    const [emailVerify, setEmailVerify] = useState(null)
    const handleEmailVerfication = (value, index) => {
        setEmailVerify(null)
        if (value.length > 3) {
            var email = value.toLowerCase()
            apiService(`founder/email/verify?email=${cryptoJS(email)}`, '', 'get').then((result) => {
                if (result && result.data && result.data.responseStatus === 'Success') {
                    setEmailVerify(true)
                    setValue(`founders.${index}.emailVerify`, true)
                } else {
                    setEmailVerify(false)
                    notifyService('danger', 'Error', result.data.responseMessage)
                    setValue(`founders.${index}.emailVerify`, false)
                }
            }).catch((err) => {
                setEmailVerify(null)
                setValue(`founders.${index}.emailVerify`, null)
            });
        }
    }

    const onSubmit = (data) => {
        if (openTypeValue === 'Delegate') {
            data.persona = type
            data.userid = id
            // if (data.pan) {
            //     data.pan = cryptoJS(data.pan.toUpperCase());
            // }
            if (gstSet === true) {
                data.gstNumber = (data.pan.toUpperCase())
                data.pan = ""
                debugger
            } else {
                data.pan = cryptoJS(data.pan.toUpperCase());
                data.gstNumber = ""
                debugger
            }
            if (data.cin) {
                data.cin = cryptoJS(data.cin);
            }
            if (data.email) {
                data.email = cryptoJS(data.email.toLowerCase())
            }
            if (data.phone) {
                data.phone = cryptoJS(data.phone)
            }
            if (tabValue === 2) {
                var value = data.founders || []
                if (value.length === 0 || (value.length > 0 && value[value.length - 1].emailVerify === true)) {
                    if (data.founders) {
                        data.founders.forEach(founder => {
                            if (founder.email) {
                                founder.email = cryptoJS(founder.email.toLowerCase());
                            }
                            if (founder.phone) {
                                founder.phone = cryptoJS(founder.phone);
                            }
                        });
                    }
                    // data.founders=
                } else {
                    return notifyService('danger', 'Error', 'Email Already Exists')
                }
            } else {
                if (data.founders) {
                    data.founders.forEach(founder => {
                        if (founder.email) {
                            founder.email = cryptoJS(founder.email.toLowerCase());
                        }
                        if (founder.phone) {
                            founder.phone = cryptoJS(founder.phone);
                        }
                    });
                }
            }

            setPageLoading(true)
            // 
            apiService("userprofile/save", data, "post")
                .then((result) => {
                    setPageLoading(false)
                    if (result && result.data && result.data.responseStatus === "Success") {
                        if (openTypeValue === 'Free' && tabValue === 2) {
                            var email = localStorage.getItem('email')
                            onClose()
                            localStorage.removeItem('currentPath');
                            var conferenceurl = `https://event.startuptn.in/conference?email=${cryptodecryptJS(email)}&type=delegate`
                            // var conferenceurl = `https://startuptn.getkameleon.com/conference?email=${cryptodecryptJS(email)}&type=delegate`
                            localStorage.setItem("conferencePath", conferenceurl)
                            localStorage.removeItem('way')
                            window.location.href = conferenceurl;
                            navigator("/fest");
                        } else if (openTypeValue !== 'Free' && tabValue === 2) {
                            var email = localStorage.getItem('email')
                            onClose()
                            localStorage.removeItem('currentPath');
                            var conferenceurl = `https://event.startuptn.in/conference?email=${cryptodecryptJS(email)}&type=delegate`
                            // var conferenceurl = `https://startuptn.getkameleon.com/conference?email=${cryptodecryptJS(email)}&type=delegate`
                            localStorage.setItem("conferencePath", conferenceurl)
                            localStorage.removeItem('way')
                            window.location.href = conferenceurl;
                            navigator("/fest");
                        } else {
                            getApi()
                            setTabValue(2)
                        }
                        // if (openTypeValue !== 'Free' && tabValue === 2) {
                        //     var email = localStorage.getItem('email')
                        //     onClose()
                        //     localStorage.removeItem('currentPath');
                        //     var conferenceurl = `https://event.startuptn.in/conference?email=${cryptodecryptJS(email)}`
                        //     localStorage.setItem("conferencePath", conferenceurl)
                        //     localStorage.removeItem('way')
                        //     window.location.href = conferenceurl;
                        //     navigator("/fest");                        
                        // } else {
                        //     getApi()
                        //     setTabValue(2)
                        // }

                    }
                })
                .catch((err) => { });
        } else {
            var founders = data.founders
            let val = []
            founders.forEach(element => {
                var rec = {
                    "name": element.name,
                    "email": element.email,
                    "mobile": element.phone,
                    "designation": element.designation
                }
                val.push(rec)
            });
            var details = val
            var value = {
                "name": details[0].name,
                "email": details[0].email,
                "mobile": details[0].mobile,
                "organisation_name": "Individual",
                "designation": details[0].designation,
                "gstNumber": "",
                "cinNumber": "",
                "panNumber": "",
                "billingAddress": "",
                "visitorTicket": details.length,
                "type": "Individual",
                "visitorData": details
            }
            console.log(value);
            setPageLoading(true)
            apiService(`user/thiruvizha/visitor/register`, value, 'unauthpost').then((result) => {
                setPageLoading(false)
                if (result && result.data.uniqueId) {
                    visitorApi(result.data.uniqueId, details)
                }

            }).catch((err) => {

            });

        }

    };
    const [visitorOpen, setVisitorOpen] = useState(false)
    const visitorApi = (uid, details) => {
        details.forEach((element, index) => {
            element.uniqueId = `${uid}/T${index + 1}`
        });
        // var URL = 'https://ds672e9rpa.execute-api.ap-south-1.amazonaws.com/dev/v1/expo/0696b537-64ed-49a4-8ed0-617caa4a676b/visitor/registration'
        var URL = 'https://t0np742f0i.execute-api.ap-south-1.amazonaws.com/prod/v1/expo/a8c8917f-adca-4a9f-90ea-8f8f1c7fa106/visitor/registration'
        var value = {
            "name": details[0].name,
            "email": details[0].email,
            "mobile": details[0].mobile,
            "organisation_name": "Individual",
            "designation": details[0].designation,
            "gstNumber": "",
            "cinNumber": "",
            "panNumber": "",
            "billingAddress": "",
            "visitorTicket": details.length,
            "type": "Individual",
            "uniqueId": uid,
            "visitorData": details
        }
        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
        };
        debugger
        setPageLoading(true)
        return axios.post(URL, value, config).then((result) => {
            setPageLoading(false)
            if (result && result.data && result.data.status === true) {
                setVisitorOpen(true)
            } else {
                notifyService('danger', '', `We're sorry, but it looks like something went wrong. Please try again later or reach us at tech@startuptn.in or +91 8610331001`)

            }
        }).catch((err) => {

        });
        
    }
    const handelCloseVisitor = () => {
        setVisitorOpen(false)
        onClose()
        handleProfileClose()
    }
    // const onSubmit = (data) => {

    // };
    // const onError = (event) => {
    //     console.log('error Data:', event);
    // }
    const PreviousBtn = () => {
        getApi()
        setTabValue(1)
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent sx={{ padding: '20px' }}>

                    {
                        tabValue === 1 && (
                            <>
                                {/* {
                                    <ThiruvizhaLogin />
                                } */}
                                <div class="alert alert-primary text-center" role="alert">
                                    Add details here for Invoice
                                </div>
                                {
                                    !token ? (
                                        <ThiruvizhaLogin getApi={getApi} />
                                    ) : (
                                        <>
                                            {
                                                (token && type !== 'NA') ? (
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={2}
                                                        className='mt-3'
                                                    >
                                                        {
                                                            token && (
                                                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                    <Controller name="email" control={control} defaultValue="" rules={{
                                                                        required: "Email is Required"
                                                                    }}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                variant="outlined"
                                                                                label="Email *"
                                                                                placeholder="Enter Email"
                                                                                fullWidth
                                                                                type="text"
                                                                                {...field}
                                                                                error={Boolean(errors.email && errors.email)}
                                                                                disabled
                                                                            />
                                                                        )}
                                                                    />
                                                                    <FormHelperText className="text-danger">
                                                                        {errors.email && errors.email.message}
                                                                    </FormHelperText>
                                                                </Grid>
                                                            )
                                                        }
                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            <Controller name="name" control={control} defaultValue="" rules={{
                                                                required: "Name / Organization Name is Required",
                                                            }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label="Name / Organization Name *"
                                                                        placeholder="Enter Name / Organization Name"
                                                                        fullWidth
                                                                        type="text"
                                                                        {...field}
                                                                        error={Boolean(errors.name && errors.name)}
                                                                    />
                                                                )}
                                                            />
                                                            <FormHelperText className="text-danger">
                                                                {errors.name && errors.name.message}
                                                            </FormHelperText>
                                                        </Grid>


                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            <Controller name="phone" control={control} defaultValue="" rules={{
                                                                required: "Phone Number is required",
                                                                validate: validatePhoneNumber,
                                                            }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label="Phone Number *"
                                                                        placeholder="Enter Phone Number"
                                                                        fullWidth
                                                                        type="text"
                                                                        {...field}
                                                                        onKeyDown={(e) => validateNumberonly(e)}
                                                                        inputProps={{ maxLength: 10 }}
                                                                        error={Boolean(errors.phone && errors.phone)}
                                                                    />
                                                                )}
                                                            />
                                                            <FormHelperText className="text-danger">
                                                                {errors.phone && errors.phone.message}
                                                            </FormHelperText>
                                                        </Grid>



                                                        {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                            <FormControl sx={{ width: "100%" }}>
                                <Controller
                                    name="country"
                                    control={control}
                                    defaultValue="India"
                                    rules={{ required: "Country is required" }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            disableClearable
                                            disablePortal
                                            {...field}
                                            options={country}
                                            onChange={(e, selectedOptions) =>
                                                handleSelectCountryType(selectedOptions)
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} label="Country *" error={Boolean(errors.country && errors.country)} />
                                            )}
                                        // disabled={readyOnlyValue}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.country && errors.country.message}
                                </FormHelperText>
                            </FormControl>
                        </Grid> */}
                                                        {
                                                            getValues("country") === "India" ? (
                                                                <>
                                                                    <Grid item lg={4} md={4} sm={12} xs={12} className="mb-3">
                                                                        <FormControl sx={{ width: "100%" }}>
                                                                            <Controller
                                                                                name="state"
                                                                                control={control}
                                                                                defaultValue="Tamil Nadu"
                                                                                rules={{ required: "State is required" }}
                                                                                render={({ field }) => (
                                                                                    <Autocomplete
                                                                                        disableClearable
                                                                                        disablePortal
                                                                                        {...field}
                                                                                        // limitTags={2}
                                                                                        options={states}
                                                                                        onChange={(e, selectedOptions) =>
                                                                                            handleSelectState(selectedOptions)
                                                                                        }
                                                                                        renderInput={(params) => (
                                                                                            <TextField {...params} label="State *" error={Boolean(errors.state && errors.state)} />
                                                                                        )}
                                                                                    // disabled={readyOnlyValue}
                                                                                    />
                                                                                )}
                                                                            />
                                                                            <FormHelperText className="text-danger">
                                                                                {errors.state && errors.state.message}
                                                                            </FormHelperText>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                        {/* {
                            getValues("country") === "Others" ? (
                                <>
                                    <Grid item lg={4} md={4} sm={12} xs={12} className="mb-3">
                                        <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                                name="otherCountry"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: 'Country name is required', validate: {
                                                        noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        variant="outlined"
                                                        label="Country Name *"
                                                        placeholder="Enter Country Name"
                                                        fullWidth
                                                        // inputProps={{ readOnly: readyOnlyValue }}
                                                        error={Boolean(errors.otherCountry && errors.otherCountry)}
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.otherCountry && errors.otherCountry.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12} className="mb-3" >
                                        <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                                name="state"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: 'State is required', validate: {
                                                        noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        variant="outlined"
                                                        label="State *"
                                                        placeholder="Enter State"
                                                        fullWidth
                                                        // inputProps={{ readOnly: readyOnlyValue }}
                                                        error={Boolean(errors.state && errors.state)}
    
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.state && errors.state.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </>
                            ) : (
                                <></>
                            )} */}
                                                        {getValues("country") === "India" &&
                                                            getValues("state") === "Tamil Nadu" ? (
                                                            <>
                                                                <Grid item lg={4} md={4} sm={12} xs={12} className="mb-3">
                                                                    <FormControl sx={{ width: "100%" }}>
                                                                        <Controller
                                                                            name="district"
                                                                            control={control}
                                                                            defaultValue=""
                                                                            rules={{ required: true }}
                                                                            render={({ field, fieldState: { error } }) => (
                                                                                <Autocomplete
                                                                                    disableClearable
                                                                                    {...field}
                                                                                    disablePortal
                                                                                    id="combo-box-demo"
                                                                                    options={district.map((option) => option.name)}
                                                                                    // getOptionLabel={(option) => option.name}
                                                                                    onChange={(e, selectedOption) =>
                                                                                        handleSelectDistrict(selectedOption)
                                                                                    }
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                            {...params}
                                                                                            label="District*"
                                                                                            error={Boolean(errors.district && errors.district)}
                                                                                            helperText={
                                                                                                error ? "District is required" : ""
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            )}
                                                                        />
                                                                        <FormHelperText className="text-danger">
                                                                            {errors.district && errors.district.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {getValues("state") !== "Tamil Nadu" &&
                                                            getValues("country") === "India" ? (
                                                            <>
                                                                <Grid item lg={4} md={4} sm={12} xs={12} className="mb-3">
                                                                    <Controller
                                                                        name="district"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        // rules={{ required: "District is required" }}
                                                                        rules={{
                                                                            required: 'District is required', validate: {
                                                                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                                                            },
                                                                        }}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                variant="outlined"
                                                                                label="District *"
                                                                                placeholder="Enter District"
                                                                                fullWidth
                                                                                type="text"
                                                                                {...field}
                                                                                error={Boolean(errors.district && errors.district)}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <FormHelperText className="text-danger">
                                                                        {errors.district && errors.district.message}
                                                                    </FormHelperText>
                                                                </Grid>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <Grid item lg={4} md={4} sm={12} xs={12} className="mb-3">
                                                            <Controller
                                                                name="address"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{
                                                                    required: 'Address is required', validate: {
                                                                        noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label="Address *"
                                                                        placeholder="Enter Address"
                                                                        fullWidth
                                                                        type="text"
                                                                        {...field}
                                                                        error={Boolean(errors.address && errors.address)}
                                                                    />
                                                                )}
                                                            />
                                                            <FormHelperText className="text-danger">
                                                                {errors.address && errors.address.message}
                                                            </FormHelperText>
                                                        </Grid>
                                                        {getValues("country") === "India" ? (
                                                            <Grid item lg={4} md={4} sm={12} xs={12} className="mb-3">
                                                                <Controller
                                                                    name="pincode"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    rules={{
                                                                        required: "Pincode is required",
                                                                        validate: validatePincode
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            variant="outlined"
                                                                            label="Pincode *"
                                                                            placeholder="Enter Pincode"
                                                                            fullWidth
                                                                            type="text"
                                                                            {...field}
                                                                            onKeyDown={(e) => validateNumberonly(e)}
                                                                            inputProps={{ maxLength: 6, minLength: 6 }}
                                                                            error={Boolean(errors.pincode && errors.pincode)}
                                                                        />
                                                                    )}
                                                                />
                                                                <FormHelperText className="text-danger">
                                                                    {errors.pincode && errors.pincode.message}
                                                                </FormHelperText>
                                                            </Grid>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {getValues("country") === "Others" ? (
                                                            <Grid item lg={4} md={4} sm={12} xs={12} className="mb-3">
                                                                <Controller
                                                                    name="pincode"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    rules={{ required: "Zip Code is required" }}
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            variant="outlined"
                                                                            label="Zip Code*"
                                                                            placeholder="Enter Zip Code"
                                                                            fullWidth
                                                                            type="text"
                                                                            {...field}
                                                                            onKeyDown={(e) => validateNumberonly(e)}
                                                                            inputProps={{ maxLength: 11, minLength: 5 }}
                                                                            error={Boolean(errors.pincode && errors.pincode)}
                                                                        />
                                                                    )}
                                                                />
                                                                <FormHelperText className="text-danger">
                                                                    {errors.pincode && errors.pincode.message}
                                                                </FormHelperText>
                                                            </Grid>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            <Controller
                                                                name="cin"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{
                                                                    required: false,
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label="CIN No. (For Organisations)"
                                                                        placeholder="Enter CIN  No."
                                                                        fullWidth
                                                                        type="text"
                                                                        {...field}
                                                                        error={Boolean(errors.cin && errors.cin)}
                                                                    />
                                                                )}
                                                            />
                                                            <FormHelperText className="text-danger">
                                                                {errors.cin && errors.cin.message}
                                                            </FormHelperText>
                                                        </Grid>
                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            <Controller name="pan" control={control} defaultValue=""
                                                                rules={{
                                                                    required: "PAN / GST No. is required",
                                                                    // validate: validatePANNumber
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label="PAN / GST No. *"
                                                                        placeholder="Enter PAN / GST No."
                                                                        fullWidth
                                                                        type="text"
                                                                        // inputProps={{ maxLength: 10 }}
                                                                        onKeyDown={(e) => checkAlphanumeric(e)}
                                                                        onInput={(e) => {
                                                                            handlePanChange(e);
                                                                        }}
                                                                        {...field}
                                                                        error={Boolean(errors.pan && errors.pan)}
                                                                        InputProps={{
                                                                            endAdornment: panTickIcon === 1 ? (
                                                                                <InputAdornment position="end">
                                                                                    <CheckCircleIcon color="success" />
                                                                                </InputAdornment>
                                                                            ) : panTickIcon === 2 ? (
                                                                                <InputAdornment position="end">
                                                                                    <CloseIcon color="error" />
                                                                                </InputAdornment>
                                                                            ) : null,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <FormHelperText >
                                                                Individual can use PAN
                                                            </FormHelperText>
                                                            <FormHelperText >
                                                                Organization can use GST
                                                            </FormHelperText>
                                                            <FormHelperText className="text-danger">
                                                                {errors.pan && errors.pan.message}
                                                            </FormHelperText>
                                                        </Grid>
                                                        {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <Controller name="gstNumber" control={control} defaultValue="" rules={{
                                                    required: false,
                                                }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            label="GST No."
                                                            placeholder="Enter GST No."
                                                            fullWidth
                                                            type="text"
                                                            inputProps={{ maxLength: 21 }}
                                                            onKeyDown={(e) => checkAlphanumeric(e)}
                                                            {...field}
                                                            error={Boolean(errors.gstNumber && errors.gstNumber)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.gstNumber && errors.gstNumber.message}
                                                </FormHelperText>
                                            </Grid> */}
                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            <Controller name="gstAuthorizedName" control={control} defaultValue="" rules={{
                                                                required: gstSet,
                                                            }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label={gstSet === true ? `Organization Name as per the GST *` : 'Organization Name as per the GST'}
                                                                        placeholder="Enter Organization Name as per the GST"
                                                                        fullWidth
                                                                        type="text"
                                                                        {...field}
                                                                        error={Boolean(errors.gstAuthorizedName && errors.gstAuthorizedName)}
                                                                    />
                                                                )}
                                                            />

                                                            <FormHelperText className="text-danger">
                                                                {errors.gstAuthorizedName && 'Organization Name as per the GST is required'}
                                                            </FormHelperText>

                                                        </Grid>
                                                    </Grid>
                                                ) : (
                                                    <>
                                                        {
                                                            token && (
                                                                <Thiruvizha24ProfileUpdate getApi={getApi} onClose={onClose} />
                                                            )
                                                        }

                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }

                            </>

                        )
                    }
                    {
                        tabValue === 2 && (
                            <>
                                <div class="alert alert-primary text-center" role="alert">
                                    Startup Thiruvizha' 2024 - Add Members here to book {openTypeValue === 'Free' ? 'Visitor' : 'Conference'} Pass
                                </div>
                                <h5 className='mt-3 text-center'>
                                    {
                                        openTypeValue === 'Delegate' ? 'Delegate Details' : 'Visitor Details'
                                    }
                                </h5>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                    className='box_grid mt-3'
                                >
                                    <Grid item lg={12} md={12} sm={12} xs={12}>

                                        {founderFields.map((field, index) => (
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={2}
                                                className='box_grid mt-2'
                                            >
                                                <Grid item lg={openTypeValue !== 'Free' ? 2 : 3} md={openTypeValue !== 'Free' ? 2 : 3} sm={6} xs={12}>
                                                    <div className='d-flex align-items-center w-100'>
                                                        <div className='mr-2'>{index + 1}</div>
                                                        <div className='w-100'>
                                                            <Controller
                                                                name={`founders.${index}.name`}
                                                                control={control}
                                                                defaultValue=""

                                                                rules={{
                                                                    required: 'Name is required', validate: {
                                                                        noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label="Name *"
                                                                        placeholder="Enter  Name"
                                                                        fullWidth
                                                                        type="text"
                                                                        {...field}
                                                                        error={errors.founders?.[index]?.name &&
                                                                            errors.founders?.[index]?.name}
                                                                    />
                                                                )}
                                                            />
                                                            <FormHelperText className="text-danger">
                                                                {errors.founders?.[index]?.name &&
                                                                    errors.founders?.[index]?.name.message}
                                                            </FormHelperText>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                                    <Controller
                                                        name={`founders.${index}.email`}
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: "Mail ID is required",
                                                            validate: validateEmail,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                variant="outlined"
                                                                label="Mail ID *"
                                                                placeholder="Enter Mail ID"
                                                                fullWidth
                                                                type="text"
                                                                {...field}
                                                                error={errors.founders?.[index]?.email &&
                                                                    errors.founders?.[index]?.email}
                                                                onInput={(e) => { openTypeValue === 'Delegate' && handleEmailVerfication(e.target.value, index) }}
                                                                InputProps={{
                                                                    endAdornment: getValues(`founders.${index}.emailVerify`) === true ? (
                                                                        <InputAdornment position="end">
                                                                            <CheckCircleIcon color="success" />
                                                                        </InputAdornment>
                                                                    ) : getValues(`founders.${index}.emailVerify`) === false ? (
                                                                        <InputAdornment position="end">
                                                                            <CloseIcon color="error" />
                                                                        </InputAdornment>
                                                                    ) : null,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.founders?.[index]?.email &&
                                                            errors.founders?.[index]?.email.message}
                                                    </FormHelperText>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={6} xs={12}>
                                                    <Controller
                                                        name={`founders.${index}.designation`}
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: true }}
                                                        render={({
                                                            field,
                                                            fieldState: { error },
                                                        }) => (
                                                            <Autocomplete
                                                                disableClearable
                                                                {...field}
                                                                options={designation}
                                                                onChange={(event, newValue) =>
                                                                    handleSelectDesignation(newValue, index)
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Designation *"
                                                                        placeholder="Select Designation"
                                                                        error={!!error}
                                                                        helperText={
                                                                            error
                                                                                ? "Designation is required"
                                                                                : ""
                                                                        }
                                                                    />
                                                                )}
                                                                fullWidth
                                                                margin="normal"
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.founders?.[index]?.designation &&
                                                            errors.founders?.[index]?.designation
                                                                .message}
                                                    </FormHelperText>
                                                </Grid>
                                                <Grid item lg={openTypeValue !== 'Free' ? 2 : 3} md={openTypeValue !== 'Free' ? 2 : 3} sm={6} xs={12}>
                                                    <Controller
                                                        name={`founders.${index}.phone`}
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: "Phone Number is required",
                                                            validate: validatePhoneNumber,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                variant="outlined"
                                                                label="Phone Number *"
                                                                placeholder="Enter Phone Number"
                                                                fullWidth
                                                                type="text"
                                                                {...field}
                                                                onKeyDown={(e) => validateNumberonly(e)}
                                                                inputProps={{ maxLength: 10 }}
                                                                error={errors.founders?.[index]?.phone &&
                                                                    errors.founders?.[index]?.phone}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.founders?.[index]?.phone &&
                                                            errors.founders?.[index]?.phone.message}
                                                    </FormHelperText>
                                                </Grid>
                                                {
                                                    openTypeValue !== 'Free' && (
                                                        <Grid item lg={2} md={2} sm={6} xs={12}>
                                                            <FormControl fullWidth>
                                                                <InputLabel className='search_sm_lbl'>Food Preference *</InputLabel>
                                                                <Controller
                                                                    name={`founders.${index}.food`}
                                                                    control={control}
                                                                    defaultValue=""
                                                                    rules={{ required: 'Food Preference is required' }}
                                                                    render={({
                                                                        field,
                                                                        fieldState: { error },
                                                                    }) => (
                                                                        <Select
                                                                            {...field}
                                                                            label="Food Preference *"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                        >
                                                                            <MenuItem value={'Veg'}>Veg</MenuItem>
                                                                            <MenuItem value={'Non-Veg'}>Non-Veg</MenuItem>
                                                                        </Select>
                                                                    )}
                                                                />
                                                                <FormHelperText className="text-danger">
                                                                    {errors.founders?.[index]?.food &&
                                                                        errors.founders?.[index]?.food
                                                                            .message}
                                                                </FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                }
                                                <Grid item xs={1}>
                                                    {founderFields.length > 1 &&
                                                        <Button
                                                            className="red_btn"
                                                            onClick={() => founderDetailsRemove(index)}
                                                        >
                                                            <DeleteForeverIcon />
                                                        </Button>
                                                    }
                                                </Grid>
                                            </Grid>
                                        ))}
                                        {founderFields.length < 10 && (
                                            <div className="text-center mt-2">
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => addFounderBtn()}
                                                >
                                                    {
                                                        openTypeValue === 'Delegate' ? '+ Delegate' : '+ Visitor'
                                                    }
                                                </Button>
                                            </div>
                                        )
                                        }

                                    </Grid>
                                </Grid>

                            </>
                        )
                    }

                </DialogContent>
                <div className="text-center pb-3">

                    {
                        (openTypeValue === 'Free' || (tabValue === 1 && type !== 'NA')) ? (
                            <Button onClick={onClose} className="discard-btn"> Close</Button>
                        ) : (
                            <>
                                {
                                    type !== 'NA' && (
                                        <Button onClick={PreviousBtn} className="discard-btn"> Previous</Button>
                                    )
                                }
                            </>

                        )
                    }
                    {
                        (openTypeValue === 'Delegate' && token && type !== 'NA') && (
                            <Button type='submit'
                                className="publish-button"
                            >
                                {
                                    (openTypeValue !== 'Free' && tabValue === 1) ? (`Next`) : (`Continue`)
                                }
                            </Button>
                        )
                    }
                    {
                        openTypeValue === 'Free' && (
                            <Button type='submit'
                                className="publish-button"
                            >
                                {
                                    (openTypeValue !== 'Free' && tabValue === 1) ? (`Next`) : (`Continue`)
                                }
                            </Button>
                        )
                    }
                </div>
            </form >
            <Dialog fullWidth={true} open={visitorOpen} maxWidth={'sm'}>
                <DialogContent sx={{ padding: '20px' }}>
                    <div className='text-center'>
                        <div className='text-center mb-3'>
                            <img src='/images/thiruzila_logo.png' className='thiruzila_logo' />
                        </div>
                        {/* <img src={'/images/04-06-10-848_512.webp'} alt="warning" className='gif-warn' /> */}
                        {/* <img src={'/images/Banner/attention -man.gif'} alt="warning" className='gif-warn' /> */}
                        <h3>Congrats!</h3>
                        <h5 className='my-4'>
                            Your visitor pass for Startup Thiruvizha 2024 is confirmed and you will receive the same in E-Mail!
                        </h5>
                    </div>
                </DialogContent>
                <div className='text-center pb-3'>
                    <Button
                        variant="outlined"
                        className="edit-btn-profile"
                        sx={{
                            textTransform: "none",
                            border: "1px solid",
                            borderColor: "#020671",
                            color: "#020671",
                            padding: "6px 20px",
                            marginRight: "10px"
                        }}
                        onClick={handelCloseVisitor}
                    >
                        Close
                    </Button>
                </div>
            </Dialog>
        </>
    )
}

export default Thiruvizha24DetailsModal