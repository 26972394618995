import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Tab,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Navbar from "../../home-module/navbar-page/Navbar";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import DataContext from "../../../context/DataContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../../home-module/footer-page/Footer";
const FundingTanseed = ({ name }) => {
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);
  const [tanseedSwipers, setTanseedSwipers] = useState(1);
  const { navigator, setPageLoading, fullScreen } = useContext(DataContext);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [activeTab, setActiveTab] = useState("0");
  const [value, setValue] = useState("0");
  const [activeChip, setActiveChip] = useState("TANSEED 1.0");
  const navigate = useNavigate();
  useDidMountEffect(() => {
    listApi();
  }, [page, searchInputValue, categoryId, startDate, endDate]);
  const [eventList, setEventList] = useState([]);
  const [portfolioData, setPortfolioData] = useState([]);
  const [fundId, setFundId] = useState("");
  const [fundVersionId, setFundVersionId] = useState("");
  useDidMountEffect(() => {
    getPortfolioApi();
  }, [fundId, fundVersionId]);
  const getPortfolioApi = () => {
    setPageLoading(true);
    setPortfolioData([]);
    var req = {
      listSize: 25,
      pageNumber: 1,
      fundId: fundId,
      fundVersionId: fundVersionId,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL_TANFUND}admin/startups/fundrecieved/eco/list`,
        req
      )
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          if (result.data.startupFundReceived) {
            setPortfolioData(result.data.startupFundReceived);
          }
        }
      })
      .catch((err) => {});
  };
  const listApi = () => {
    setEventList([]);
    setPageLoading(true);
    var req = {
      searchString: searchInputValue,
      categoryId: categoryId,
      listSize: 12,
      pageNumber: page,
      fromDate: startDate,
      toDate: endDate,
      serviceId: 1,
    };
    apiService(`event/home/list`, req, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          if (result.data.event) {
            setEventList(result.data.event);
            setTotalPage(result.data.totalPages);
          }
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    size();
    tanseedswiper();
  }, []);
  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };
  const tanseedswiper = () => {
    if (window.innerWidth > 1440) {
      setTanseedSwipers(1);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setTanseedSwipers(1);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setTanseedSwipers(1);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setTanseedSwipers(1);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setTanseedSwipers(1);
    } else {
      setTanseedSwipers(1);
    }
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handlenow = () => {
    navigate("/login");
  };
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  const handleChipClick = (chip, id, fundID) => {
    setActiveChip(chip);
    setFundId(id);
    setFundVersionId(fundID);
    debugger;
  };

  const handleTanseed = () => {
    window.open("https://tanfund.in/", "_blank", "noopener,noreferrer");
  };
  return (
    <div className="smartCard-page">
      <Navbar />
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <div className="abc">
                  <p>
                    {" "}
                    Implemented by{" "}
                    <img
                      src="/images/startupLogo.png"
                      className="sml-logo"
                      alt=""
                    />
                  </p>
                </div>
                {/* <span>{`Investment Ecosystem   >  TANSEED`}</span> */}
                <h1 className="banner-text-gradient">TANSEED</h1>
                <h6>
                  A Support Equity-linked Grant Fund for scaling Startups!
                </h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn" onClick={handleTanseed}>
                    Apply for Funding
                  </button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            className="tanseed-info"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage">
                <h4>What is TANSEED?</h4>
                <p>
                  Tamil Nadu Seed Fund, is a flagship equity seed investment by
                  the Tamil Nadu government designed to support early-stage
                  startups. This initiative aims to bridge the gap between
                  budding startups and their funding needs, enabling them to
                  scale their operations and bring innovative solutions to
                  market.
                </p>
                <div className="left-content-actions">
                  <button className="ser-viewbtn" onClick={handleTanseed}>
                    Apply for Funding
                  </button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="smartcrdimag img-fluid"
                  src={"/images/startupLogo.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>
          <div className="smartcrd-details-div ">
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                className="tan-image"
              >
                <img
                  src="/images/Banner/TANSEED 6.0 11.png"
                  alt="Tanseed 7.0"
                  className="img-fluid"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={8}
                xl={8}
                className="tan-grid"
              >
                <h3 className="tan-title">
                  <span className="tan">TANSEED</span> 7.0
                </h3>
                <p className="startup-para">
                  StartupTN is inviting applications for ‘TANSEED 7.0’. The
                  revamped TANSEED now functions as a Support Equity-linked
                  grant fund, offering up to Rs. 15 Lakh for Green Tech, Rural
                  Impact, and Women-led Startups, and up to Rs. 10 Lakh for
                  other sectors. StartupTN will take a 3% support stake in
                  startups and provide a one-year accelerator program, including
                  mentorship, priority for networking events, and participation
                  in national and international startup delegations.
                </p>
                <p className="date">
                  Last Date to Apply :{" "}
                  <span className="feb">&nbsp;JANUARY 31, 2025</span>
                </p>
                <div className="left-content-actions">
                  <a href="https://tanfund.in/" target="_blank" >
                    <button className="tan-viewbtn">Apply Now!</button>
                  </a>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="how-works-div">
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="works-section mt-5">
                  <h4>How it Works?</h4>
                  <p>
                    Here are the detailed steps of the SmartCard process for
                    providing Support to startups.
                  </p>
                </div>
                <div className="track"></div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <div className="layer-bg-smcrd">
                  <div className="works-mesh-layer"></div>
                  <div className="how-works-details">
                    <div className="sm-crd-process">
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/smcrd-reg.png"}
                              alt="registration"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              01 <span>Registration :</span>
                            </h3>
                            <p>
                              Start by clicking the apply button, which will
                              take you to
                              <Link to="https://www.tanfund.in/">
                                www.tanfund.in.
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/smcrd.png"}
                              alt="signup"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              02 <span>Sign Up :</span>
                            </h3>
                            <p>
                              Sign up and set up your account on
                              <Link to="https://www.tanfund.in/">
                                www.tanfund.in.
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/fill-application.png"}
                              alt="fill-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              03 <span>Fill the Application:</span>
                            </h3>
                            <p>Log in and share the details of your startup.</p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/submit-application.png"}
                              alt="submit-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <div>
                              <h3>
                                04 <span>Submit Your Application:</span>
                              </h3>
                            </div>
                            <div>
                              <p>
                                Once you’ve provided all the necessary
                                information, click the submit application for
                                TANSEED 6.0 button.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/track-application.png "}
                              alt="track-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              05 <span>Track Your Application:</span>
                            </h3>
                            <p>
                              Keep track of your application status right there
                              on the website.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
        <div className="why-smartcrd-sec">
          <div className="container">
            <div className="tabs pb-5">
              <Box className="mt-5">
                <TabContext value={value}>
                  <div className="tan-tab">
                    <TabList onChange={handleChangetab}>
                      <Tab
                        className={
                          activeTab === "0" ? "altActiveTab" : "tan_lable_name"
                        }
                        label="Eligibility Criteria"
                        value="0"
                      />
                      {/* <Tab
                        className={
                          activeTab === "1" ? "altActiveTab" : "tan_lable_name1"
                        }
                        label="Shortlisting Criteria"
                        value="1"
                      /> */}
                    </TabList>
                  </div>
                  <TabPanel value="0">
                    <div className="tab-1">
                      {" "}
                      <ol className="taseded">
                        <li>
                          The entity should be registered as a Private Limited
                          Company (under Companies Act 2013)(However, if some
                          applying companies are currently registered as LLP or
                          Partnership, These applications will also be reviewed,
                          taking into consideration their willingness to convert
                          the entity registration to Private Limited.)
                        </li>
                        <li>
                          The entity should be a Startup registered with TANSIM
                          and STARTUP INDIA. It shall be located and registered
                          in Tamil Nadu.
                        </li>
                        <li>
                          The average profit of the entity (as per income tax
                          return) for the last 3 years (or lesser in case it’s a
                          newly registered entity) shall be less than Rs.5
                          Lakhs.
                        </li>
                        <li>
                          Working towards innovation, development or improvement
                          of products or processes with a high potential of
                          employment generation, social impact or wealth
                          creation. The startup must be working on an innovative
                          product or technology and not involved in pure service
                          delivery. Have at least a Proof of Concept (PoC) of
                          the Idea or a Minimum Viable Product (MVP). Use
                          technology in its core product or service, or business
                          innovation model, or innovative distribution model, or
                          innovative methodology to solve the problem being
                          targeted.
                        </li>
                        <li>
                          The entity should not have been formed by splitting
                          up, reconstruction of a business already in existence
                          or as a Subsidiary/Joint Venture/Associate of another
                          Company.
                        </li>
                        <li>
                          The startup should have cleared all / not have any
                          other pending dues with any of the Government agencies
                          and shall not be blacklisted by any Government agency
                          in India.
                        </li>
                        <li>
                          The applicant may be incubated in an incubator for at
                          least six months at the time of application.
                        </li>
                        <li>
                          Applicant has to be an Indian start-up. This support
                          is not meant for Indian Subsidiaries of MNCs/Foreign
                          Companies. Persons holding Overseas Citizenship of
                          India (OCI) and Persons of Indian Origin (PIO) would
                          be considered as Indian citizens for the purpose of
                          this scheme. The shareholding by Indian promoters in
                          the incubated Startups should be at least 51 %.
                        </li>
                        <li>
                          The Seed Fund is not generally given for service
                          company working in Traditional Trade and Commerce.
                        </li>
                        <li>
                          The seed fund shall be funding the development of the
                          prototype or a product which is market ready / of
                          small level pilot production only and shall not be for
                          regular research which any University or College can
                          foster.
                        </li>
                      </ol>
                      <p>
                        Note: StartupTN reserves the right to add or modify
                        TANSEED Rules & Regulations and the Implementation
                        Process.
                      </p>
                    </div>
                  </TabPanel>
                  <TabPanel value="1">
                    <div className="tab-1">
                      <ol>
                        <li>
                          The entity should be registered as a Private Limited
                          Company (under Companies Act 2013)(However, if some
                          applying companies are currently registered as LLP or
                          Partnership, These applications will also be reviewed,
                          taking into consideration their willingness to convert
                          the entity registration to Private Limited.)
                        </li>
                        <li>
                          The entity should be a Startup registered with TANSIM
                          and STARTUP INDIA. It shall be located and registered
                          in Tamil Nadu.
                        </li>
                        <li>
                          The average profit of the entity (as per income tax
                          return) for the last 3 years (or lesser in case it’s a
                          newly registered entity) shall be less than Rs.5
                          Lakhs.
                        </li>
                        <li>
                          Working towards innovation, development or improvement
                          of products or processes with a high potential of
                          employment generation, social impact or wealth
                          creation. The startup must be working on an innovative
                          product or technology and not involved in pure service
                          delivery. Have at least a Proof of Concept (PoC) of
                          the Idea or a Minimum Viable Product (MVP). Use
                          technology in its core product or service, or business
                          innovation model, or innovative distribution model, or
                          innovative methodology to solve the problem being
                          targeted.
                        </li>
                        <li>
                          The entity should not have been formed by splitting
                          up, reconstruction of a business already in existence
                          or as a Subsidiary/Joint Venture/Associate of another
                          Company.
                        </li>
                        <li>
                          The startup should have cleared all / not have any
                          other pending dues with any of the Government agencies
                          and shall not be blacklisted by any Government agency
                          in India.
                        </li>
                        <li>
                          The applicant may be incubated in an incubator for at
                          least six months at the time of application.
                        </li>
                        <li>
                          Applicant has to be an Indian start-up. This support
                          is not meant for Indian Subsidiaries of MNCs/Foreign
                          Companies. Persons holding Overseas Citizenship of
                          India (OCI) and Persons of Indian Origin (PIO) would
                          be considered as Indian citizens for the purpose of
                          this scheme. The shareholding by Indian promoters in
                          the incubated Startups should be at least 51 %.
                        </li>
                        <li>
                          The Seed Fund is not generally given for service
                          company working in Traditional Trade and Commerce.
                        </li>
                        <li>
                          The seed fund shall be funding the development of the
                          prototype or a product which is market ready / of
                          small level pilot production only and shall not be for
                          regular research which any University or College can
                          foster.
                        </li>
                      </ol>
                      <p>
                        Note: StartupTN reserves the right to add or modify
                        TANSEED Rules & Regulations and the Implementation
                        Process.
                      </p>
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
        {/* <div className="join-us-now-sec1">
          <Container>
            <div className="portfolio-partners-title">
              <h4>Portfolio Startups</h4>
              <p>Here are the Portfolio Startups of TANSEED</p>
            </div>
            <div className="chips">
              <Button
                variant={activeChip === "TANSEED 1.0" ? "contained" : "outlined"}
                className={activeChip === "TANSEED 1.0" ? "tan-chip1" : "tan-chip2"}
                onClick={() => handleChipClick("TANSEED 1.0", 8, 12)}
              >
                TANSEED 1.0
              </Button>
              <Button
                variant={activeChip === "TANSEED 2.0" ? "contained" : "outlined"}
                className={activeChip === "TANSEED 2.0" ? "tan-chip1" : "tan-chip2"}
                onClick={() => handleChipClick("TANSEED 2.0", 8, 13)}
              >
                TANSEED 2.0
              </Button>
              <Button
                variant={activeChip === "TANSEED 3.0" ? "contained" : "outlined"}
                className={activeChip === "TANSEED 3.0" ? "tan-chip1" : "tan-chip2"}
                onClick={() => handleChipClick("TANSEED 3.0", 8, 14)}
              >
                TANSEED 3.0
              </Button>
              <Button
                variant={activeChip === "TANSEED 4.0" ? "contained" : "outlined"}
                className={activeChip === "TANSEED 4.0" ? "tan-chip1" : "tan-chip2"}
                onClick={() => handleChipClick("TANSEED 4.0", 8, 15)}
              >
                TANSEED 4.0
              </Button>
              <Button
                variant={activeChip === "TANSEED 5.0" ? "contained" : "outlined"}
                className={activeChip === "TANSEED 5.0" ? "tan-chip1" : "tan-chip2"}
                onClick={() => handleChipClick("TANSEED 5.0")}
              >
                TANSEED 5.0
              </Button>
            </div>
            <div className="service-crd">
              <Swiper
                slidesPerView={screenSize}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                  renderBullet: function (index, className) {
                    return `<span class="${className}" style="background-color: orange;"></span>`;
                  },
                }}
                modules={[Pagination]}
                className="mySwiper"
                style={{ paddingBottom: "50px" }}
              >
                {portfolioData.length > 0 ? (<>
                  {portfolioData && portfolioData.map((list, i) => (
                    <SwiperSlide>
                      <Card className="ser-crds" key={i}>
                        <div className="service-card-back-layer">
                          <CardMedia
                            component="img"
                            height="180"
                            image="/images/crd-backlayer.png"
                            alt="green iguana"
                          />
                          <div className="service-card-back-prof">
                            <img
                              src={list.logo}
                              alt="crd-img"
                              className="service-card-back-image"
                            />
                          </div>
                          <div className="crd-back-tanseeds">
                            
                            <div className="service-card-back-prof">
                            <img
                              src={list.logo}
                              alt="crd-img"
                              className="service-card-back-image1"
                            />
                          </div>
                          </div>
                        </div>
                        <CardContent
                          sx={{
                            textAlign: "center",
                            padding: "24px 0px 0px 0px",
                          }}
                        >
                          <p className="ser-crd-title-text">{list.startupName}</p>
                        </CardContent>
                      </Card>
                    </SwiperSlide>

                  ))
                  }</>) : (<>
                    <div className="text-center w-100 my-5 nodatacss">
                      <div>
                        <img
                          src="/images/blue horizontal.png"
                          alt="blue horizontal.png"
                          className="logo_dash img-fluid"
                        />
                      </div>
                      <div className="mt-5">
                        <h3 className="title_p opa">StartupTN 3.0</h3>
                      </div>
                    </div>
                  </>)}

               <SwiperSlide>
                  {" "}
                  <Card className="ser-crds">
                    <div className="service-card-back-layer">
                      <CardMedia
                        component="img"
                        height="180"
                        image="/images/crd-backlayer.png"
                        alt="green iguana"
                      />
                      <div className="service-card-back-prof">
                        <img
                          src={"/images/imerge.png"}
                          alt="crd-img"
                          className="service-card-back-image"
                        />
                      </div>
                    </div>
                    <CardContent
                      sx={{
                        textAlign: "center",
                        padding: "24px 0px 0px 0px",
                      }}
                    >
                      <p className="ser-crd-title-text">Imerge</p>
                    </CardContent>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className="ser-crds">
                    <div className="service-card-back-layer">
                      <CardMedia
                        component="img"
                        height="180"
                        image="/images/crd-backlayer.png"
                        alt="green iguana"
                      />
                      <div className="service-card-back-prof">
                        <img
                          src={"/images/Digiit.png "}
                          alt="crd-img"
                          className="service-card-back-image"
                        />
                      </div>
                    </div>
                    <CardContent
                      sx={{
                        textAlign: "center",
                        padding: "24px 0px 0px 0px",
                      }}
                    >
                      <p className="ser-crd-title-text">Digiit</p>
                    </CardContent>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className="ser-crds">
                    <div className="service-card-back-layer">
                      <CardMedia
                        component="img"
                        height="180"
                        image="/images/crd-backlayer.png"
                        alt="green iguana"
                      />
                      <div className="service-card-back-prof">
                        <img
                          src={"/images/hiver.png"}
                          alt="crd-img"
                          className="service-card-back-image"
                        />
                      </div>
                    </div>
                    <CardContent
                      sx={{
                        textAlign: "center",
                        padding: "24px 0px 0px 0px",
                      }}
                    >
                      <p className="ser-crd-title-text">Hiver</p>
                    </CardContent>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className="ser-crds">
                    <div className="service-card-back-layer">
                      <CardMedia
                        component="img"
                        height="180"
                        image="/images/crd-backlayer.png"
                        alt="green iguana"
                      />
                      <div className="service-card-back-prof">
                        <img
                          src={"/images/k7-security.png"}
                          alt="crd-img"
                          className="service-card-back-image"
                        />
                      </div>
                    </div>
                    <CardContent
                      sx={{
                        textAlign: "center",
                        padding: "24px 0px 0px 0px",
                      }}
                    >
                      <p className="ser-crd-title-text">K7 Security</p>
                    </CardContent>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <Card className="ser-crds">
                    <div className="service-card-back-layer">
                      <CardMedia
                        component="img"
                        height="180"
                        image="/images/crd-backlayer.png"
                        alt="green iguana"
                      />
                      <div className="service-card-back-prof">
                        <img
                          src={"/images/imerge.png"}
                          alt="crd-img"
                          className="service-card-back-image"
                        />
                      </div>
                    </div>
                    <CardContent
                      sx={{
                        textAlign: "center",
                        padding: "24px 0px 0px 0px",
                      }}
                    >
                      <p className="ser-crd-title-text">Imerge</p>
                    </CardContent>
                  </Card>
                </SwiperSlide>
              </Swiper>
            </div>
          </Container>
        </div> */}
        <div className="faq-section">
          <div className="faq-content">
            <Container>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                  <div className="faq-box-div">
                    <h3>Frequently Asked Questions</h3>
                    <div className="accordion">
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <div className="d-flex w-100">
                            <p>1. What is TANSEED?</p>
                            <div className="ml-auto">
                              {expanded === "panel1" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          The Tamil Nadu Startup Seed Fund (TANSEED) is a
                          Government of Tamil Nadu initiative that aims to
                          support early-stage Startups in Tamil Nadu. The fund
                          provides seed funding to Startup founders in an equity
                          model to help them develop their products, validate
                          their ideas and scale up their businesses.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <div className="d-flex  w-100">
                            <p>2. How often will TANSEED be launched?</p>
                            <div className="ml-auto">
                              {expanded === "panel2" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>Every Quarter</AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary>
                          <div className="d-flex  w-100 ">
                            <p>
                              3. What are eligibility criterias for TANSEED?
                            </p>
                            <div className="ml-auto">
                              {expanded === "panel3" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          a. Have at least a Proof of Concept (PoC) of the Idea
                          or a Minimum Viable Product (MVP).
                          <br />
                          <br />
                          b. Registered with StartupTN and Startup India and the
                          applicant should be an Indian citizen.
                          <br />
                          <br />
                          c. Located and headquartered in Tamil Nadu.
                          <br />
                          <br />
                          d. Registered as a Private Limited Company <br />
                          <br />
                          e. Work towards innovation, commercialization,
                          development or improvement of products or processes
                          with a high potential of employment generation, social
                          impact or wealth creation.
                          <br />
                          <br />
                          f. Use technology in its core product or service, or
                          business innovation model, or innovative distribution
                          model, or innovative methodology to solve the problem
                          being targeted.
                          <br />
                          <br />
                          g. Not have been formed by splitting up,
                          reconstruction of a business already in existence or
                          as a Subsidiary/Joint Venture/Associate of another
                          Company.
                          <br />
                          <br />
                          h. Not be blacklisted by any Government agency in
                          India.
                          <br />
                          <br />
                          i. StartupTN reserves the right to add or modify
                          TANSEED Rules & Regulations and the Implementation
                          Process.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel4"}
                        onChange={handleChange("panel4")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary
                          aria-controls="panel4-content"
                          id="panel2-header"
                        >
                          <div className="d-flex  w-100">
                            <p>4. How to apply for TANSEED ?</p>
                            <div className="ml-auto">
                              {expanded === "panel4" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          You need to login our website and apply TANSEED
                          application
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel5"}
                        onChange={handleChange("panel5")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary
                          aria-controls="panel5-content"
                          id="panel2-header"
                        >
                          <div className="d-flex  w-100">
                            <p>5. What will be the fund amount for TANSEED?</p>
                            <div className="ml-auto">
                              {expanded === "panel5" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          Under TANSEED, the seed fund supports up to Rs. 15
                          Lakh to Green Tech, Rural Impact, and Women-led
                          Startups and up to Rs. 10 Lakh each to other Startups.
                          StartupTN will take a 3% stake in the Startup ventures
                          against the seed fund.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary
                          aria-controls="panel6-content"
                          id="panel2-header"
                        >
                          <div className="d-flex  w-100">
                            <p>
                              6. When will the next TANSEED edition be launched?
                            </p>
                            <div className="ml-auto">
                              {expanded === "panel6" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          In month of May 2023
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel7"}
                        onChange={handleChange("panel7")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary
                          aria-controls="panel7-content"
                          id="panel2-header"
                        >
                          <div className="d-flex  w-100">
                            <p>
                              7. Can a startup once rejected reapply for the
                              next TANSEED edition?
                            </p>
                            <div className="ml-auto">
                              {expanded === "panel7" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          Yes, startups can apply for TANSEED any number of
                          times.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel8"}
                        onChange={handleChange("panel8")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary
                          aria-controls="panel8-content"
                          id="panel2-header"
                        >
                          <div className="d-flex  w-100">
                            <p>
                              8. Once a startup applies for TANSEED, How long
                              should they wait for the results?
                            </p>
                            <div className="ml-auto">
                              {expanded === "panel8" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          Evaluation process takes approximately 1 - 2 months
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  {/* <div className="tans-div">
                    <h3 className="eveing">Events</h3>
                    <Swiper
                      slidesPerView={tanseedSwipers}
                      spaceBetween={30}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay]}
                      className="my-Swiper"
                    >
                      {eventList.length > 0 ? (
                        <>
                          {eventList &&
                            eventList.map((item, i) => (
                              <SwiperSlide key={i}>
                                <div className="faq-box-2-flex">
                                  <div className="faq-box2-img">
                                    <img
                                      src={item.imageName}
                                      alt="smartcard-img"
                                      className="tans-get"
                                    />
                                  </div>
                                  <div className="faq-box2-content">
                                    <h4>{item.title}</h4>
                                    <p> {item.formattedTime}</p>
                                    <div className="tan-content-actions">
                                      <button className="tan-viewbtn" onClick={handlenow}>
                                        Enroll Now
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                        </>
                      ) : (
                        <>
                          <p className="eve-no-fou">Events Not Found</p>
                        </>
                      )}
                    </Swiper>
                  </div> */}
                  <div className="set-support-div">
                    <div className="faq-support-content">
                      <div className="faq-support-img">
                        <img src={"/images/smcrd-person.png"} alt="person" />
                      </div>
                      <div className="faq-support-para">
                        <p>
                          Need support for a Tanseed related query Don't worry,
                          we're here to help you!
                        </p>
                        <p>
                          <span style={{ color: "#FFB100" }}>Contact</span> -
                          155343 , (+91) 44 22256789
                        </p>
                        <p>
                          <span style={{ color: "#FFB100" }}>Email</span> -
                          support@startuptn.in
                        </p>
                        {/* <span className="support-links">
                          <p onClick={handlenow}>Click here to raise a support ticket</p>
                        </span> */}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h4>Let’s Get Started</h4>
                    <p>Tamil Nadu's investment Gateway for scaling Startups!</p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default FundingTanseed;
