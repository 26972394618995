import { Autocomplete, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, Stack, Switch, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import DataContext from '../../../context/DataContext';
import { useParams } from 'react-router-dom';
import { FaInfoCircle } from "react-icons/fa";
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import notifyService from '../../../api/notifySerivce';
import { BiTransfer } from "react-icons/bi";

const Joinusproductadd = ({ path }) => {
    const { id, smartcardid, productid } = useParams()
    let ids
    let smartcardIds
    if (productid !== undefined) {
        ids = atob(productid)
    }
    if (smartcardid !== undefined) {
        smartcardIds = atob(smartcardid)
    }
    useDidMountEffect(() => {
        if (productid) {
            getApi()
        }
    }, [])

    const [formData, setFormData] = useState()
    const { handleSubmit, control, setValue, getValues, watch, clearErrors, setError, formState: { errors } } = useForm({ values: formData, mode: 'onChange' });
    const { navigator, validateNumberonly, validatedecimalNumber, setPageLoading, isWhitespace, isValidPercentage, validatediscountonly } = useContext(DataContext);
    const [addidata, setAddidata] = useState(false)
    const [datapricetype, setDatapricetype] = useState(false)
    const [pricecuttypes, setPricecuttypes] = useState("")

    const getApi = () => {
        setPageLoading(true)
        apiService(`smartcard/product/get?privilegeid=${ids}`, "", "get")
            .then((result) => {
                var data = result.data;
                setPageLoading(false)

                if (data.additionalCharges) {
                    const additionalCharges = data.additionalCharges === true;
                    setValue("additionalCharges", additionalCharges);

                }
                if (data.additionalChargePrice === 0) {
                    data.additionalChargePrice = ""
                }
                if (data.priceType === "Fixed") {
                    setpriceTypestate("Fixed");
                }
                else {
                    setpriceTypestate("Range");
                }
                if (data.priceCutType) {
                    setDatapricetype(data.priceCutType)
                    if (data.priceCutType === "Fixed") {
                        setPricecuttypes("Fixed")
                    } else if (data.priceCutType === "Range") {
                        setPricecuttypes("Range")
                    }
                    else {
                        setPricecuttypes("")
                    }
                }
                if (data.offerDuration) {
                    var durat = Duration.find(duration => duration.Durations === data.offerDuration)
                    setSelectedDuration(durat || null);
                }
                setFormData(result.data)
                console.log(result)
            }).catch((err) => {
                console.log(err);

            });
    }

    const onsubmit = (data) => {
        const additionalChargePrice = data.additionalChargePrice !== "" ? Number(data.additionalChargePrice) : ""

        const payload = {
            ...data,
            smartcardPriceFrom: Number(data.smartcardPriceFrom ? data.smartcardPriceFrom : ""),
            smartcardPriceTo: Number(data.smartcardPriceTo ? data.smartcardPriceTo : ""),
            discount: Number(data.discount ? data.discount : ""),
            offerTime: Number(data.offerTime),
            additionalChargePrice: additionalChargePrice,
            smartcardId: Number(smartcardIds),
            priceRangeFrom: Number(data.priceRangeFrom),
            priceRangeTo: Number(data.priceRangeTo ? data.priceRangeTo : ""),
            priceCutType: pricecuttypes ? pricecuttypes : false
        };
        console.log(payload);

        setPageLoading(true)
        apiService("smartcard/product/save", payload, "post")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data && result.data.responseStatus === "Success") {
                    navigator(`/smartcard/joinuspartner/${id}/list/${smartcardid}`)

                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const handlePriceMode = (value) => {
        setValue("period", value);
        clearErrors("period");
    };
    const [priceTypestate, setpriceTypestate] = useState("");
    const handlePriceType = (value) => {
        setpriceTypestate(value)
        setValue('priceCutType', '')
        setPricecuttypes('')
        setValue("priceType", value);
        setValue("priceRangeFrom", "")
        setValue("priceRangeTo", "")
        setValue("smartcardPriceFrom", "")
        setValue("smartcardPriceTo", "")
        setValue('discount', "")
        clearErrors("priceType");
        clearErrors("priceRangeFrom");
        clearErrors("priceRangeTo");
    }
    const handleTypeMode = (value) => {

        setValue("offerType", value);
        setValue('priceCutType', '')
        setPricecuttypes('')
        clearErrors("offerType");
        setValue('discount', "")
        setValue("smartcardPriceFrom", "")
        setValue("smartcardPriceTo", "")

        if (value === "Free") {
            setValue("smartcardPriceFrom", "");
            setValue("smartcardPriceTo", "");
            setValue("additionalChargeDesc", "");
            setValue("additionalChargePrice", "");
            setValue("additionalCharges", false);
        }
    };
    const [selectedDuration, setSelectedDuration] = useState("")
    const Duration = [
        { Durations: "Day(s)" },
        { Durations: "Month(s)" },
        { Durations: "Year(s)" },
    ];
    const handleDuration = (value) => {
        setSelectedDuration(value);
        setValue('offerDuration', value ? value.Durations : '');
        clearErrors('offerDuration')
    };
    const chargesaddi = watch("additionalCharges", false);
    const handleFilter = (isadd) => {
        setValue("additionalCharges", isadd);
        setAddidata(isadd)

        if (!isadd) {
            setValue("additionalChargeDesc", "");
            setValue("additionalChargePrice", "");

        }
    };
    const enableoffer = watch("enableOfferDuration", false);
    const handleFilterenable = (isadd) => {
        setValue("enableOfferDuration", isadd);
        setAddidata(isadd)
        setValue("offerTime", "");
        setValue("offerDuration", '');
        setSelectedDuration("")
        if (!isadd) {
            setValue("offerTime", "");
            setValue("offerDuration", '');
            setSelectedDuration("")
        }
    };
    const handlePricecutType = (value) => {
        setPricecuttypes(value)
        clearErrors('priceCutType')
        setValue('priceCutType', value)
        setValue("smartcardPriceFrom", "");
        setValue("smartcardPriceTo", "");
        clearErrors('smartcardPriceFrom')
        clearErrors('smartcardPriceTo')
    }
    const handleBackjoin = () => {
        navigator(`/smartcard/joinuspartner/${id}/list/${smartcardid}`)
    }
    const onError = (event) => {
        console.log("error Data:", event);
        notifyService("danger", "Submit Failed", "Please check the mandatory fields");
    };
    const handleInputclear = () => {
        setValue('smartcardPriceFrom', "")
        clearErrors('smartcardPriceFrom')
    }
    const handleInputclearfrom = () => {
        setValue('smartcardPriceTo', "")
        clearErrors('smartcardPriceTo')
    }
    const handleprodClear = () => {
        setValue('priceRangeTo', "")
        clearErrors('priceRangeTo')
        setValue('smartcardPriceFrom', "")
        clearErrors('smartcardPriceFrom')


    }
    const handleprodClearto = () => {
        setValue('smartcardPriceTo', "")
        clearErrors('smartcardPriceTo')
    }
    const handleInputChange = (e, onChange) => {
        let value = e.target.value;
        const intermediateRegex = /^\d{0,8}(\.\d{0,2})?$/;
        if (intermediateRegex.test(value)) {
            if (value.includes('.')) {
                value = value.replace(/^0+(?=\d)/, '');
            } else {
                value = value.replace(/^0+(?!$)/, '');
            }
            onChange(value);
        }
    };



    // const handleInputChange = (e, onChange) => {
    //     let value = e.target.value;
    //     const intermediateRegex = /^\d{0,8}(\.\d{0,2})?$/;
    //     if (intermediateRegex.test(value)) {

    //         if (value.includes('.')) {
    //             value = value.replace(/^0+/, '0');
    //         } else {
    //             value = value.replace(/^0+/, '');
    //         }
    //         onChange(value);
    //     }
    // };
    return (
        <Container>
            <div className='joinus-design'>
                <div >
                    <span className='bck-join c-pointer' onClick={() => handleBackjoin()}>{`< Back`}</span>
                </div>
                <div className='title-joinus text-center'>
                    <h3 className='join-h3 mb-2 ml-5'>Add Products / Services</h3>
                    <div className='my-3'>
                        <img src="/images/join-us/second.png" alt="tab" />
                    </div>
                </div>

            </div>
            <form onSubmit={handleSubmit(onsubmit, onError)}>
                <div className='pb-4'>
                    <fieldset className="fieldset-container">
                        <legend className='legend-container3'><span>Add Products / Services</span></legend>
                        <Grid container direction="row" alignItems="flex-start" jusifyContent="center" spacing={3}>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <div className="input_background mb-3">
                                    <Controller
                                        name="name"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "Please enter Product/Service Name", validate: {
                                                noWhitespace: value => !isWhitespace(value) || 'Please enter Valid Product/Service Name'
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Product / Service Name *"
                                                placeholder=" Enter Product / Service Name"
                                                fullWidth
                                                type="text"
                                                {...field}
                                                inputProps={{ maxLength: 75 }}
                                                error={Boolean(errors.name && errors.name)}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.name && errors.name.message}
                                    </FormHelperText>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="input_background mb-3">
                                    <Controller
                                        name="description"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: false,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Product / Service Description "
                                                placeholder=" Enter Product / Service Description"
                                                fullWidth
                                                type="text"
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.description &&
                                            errors.description.message}
                                    </FormHelperText>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className="input_background mb-3">
                                    <Controller
                                        name="period"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Please Choose Price / Period" }}
                                        render={({ field }) => (
                                            <FormControl>
                                                <FormLabel sx={{ fontWeight: '600', color: '#000' }}> Price / Period *</FormLabel>
                                                <RadioGroup
                                                    row
                                                    {...field}
                                                    onChange={(e) => handlePriceMode(e.target.value)}
                                                >
                                                    <FormControlLabel
                                                        value={"Annually"}
                                                        control={<Radio />}
                                                        label="Annually"
                                                    />
                                                    <FormControlLabel
                                                        value={"Monthly"}
                                                        control={<Radio />}
                                                        label="Monthly"
                                                    />
                                                    <FormControlLabel
                                                        value={"Nil"}
                                                        control={<Radio />}
                                                        label="Nil"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.period && errors.period.message}
                                    </FormHelperText>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className="input_background mb-3">
                                    <Controller
                                        name="priceType"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Please Choose Price Type " }}
                                        render={({ field }) => (
                                            <FormControl>
                                                <FormLabel sx={{ fontWeight: '600', color: '#000' }}> Price Type *</FormLabel>
                                                <RadioGroup
                                                    row
                                                    {...field}
                                                    onChange={(e) => handlePriceType(e.target.value)}
                                                >
                                                    <FormControlLabel
                                                        value={"Fixed"}
                                                        control={<Radio />}
                                                        label="Fixed Price"
                                                    />
                                                    <FormControlLabel
                                                        value={"Range"}
                                                        control={<Radio />}
                                                        label="Price Range"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.priceType && errors.priceType.message}
                                    </FormHelperText>
                                </div>
                            </Grid>
                            {priceTypestate === "Fixed" && (
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className="input_background mb-3">
                                        <Controller
                                            name="priceRangeFrom"
                                            control={control}
                                            rules={{
                                                required: 'Please Enter Price',
                                                validate: (value) => {
                                                    if (!value || isNaN(value)) {
                                                        return 'Please Enter a valid Price';
                                                    }
                                                    if (parseFloat(value) < 0.01) {
                                                        return 'Please Enter a valid Price';
                                                    }
                                                    return true;
                                                },
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    variant="outlined"
                                                    label="Product / Service Price *"
                                                    placeholder="Enter Product / Service Price"
                                                    fullWidth
                                                    type="text"
                                                    value={field.value}
                                                    onInput={() => handleInputclear()}
                                                    onChange={(e) => handleInputChange(e, field.onChange)}
                                                    inputProps={{
                                                        maxLength: 11,
                                                    }}
                                                    error={Boolean(errors.priceRangeFrom)}
                                                />
                                            )}
                                        />
                                        <FormHelperText>
                                            <FaInfoCircle style={{ color: 'darkgray' }} /> Enter price including GST
                                        </FormHelperText>
                                        <FormHelperText className="text-danger">
                                            {errors.priceRangeFrom && errors.priceRangeFrom.message}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                            )}
                            {priceTypestate === "Range" && (
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className="input_background mb-3">
                                        <FormLabel sx={{ fontWeight: '600', color: '#000' }}>Product / Service Price Range *</FormLabel>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <div>
                                                <Controller
                                                    name="priceRangeFrom"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: "Please Enter From Price",
                                                        validate: (value) => {
                                                            if (!value || isNaN(value)) {
                                                                return 'Please Enter a valid Price';
                                                            }
                                                            if (parseFloat(value) < 0.01) {
                                                                return 'Please Enter a valid Price';
                                                            }
                                                            return true;
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            label="From *"
                                                            placeholder="Enter From Price"
                                                            fullWidth
                                                            type="text"
                                                            {...field}
                                                            onInput={() => handleprodClear()}
                                                            // onKeyDown={(e) => validateNumberonly(e)}
                                                            onChange={(e) => handleInputChange(e, field.onChange)}
                                                            inputProps={{ maxLength: 11 }}
                                                            error={Boolean(errors.priceRangeFrom)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.priceRangeFrom && errors.priceRangeFrom.message}
                                                </FormHelperText>
                                            </div>
                                            <div>
                                                <BiTransfer />
                                            </div>
                                            <div>
                                                <Controller
                                                    name="priceRangeTo"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: "Please Enter To Price",
                                                        validate: (value) => {

                                                            if (parseFloat(value) <= parseFloat(getValues("priceRangeFrom"))) {
                                                                return "To Price must be greater than From Price";
                                                            }
                                                            if (!value || isNaN(value)) {
                                                                return 'Please Enter a valid Price';
                                                            }
                                                            if (parseFloat(value) < 0.01) {
                                                                return 'Please Enter a valid Price';
                                                            }
                                                            return true;

                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            label="To *"
                                                            placeholder="Enter To Price"
                                                            fullWidth
                                                            type="text"
                                                            {...field}
                                                            onInput={() => handleprodClearto()}
                                                            onChange={(e) => handleInputChange(e, field.onChange)}
                                                            // onKeyDown={(e) => validateNumberonly(e)}
                                                            inputProps={{ maxLength: 11 }}
                                                            error={Boolean(errors.priceRangeTo)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.priceRangeTo && errors.priceRangeTo.message}
                                                </FormHelperText>
                                            </div>
                                        </div>
                                        <FormHelperText>
                                            <FaInfoCircle style={{ color: 'darkgray' }} /> Enter price including GST
                                        </FormHelperText>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>

                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <div className="input_background mb-3">
                                    <Controller
                                        name="offerType"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Please choose Type of offer" }}
                                        render={({ field }) => (
                                            <FormControl>
                                                <FormLabel sx={{ fontWeight: '600', color: '#000' }}> Type of Offer *</FormLabel>
                                                <RadioGroup
                                                    row
                                                    {...field}
                                                    onChange={(e) => {
                                                        handleTypeMode(e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        value={"Discount"}
                                                        control={<Radio />}
                                                        label="Discount"
                                                    />
                                                    <FormControlLabel
                                                        value={"Price Cut"}
                                                        control={<Radio />}
                                                        label="Price Cut"
                                                    />
                                                    <FormControlLabel
                                                        value={"Free"}
                                                        control={<Radio />}
                                                        label="Free"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.offerType && errors.offerType.message}
                                    </FormHelperText>
                                </div>
                            </Grid>
                            {priceTypestate === "Range" && getValues('offerType') === "Price Cut" ? (<>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className="input_background mb-3">
                                        <Controller
                                            name="priceCutType"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Please Choose Price Cut Type " }}
                                            render={({ field }) => (
                                                <FormControl>
                                                    <FormLabel sx={{ fontWeight: '600', color: '#000' }}> Price Cut Type *</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        {...field}
                                                        onChange={(e) => handlePricecutType(e.target.value)}
                                                    >
                                                        <FormControlLabel
                                                            value={"Fixed"}
                                                            control={<Radio />}
                                                            label="Fixed Price"
                                                        />
                                                        <FormControlLabel
                                                            value={"Range"}
                                                            control={<Radio />}
                                                            label="Price Range"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.priceCutType && errors.priceCutType.message}
                                        </FormHelperText>
                                    </div>
                                </Grid></>)
                                : (<>
                                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                        {watch("offerType") === "Discount" && priceTypestate === "Fixed" && (
                                            <div className="input_background mb-3">
                                                <Controller
                                                    name="smartcardPriceFrom"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: "Please Enter Smart Card Discount",
                                                        validate: value => isValidPercentage(value) || "Discount Must be between 1 and 100"
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            label="Smart Card Discount *"
                                                            placeholder="Enter Discount "
                                                            fullWidth
                                                            type="text"
                                                            {...field}
                                                            sx={{
                                                                "& .MuiInputLabel-root.Mui-focused": {
                                                                    color: "#002ECE",
                                                                    fontWeight: 500
                                                                },
                                                            }}
                                                            onKeyDown={(e) => validatediscountonly(e)}
                                                            inputProps={{ maxLength: 5 }}
                                                            error={Boolean(errors.smartcardPriceFrom && errors.smartcardPriceFrom)}
                                                        />
                                                    )}
                                                />

                                                <FormHelperText className="text-danger">
                                                    {errors.smartcardPriceFrom && errors.smartcardPriceFrom.message}
                                                </FormHelperText>
                                            </div>
                                        )}
                                        {watch("offerType") === "Discount" && priceTypestate === "Range" && (
                                            <div className="input_background mb-3">
                                                <Controller
                                                    name="discount"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: "Please Enter Smart Card Discount",
                                                        validate: value => isValidPercentage(value) || "Discount Must be between 1 and 100"
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            label="Smart Card Discount *"
                                                            placeholder="Enter Discount "
                                                            fullWidth
                                                            type="text"
                                                            {...field}
                                                            sx={{
                                                                "& .MuiInputLabel-root.Mui-focused": {
                                                                    color: "#002ECE",
                                                                    fontWeight: 500
                                                                },
                                                            }}
                                                            // onKeyDown={(e) => validateNumberonly(e)}
                                                            // inputProps={{ maxLength: 3 }}
                                                            onKeyDown={(e) => validatediscountonly(e)}
                                                            inputProps={{ maxLength: 5 }}
                                                            error={Boolean(errors.discount && errors.discount)}
                                                        />
                                                    )}
                                                />

                                                <FormHelperText className="text-danger">
                                                    {errors.discount && errors.discount.message}
                                                </FormHelperText>
                                            </div>
                                        )}
                                        {watch("offerType") === "Price Cut" && (
                                            <>
                                                {getValues('priceType') === "Range" ? (
                                                    <>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="input_background mb-3">
                                                            <Controller
                                                                name="smartcardPriceFrom"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{
                                                                    required: "Please Enter Smart Card Price",
                                                                    validate: {
                                                                        notZero: value => Number(value) > 0 || "Smart Card Price must be greater than zero",
                                                                        lessThanPrice: value => {
                                                                            const priceValue = getValues("priceRangeFrom");

                                                                            if (Number(value) >= Number(priceValue)) {
                                                                                return "Smart Card Price must be less than the Price";
                                                                            }
                                                                            // if (parseFloat(value) <= parseFloat(getValues("priceRangeFrom"))) {
                                                                            //     return "To Price must be greater than From Price";
                                                                            // }
                                                                            if (!value || isNaN(value)) {
                                                                                return 'Please Enter a valid Price';
                                                                            }
                                                                            if (parseFloat(value) < 0.01) {
                                                                                return 'Please Enter a valid Price';
                                                                            }

                                                                            return true; // Valid input
                                                                        }
                                                                    }

                                                                }}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label="Smart Card Price *"
                                                                        placeholder="Enter Smartcard Price"
                                                                        fullWidth
                                                                        type="text"
                                                                        {...field}
                                                                        // onKeyDown={(e) => validateNumberonly(e)}
                                                                        onChange={(e) => handleInputChange(e, field.onChange)}
                                                                        inputProps={{ maxLength: 11 }}
                                                                        error={Boolean(errors.smartcardPriceFrom)}
                                                                        sx={{
                                                                            "& .MuiInputLabel-root.Mui-focused": {
                                                                                color: "#002ECE",
                                                                                fontWeight: 500
                                                                            },
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <FormHelperText>
                                                                <FaInfoCircle style={{ color: 'darkgray' }} /> Enter price including GST
                                                            </FormHelperText>
                                                            <FormHelperText className="text-danger">
                                                                {errors.smartcardPriceFrom && errors.smartcardPriceFrom.message}
                                                            </FormHelperText>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}


                                    </Grid>
                                </>)}

                            {pricecuttypes === "Fixed" && (<>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className="input_background mb-3">
                                        <Controller
                                            name="smartcardPriceFrom"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Please Enter Smart Card Price",
                                                validate: {
                                                    notZero: value => {
                                                        // Check if the value is greater than 0
                                                        return Number(value) > 0 || "Smart Card Price must be greater than Zero";
                                                    },
                                                    lessThanPrice: value => {
                                                        const priceValue = getValues("priceRangeTo");
                                                        // Check if the value is less than priceRangeTo
                                                        return Number(value) < Number(priceValue) || "Smart Card Price must be less than the Price";
                                                    },
                                                    greaterThanPrice: value => {
                                                        const priceValue = getValues("priceRangeFrom");
                                                        // Check if the value is greater than priceRangeFrom
                                                        return Number(value) > Number(priceValue) || "Smart Card Price must be Greater than the Price";
                                                    },
                                                    isValidPrice: value => {
                                                        // Ensure the value is a valid number and not zero
                                                        if (!value || isNaN(value)) {
                                                            return 'Please Enter a valid Price';
                                                        }
                                                        if (parseFloat(value) < 0.01) {
                                                            return 'Please Enter a valid Price';
                                                        }

                                                        return true;
                                                    }
                                                }

                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="Smart Card Price *"
                                                    placeholder="Enter Smartcard Price"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    // onKeyDown={(e) => validateNumberonly(e)}
                                                    onChange={(e) => handleInputChange(e, field.onChange)}
                                                    inputProps={{ maxLength: 11 }}
                                                    error={Boolean(errors.smartcardPriceFrom)}
                                                    sx={{
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: "#002ECE",
                                                            fontWeight: 500
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                        <FormHelperText>
                                            <FaInfoCircle style={{ color: 'darkgray' }} /> Enter price including GST
                                        </FormHelperText>
                                        <FormHelperText className="text-danger">
                                            {errors.smartcardPriceFrom && errors.smartcardPriceFrom.message}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                            </>)}
                            {(pricecuttypes === "Range") ? (
                                <>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <div className="input_background mb-3">
                                            <FormLabel sx={{ fontWeight: '600', color: '#000' }}>Smart Card Price *</FormLabel>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                                <div>
                                                    <Controller
                                                        name="smartcardPriceFrom"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: "Please Enter Price Cut From",
                                                            validate: (value) => {
                                                                if (!value || isNaN(value)) {
                                                                    return 'Please Enter a valid Price';
                                                                }
                                                                if (parseFloat(value) < 0.01) {
                                                                    return 'Please Enter a valid Price';
                                                                }
                                                                const fromPrice = parseFloat(getValues("priceRangeFrom"));
                                                                return value < fromPrice || "Price Cut From must be less than Product/Service Price Range From";
                                                            }

                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                variant="outlined"
                                                                label="From *"
                                                                placeholder="Enter Price Cut From"
                                                                fullWidth
                                                                type="text"
                                                                {...field}
                                                                onInput={() => handleInputclearfrom()}
                                                                // onKeyDown={(e) => validateNumberonly(e)}
                                                                onChange={(e) => handleInputChange(e, field.onChange)}
                                                                inputProps={{ maxLength: 11 }}
                                                                error={Boolean(errors.smartcardPriceFrom && errors.smartcardPriceFrom)}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.smartcardPriceFrom && errors.smartcardPriceFrom.message}
                                                    </FormHelperText>
                                                </div>
                                                <div>
                                                    <BiTransfer />
                                                </div>
                                                <div>
                                                    <Controller
                                                        name="smartcardPriceTo"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: "Please Enter To Price Cut",
                                                            validate: (value) => {
                                                                const priceRangeTo = parseFloat(getValues("priceRangeTo"));
                                                                const priceRangeFrom = parseFloat(getValues("priceRangeFrom"));
                                                                const smartcardPriceFrom = parseFloat(getValues("smartcardPriceFrom"));


                                                                // Check if 'To' price is less than 'From' price
                                                                if (parseFloat(value) <= smartcardPriceFrom) {
                                                                    return "To Price Cut must be greater than From Price Cut";
                                                                }

                                                                // Check if 'To' price is less than Product/Service Price Range 'To'
                                                                if (parseFloat(value) >= priceRangeTo) {
                                                                    return "Price Cut To must be less than Product/Service Price Range To";
                                                                }
                                                                if (!value || isNaN(value)) {
                                                                    return 'Please Enter a valid Price';
                                                                }
                                                                if (parseFloat(value) < 0.01) {
                                                                    return 'Please Enter a valid Price';
                                                                }

                                                                return true; // Validation passed
                                                            }
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                variant="outlined"
                                                                label="To *"
                                                                placeholder="Enter Price Cut To"
                                                                fullWidth
                                                                type="text"
                                                                {...field}
                                                                // onKeyDown={(e) => validateNumberonly(e)}
                                                                onChange={(e) => handleInputChange(e, field.onChange)}
                                                                inputProps={{ maxLength: 11 }}
                                                                error={Boolean(errors.smartcardPriceTo && errors.smartcardPriceTo)}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.smartcardPriceTo && errors.smartcardPriceTo.message}
                                                    </FormHelperText>
                                                </div>
                                            </div>
                                            <FormHelperText>
                                                <FaInfoCircle style={{ color: 'darkgray' }} /> Enter price including GST
                                            </FormHelperText>
                                        </div>
                                    </Grid>
                                </>) : (<></>)}
                        </Grid>
                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3} className='mt-3'>

                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <div className="form-group mt-2">
                                    <div className='d-flex  align-items-center ml-3'>
                                        <span>Offer Duration :</span>
                                        <span className="ml-3 d-flex align-items-center">
                                            <Controller
                                                name="enableOfferDuration"
                                                control={control}
                                                defaultValue={false}
                                                render={({ field: { onChange, value } }) => (
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        No
                                                        <Switch
                                                            className="mx-2"
                                                            checked={value}
                                                            onChange={() => { onChange(!value); handleFilterenable(!value) }}
                                                            inputProps={{ 'aria-label': 'ant design' }}
                                                        />
                                                        Yes
                                                    </Stack>
                                                )}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </Grid>


                            {
                                enableoffer && (<>
                                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                        <div className="input_background mb-3">
                                            <Controller
                                                name="offerTime"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: "Please Enter Duration",
                                                    validate: (value) => {
                                                        const numericValue = parseInt(value, 10);
                                                        if (isNaN(numericValue) || numericValue <= 0) {
                                                            return "Invalid Duration";
                                                        }
                                                        return true;
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        variant="outlined"
                                                        label="Offer Valid Duration *"
                                                        placeholder=" Enter Duration"
                                                        fullWidth
                                                        type="text"
                                                        {...field}
                                                        onKeyDown={(e) => validateNumberonly(e)}
                                                        inputProps={{ maxLength: 2 }}
                                                        error={Boolean(errors.offerTime && errors.offerTime)}

                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.offerTime && errors.offerTime.message}
                                            </FormHelperText>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={4} md={4} xl={4}>
                                        <div className='input_background mb-3'>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Controller
                                                    name="offerDuration"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: true }}
                                                    render={({ field, fieldState: { error } }) => (
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            options={Duration}
                                                            getOptionLabel={(option) => option.Durations}
                                                            value={selectedDuration || null}
                                                            onChange={(event, newValue) => {
                                                                handleDuration(newValue);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Duration *"
                                                                    placeholder="Select Duration"
                                                                    error={Boolean(errors.offerDuration && errors.offerDuration)}
                                                                />
                                                            )}
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                />
                                                {errors.offerDuration && (
                                                    <FormHelperText className="text-danger">
                                                        {errors.offerDuration.message || 'Please select Duration '}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>
                                    </Grid>

                                </>)}

                        </Grid>
                        <Grid container spacing={2}>
                            {getValues('offerType') !== "Free" && (
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <div className="form-group mt-2">
                                        <div className='d-flex  align-items-center ml-3'>
                                            <span>Additional Charges :</span>
                                            <span className="ml-3 d-flex align-items-center">
                                                <Controller
                                                    name="additionalCharges"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            No
                                                            <Switch
                                                                className="mx-2"
                                                                checked={value}
                                                                onChange={() => { onChange(!value); handleFilter(!value) }}
                                                                inputProps={{ 'aria-label': 'ant design' }}
                                                            />
                                                            Yes
                                                        </Stack>
                                                    )}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </Grid>
                            )}

                            {
                                chargesaddi && (
                                    <>

                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>

                                            <div className="input_background mb-3">
                                                <Controller
                                                    name="additionalChargeDesc"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: "Please enter Additional Charge Description",
                                                        validate: {
                                                            noWhitespace: value => !isWhitespace(value) || 'Please enter valid Additional Charge Description'
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            label="Additional Charge Description *"
                                                            placeholder="Deployment Charge"
                                                            fullWidth
                                                            type="text"
                                                            {...field}
                                                            inputProps={{ maxLength: 50 }}
                                                            error={Boolean(errors.additionalChargeDesc && errors.additionalChargeDesc)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText>
                                                    <FaInfoCircle style={{ color: 'darkgray', marginRight: '2px' }} />Max 50 Characters
                                                </FormHelperText>
                                                <FormHelperText className="text-danger">
                                                    {errors.additionalChargeDesc && errors.additionalChargeDesc.message}
                                                </FormHelperText>
                                            </div>

                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                            <div className="input_background mb-3">
                                                <Controller
                                                    name="additionalChargePrice"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: false,
                                                        validate: (value) => {
                                                            if (!value || isNaN(value)) {
                                                                return 'Please Enter a valid Price';
                                                            }
                                                            if (parseFloat(value) < 0.01) {
                                                                return 'Please Enter a valid Price';
                                                            }
                                                            return true;
                                                        },

                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            label="Additional Charge price"
                                                            placeholder=" Enter Additional Charge price"
                                                            fullWidth
                                                            type="text"
                                                            {...field}
                                                            // onKeyDown={(e) => validateNumberonly(e)}
                                                            // inputProps={{ maxLength: 8 }}
                                                            onChange={(e) => handleInputChange(e, field.onChange)}
                                                            inputProps={{ maxLength: 11 }}
                                                            error={Boolean(errors.additionalChargePrice && errors.additionalChargePrice)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText>
                                                    <FaInfoCircle style={{ color: 'darkgray' }} /> Enter price including GST
                                                </FormHelperText>
                                                <FormHelperText className="text-danger">
                                                    {errors.additionalChargePrice && errors.additionalChargePrice.message}
                                                </FormHelperText>
                                            </div>
                                        </Grid>

                                    </>
                                )}
                        </Grid>
                        <div className='text-center mt-4'>
                            {/* <button className='add-sub-join-btn' type="submit">Submit</button> */}
                            <button className='add-sub-join-btn' type="submit">
                                {path === "editprod" ? "Update" : "Submit"}
                            </button>
                        </div>
                    </fieldset>

                </div>
            </form>
        </Container>
    )
}

export default Joinusproductadd