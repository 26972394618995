import React, { useContext, useState } from "react";
import {
    Autocomplete,
    Box,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { Link, NavLink, useParams } from "react-router-dom";
import { BiSearchAlt } from "react-icons/bi";
import { MdArrowDropDownCircle } from "react-icons/md";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { Badge } from "@mui/material";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import DataContext from "../../../../context/DataContext";
import Navbar from "../../../home-module/navbar-page/Navbar";


const Smartservicereq = () => {
    const { id } = useParams()
    const { navigator, setPageLoading } = useContext(DataContext);
    let ids
    if (id !== null) {
        ids = atob(id)
    }
    const [value, setValue] = useState("1");
    const [searchInputValue, setSearchInputValue] = useState();
    const [showForm, setShowForm] = useState(false);
    const [sectorId, setSectorId] = useState();
    const [activeButtonMain, setActiveButtonMain] = useState("secs-btn-1");
    const [selectedPeriod, setSelectedPeriod] = useState("All");
    const [filterStatus, setFilterStatus] = useState(4)
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [tableDats, setTableDats] = useState([])
    const [matrixData, setMatrixData] = useState([])
    const [sectors, setSectors] = useState([]);
    const [sectorValue, setSectorValue] = useState("");


    const smids = localStorage.getItem('joinusId',)
    let useid = localStorage.getItem('id')
    let reqId = localStorage.getItem("userpersonaId");

    const handleButtonClicks = (btnClass3, val) => {
        setActiveButtonMain(btnClass3);
        setFilterStatus(val)
    };

    useDidMountEffect(() => {
        sectorsdropdownApi();
        matrixApi()
    }, []);

    useDidMountEffect(() => {
        servicelistApi()
    }, [searchInputValue, page, selectedPeriod, sectorId, filterStatus]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleSearch = (e) => {
        setSearchInputValue(e.target.value);
    };

    const sectorsdropdownApi = () => {
        setPageLoading(true)
        var req = {
            smartcardId: Number(smids)
        }
        apiService(`smartcard/servicerequest/product/list`, req, "post")
            .then((result) => {
                setPageLoading(false)
                if (result) {
                    if (result.data) {
                        setSectors(result.data.products);
                    }
                }
            })
            .catch((err) => { });
    };

    const servicelistApi = () => {
        setPageLoading(true)
        setTableDats([])
        var req = {
            pageNumber: page,
            listSize: 100,
            searchString: searchInputValue,
            smartCardProductServiceId: sectorId,
            status: filterStatus,
            period: selectedPeriod,
            smartCardId: Number(smids)
        }
        apiService('smartcard/servicerequest/list', req, "post")
            .then((res) => {
                setPageLoading(false)
                if (res && res.data) {
                    setTotalPage(res.data.totalPages);
                    if (res.data.requestedServices) {
                        setTableDats(res.data.requestedServices)
                    }
                }
            }).catch((err) => {
                console.log(err);

            });
    }
    const matrixApi = () => {
        setPageLoading(true)
        var numuseid = Number(smids)
        apiService(`smartcard/servicerequest/status/consolidated?smartCardId=${numuseid}`, "", "get")
            .then((res) => {
                setPageLoading(false)
                if (res && res.data) {
                    setMatrixData(res.data)
                }
            }).catch((err) => {
                console.log(err)
            });
    }
    const handleStatusUpd = (val, id) => {
        setPageLoading(true)
        var req = {
            smartcardServiceRequestProductId: id,
            status: val
        }
        debugger
        apiService('smartcard/servicerequest/status/save', req, "post")
            .then((res) => {
                setPageLoading(false)
                matrixApi()
                servicelistApi()
            }).catch((err) => {
                console.log(err);

            });
    }
    const handleSelectSector = (e, name) => {
        if (name !== null) {
            setSectorValue(name);
            var value = sectors.filter((option) => option.name === name);
            setSectorId(value[0].id);
        } else {
            setSectorValue("");
            setSectorId("")
        }
    };
    const handleSelectPeriod = (value) => {
        setSelectedPeriod(value);
    };

    const handleBackjoin = () => {
        navigator(`/smartcard/${btoa(ids)}/service`)
    }
    return (
        <div style={{ background: '#f2f5fa' }}>
            <Navbar />
            <Container>
                <div className='joinus-design'>
                    <div className='mt-4'>
                        <span className='bck-join c-pointer' onClick={() => handleBackjoin()}>{`< Back`}</span>
                    </div>
                </div>
                <div className='title-joinus text-center'>
                    <h2 className='join-h3 mb-2'>Service Requests</h2>
                    <div>
                        <div className="profile-box2 mt-3">
                            <div className="inv-dynamic-text">
                                <div>
                                    <h5>
                                        Total Requests
                                        <Badge color="error" badgeContent={tableDats.length} className="ml-4"></Badge>
                                    </h5>
                                </div>
                                <div class="search-bar-sm-crd4">
                                    <BiSearchAlt className="upd-search-eco" /> <input type="text" class="search-input" onChange={handleSearch} placeholder="Search Startups" autoFocus={true} />
                                </div>
                            </div>

                            <div className="inv-startup-filters-divs mt-3">
                                <div className="name-filters-sm-page mt-3">
                                    {/* {showForm && ( */}
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                    >
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="list-item-calender-button2">
                                                <div className="frt-fil">
                                                    <div className="all-btn-bg1">
                                                        <button
                                                            className={`secs-btn-1 ${activeButtonMain === "secs-btn-1" ? "active" : ""}`}
                                                            onClick={() => handleButtonClicks("secs-btn-1", 4)} >
                                                            All
                                                        </button>
                                                        <button
                                                            className={`secs-btn-1 ${activeButtonMain === "secs-btn-5" ? "active" : ""}`}
                                                            onClick={() => handleButtonClicks("secs-btn-5", 0)}>
                                                            New Requests
                                                        </button>
                                                        <button
                                                            className={`secs-btn-2 ${activeButtonMain === "secs-btn-2" ? "active" : ""}`}
                                                            onClick={() => handleButtonClicks("secs-btn-2", 1)}>
                                                            Inprogress
                                                        </button>
                                                        <button
                                                            className={`secs-btn-3 ${activeButtonMain === "secs-btn-3" ? "active" : ""}`}
                                                            onClick={() => handleButtonClicks("secs-btn-3", 3)}>
                                                            Rejected
                                                        </button>
                                                        <button
                                                            className={`secs-btn-4 ${activeButtonMain === "secs-btn-4" ? "active" : ""
                                                                }`}
                                                            onClick={() => handleButtonClicks("secs-btn-4", 2)}
                                                        >
                                                            Closed
                                                        </button>
                                                    </div>
                                                </div>


                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                        >
                                            <div className="text-left">
                                                <FormControl
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ width: 150 }}
                                                >
                                                    <InputLabel id="dropdown-label">Duration</InputLabel>
                                                    <Select
                                                        labelId="dropdown-label"
                                                        label="Select Option"
                                                        value={selectedPeriod}
                                                        variant="outlined"
                                                        style={{ borderRadius: 10 }}
                                                        onChange={(e) => handleSelectPeriod(e.target.value)}
                                                    >
                                                        <MenuItem value={"All"}>All</MenuItem>
                                                        <MenuItem value={"This Week"}>This Week</MenuItem>
                                                        <MenuItem value={"This Month"}>This Month</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {/* )} */}
                                </div>
                                <div className="name-filters-sm-page mt-3">
                                    {/* {showForm && ( */}
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                    >
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Autocomplete
                                                // disableClearable
                                                disablePortal
                                                id="combo-box-demo"
                                                value={sectorValue}
                                                options={sectors.map((option) => option.name)}
                                                popupIcon={<MdArrowDropDownCircle />}
                                                onChange={(e, selectedOption) =>
                                                    handleSelectSector(e, selectedOption)
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Product / Service Name"
                                                        placeholder="Select Product / Service Name"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            sx={{ textAlign: "end" }}
                                        >
                                            <div className="inv-chip-selection">
                                                <p>
                                                    Showing Results : <span>{tableDats.length}</span>
                                                </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {/* )} */}
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Grid item sm={12} md={3} lg={3}>
                                    <Box className="widget-boxs">
                                        <p>Total Request</p>
                                        <h3>{matrixData.totalRequest}</h3>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={3} lg={3}>
                                    <Box className="widget-boxs">
                                        <p>Leads Converted</p>
                                        <h3>{matrixData.leadsConverted}</h3>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={3} lg={3}>
                                    <Box className="widget-boxs">
                                        <p>In Progress</p>
                                        <h3>{matrixData.inProgress}</h3>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={3} lg={3}>
                                    <Box className="widget-boxs">
                                        <p>Total Income </p>
                                        <h3>{matrixData.totalIncome}</h3>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <TableContainer component={Paper} className="table-container">
                                <Table sx={{ borderCollapse: "separate", borderSpacing: "0 10px" }}>
                                    <TableHead>
                                        <TableRow className="titles1">
                                            <TableCell align="center">ID</TableCell>
                                            <TableCell align="center">Startup Name</TableCell>
                                            <TableCell align="center">Service Requested</TableCell>
                                            <TableCell align="center">Total Nos</TableCell>
                                            <TableCell align="center">Lead Status</TableCell>
                                            <TableCell align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableDats && tableDats.map((a, i) => (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    position: "relative",
                                                    "&:after": {
                                                        content: '""',
                                                        position: "absolute",
                                                        top: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        border: "1px solid rgba(223, 224, 224, 1)",

                                                        pointerEvents: "none",
                                                        marginBottom: "-1px",
                                                    },
                                                    "& > *": {
                                                        padding: "10px",
                                                        backgroundColor: "#fff",
                                                        "&:last-of-type": {},
                                                    },
                                                    marginBottom: "10px",
                                                }}
                                                className="Sup-list"
                                            >
                                                <TableCell align="center">
                                                    <span className="tab1-clr1">{a.uid}</span>
                                                </TableCell >
                                                <TableCell align="center">
                                                    <span className="list-aca">{a.startupName}</span>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <span className="list-place">{a.productName}</span>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <span className="list2-prof">{a.totalNo}</span>
                                                </TableCell>
                                                <TableCell align="center">

                                                    <span className="list2-ran">
                                                        <span
                                                            className={`c-pointer 
                                                        ${a.leadStatus === 0 ? 'new-btn-6-clr' : ''}
                                                        ${a.leadStatus === 2 ? 'new-btn-7-clr' : ''}
                                                        ${a.leadStatus === 3 ? 'new-btn-8-clr' : ''}
                                                        ${a.leadStatus === 1 ? 'new-btn-9-clr' : ''}`}
                                                        >
                                                            {a.leadStatus === 0 && "New"}
                                                            {a.leadStatus === 2 && "Closed"}
                                                            {a.leadStatus === 1 && "Inprogress"}
                                                            {a.leadStatus === 3 && "Rejected"}
                                                        </span>
                                                    </span>
                                                </TableCell>

                                                {(a.leadStatus  !== 3 && a.leadStatus  !== 2 )&&(<TableCell align="center">
                                                    <div className="act-links" style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: "1px" }}>
                                                        <button className="call-dem-btn" onClick={() => handleStatusUpd(1, a.id)}>Call Demo</button>
                                                        <button className="deal-dem-btn" onClick={() => handleStatusUpd(2, a.id)}>Deal Closed</button>
                                                        <button className="deal-dem-btnr" onClick={() => handleStatusUpd(3, a.id)}>Reject</button>
                                                    </div>
                                                </TableCell>)}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Smartservicereq