import { Button, Container, Grid, Pagination, Stack } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const Joinusproductlist = () => {
    const { navigator, setPageLoading } = useContext(DataContext);
    const { id, smartcardid } = useParams()
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    let ids
    if (smartcardid !== undefined) {
        ids = atob(smartcardid)
        console.log(ids, "smartcardid");

    }
    const getId = localStorage.getItem('id')
    useDidMountEffect(() => {
        listApi()
    }, [page])
    const [priid, setPriid] = useState()
    const [listDatas, setListDatas] = useState([])
    const listApi = () => {
        setPageLoading(true)
        var req = {
            listSize: 6,
            smartcardId: Number(ids),
            pageNumber: page
        }
        apiService(`smartcard/product/list`, req, "post")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    setTotalPage(result.data.totalPages)
                    setPriid(result.data.smartcardId)
                    if (result.data.products) {
                        var data = result.data.products;
                        setListDatas(data)
                    }
                }

            })
            .catch((err) => {
                console.log(err)
            });

    };

    const handlepageChange = (event, value) => {
        setPage(value);
    };
    const handleBackjoin = () => {
        navigator(`/smartcard/joinuspartner/${btoa(getId)}/info`)
    }
    const handleaddPage = () => {
        navigator(`/smartcard/joinuspartner/${id}/add/${smartcardid}`)
    }
    const handleEdit = (smart) => {
        navigator(`/smartcard/joinuspartner/${id}/update/${smartcardid}/${btoa(smart)}`)
    }
    const whileSubmit = () => {
        navigator(`/smartcard/joinuspartner/${btoa(ids)}/eoi/${btoa(priid)}`)
    }
    // const listDatas = [
    //     {
    //         id: 1,
    //         title: "K7 Antivirus Premium /user /year",
    //         description: "Amizhth is a software development company with expertise in website building",
    //         marketPrice: "Rs. 1,199/-",
    //         smartCardPrice: "Free",
    //         offerDuration: "6 Months",
    //         additionalCharges: "NA",
    //     },
    //     {
    //         id: 2,
    //         title: "K7 Antivirus Premium /user /year",
    //         description: "Amizhth is a software development company with expertise in website building",
    //         marketPrice: "Rs. 1,199/-",
    //         smartCardPrice: "Free",
    //         offerDuration: "6 Months",
    //         additionalCharges: "NA",
    //     },
    //     {
    //         id: 3,
    //         title: "K7 Antivirus Premium /user /year",
    //         description: "Amizhth is a software development company with expertise in website building",
    //         marketPrice: "Rs. 1,199/-",
    //         smartCardPrice: "Free",
    //         offerDuration: "6 Months",
    //         additionalCharges: "NA",
    //     },
    // ];

    return (
        <Container>
            <div className='joinus-design'>
                <div className='mt-4'>
                    <span className='bck-join c-pointer' onClick={() => handleBackjoin()}>{`< Back`}</span>
                </div>
                <div className='title-joinus text-center pb-3'>
                    <h2 className='join-h3 mb-2'>Join as a Service Partner</h2>
                    <p>Join our network of trusted service partners and grow together.</p>
                    <div className='my-3'>
                        <img src="/images/join-us/second.png" alt="tab" />
                    </div>
                    <h6><b>List Down your Product & Services</b></h6>
                </div>
            </div>

            {listDatas.length > 0 ? (
                <div>
                    <Container maxWidth="lg">
                        <div className="profile-box-join mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                    <h5>Add Product / Services </h5>
                                    <p className="para-join">
                                        Provide details about the services or products you offer,
                                        including pricing,<br /> terms, and any special offers for our
                                        members.
                                    </p>
                                </div>
                                <div className="modal-smcrd-service">
                                    <button
                                        className="product-button"
                                        sx={{ textTransform: "none" }}
                                        size="small"
                                        onClick={handleaddPage}
                                    >
                                        Add More
                                    </button>

                                </div>
                            </div>

                        </div>
                        <div className="pb-5">
                            <div className="profile-box-joincrd">
                                <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className="join-grey-box">
                                            <span>Products / Services</span>
                                        </div>
                                    </Grid>
                                    {listDatas && listDatas.map((item, index) => (
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
                                            <div className='join-cards'>
                                                <div className="crd-j-tit">
                                                    <span>{item.name}</span>
                                                    <p>{item.description}</p>
                                                </div>
                                                <div className="pricing-details my-3">
                                                    {item.priceRangeTo ? (<p>Market Price: <span>Rs. &nbsp;{item.priceRangeFrom} - {item.priceRangeTo}</span></p>) : (<p>Market Price: <span>Rs. &nbsp;{item.priceRangeFrom}</span></p>)}
                                                    {/* {item.offerType === "Discount" && item.priceType === "Range" ? (
                                                        <p>
                                                            Discount :
                                                            <span className='free-join'>
                                                                {item.discount}%
                                                            </span>
                                                        </p>
                                                    ) : (

                                                        <p>
                                                            Smart Card Price:
                                                            <span className='free-join'>
                                                                {item.discountedPriceTo
                                                                    ? `Rs ${item.discountedPriceFrom} - ${item.discountedPriceTo}`
                                                                    : `Rs ${item.discountedPriceFrom}`}
                                                            </span>
                                                        </p>
                                                    )} */}
                                                    {/* {item.offerType === "Price Cut" && (
                                                        <p>
                                                            Discount:
                                                            <span className='free-join'>
                                                                {item.discount}%
                                                            </span>
                                                        </p>
                                                    )}

                                                    {item.offerType === "Free" && (
                                                        <p>
                                                            Smart Card Price:
                                                            <span className='free-join'>
                                                                Free
                                                            </span>
                                                        </p>
                                                    )
                                                    } */}
                                                    {
                                                        item.priceType === "Fixed" && item.offerType === "Discount" && (
                                                            <>
                                                                <p>
                                                                    Smart Card Price:
                                                                    <span className='free-join'>
                                                                        {
                                                                            `Rs ${item.discountedPriceFrom}`}
                                                                    </span>
                                                                </p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        item.priceType === "Range" && item.offerType === "Discount" && (
                                                            <>
                                                                <p>
                                                                    Discount :
                                                                    <span className='free-join'>
                                                                        <span className='free-join'>

                                                                            {item.discount} %

                                                                        </span>
                                                                    </span>
                                                                </p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        item.priceType === "Fixed" && item.offerType === "Price Cut" && (
                                                            <>
                                                                <p>
                                                                    Smart Card Price:
                                                                    <span className='free-join'>
                                                                        {
                                                                            `Rs. ${item.discountedPriceFrom}`}
                                                                    </span>
                                                                </p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        item.priceType === "Range" && item.offerType === "Price Cut" && (
                                                            <>
                                                               {item.discountedPriceTo > 0 ?( <p>
                                                                    Smart Card Price:
                                                                    <span className='free-join'>

                                                                        Rs. {item.discountedPriceFrom} - {item.discountedPriceTo}
                                                                    </span>
                                                                </p>):( <p>
                                                                    Smart Card Price:
                                                                    <span className='free-join'>

                                                                        Rs. {item.discountedPriceFrom}
                                                                    </span>
                                                                </p>)}
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        item.offerType === "Free" && (
                                                            <>
                                                                <p>
                                                                    Smart Card Price:
                                                                    <span className='free-join'>
                                                                        Free
                                                                    </span>
                                                                </p>
                                                            </>
                                                        )
                                                    }
                                                    <p>
                                                        Offer Duration: {item.enableOfferDuration !== false ? (
                                                            <span>{item.offerTime}&nbsp;{item.offerDuration}</span>
                                                        ) : (
                                                            <span>-</span>
                                                        )}
                                                    </p>

                                                    <p style={{ borderBottom: 'none', height: '70px' }}>
                                                      <span style={{width: '68%'}}>  {item.additionalChargeDesc ? `${item.additionalChargeDesc} (Additional Charges)` : "Additional Charges:"}</span>


                                                        {item.additionalCharges === false && (<span>
                                                            {"NA"}
                                                        </span>)}
                                                        {item.additionalChargePrice ? (
                                                            <div className='text-right' style={{ color: '#2E8EFF', fontWeight: '600' }}>
                                                                {item.additionalChargeDesc && item.additionalChargePrice ? `Rs.${item.additionalChargePrice}` : ""}
                                                            </div>) : (<></>)}
                                                    </p>
                                                </div>
                                                <div className="text-center">
                                                    <button className='ed-joi-btns' onClick={() => handleEdit(item.id)}>Edit</button>
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                                <div className="event-pagination">
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={totalPage}
                                            page={page}
                                            onChange={handlepageChange}
                                        />
                                    </Stack>
                                </div>
                            </div>
                        </div>
                        <div className='text-center mt-2 pb-4'>
                            <button className='add-sub-join-btn' onClick={() => whileSubmit()}>Submit</button>
                        </div>
                    </Container>
                </div>) : (
                <div className='text-center mb-5'>
                    <img src={'/images/join-us/OBJECTS.png'} alt="images" className=' my-5' />
                    <div >
                        <h6><b>Nothing to Show</b></h6>
                        <div className='Nothing my-4'>
                            <p className='mb-0'>It looks like you haven’t added any products or services yet. </p>
                            <p> Start by adding your first offering to showcase what you can provide.</p>
                        </div>
                    </div>
                    <div className='join-prod-act pb-5'>
                        <Button variant='contained' size='large' sx={{ background: "#0B40AA", textTransform: 'none' }} onClick={() => handleaddPage()}>Add Products / Services</Button>
                    </div>
                </div>
            )}
        </Container>
    )
}

export default Joinusproductlist