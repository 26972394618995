import React from 'react'
import Navbar from '../navbar-page/Navbar'
import { Container, Grid } from '@mui/material'
import Footer from '../footer-page/Footer'
import { Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, Typography, Stack, Pagination } from '@mui/material';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import { useState } from 'react';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

const ResourcePage = () => {

    useDidMountEffect(() => {
        ShowgetApi()
    }, []);

    const [policy, setPolicy] = useState([])
    const ShowgetApi = (value) => {
        // setPageLoading(true)
        apiService('home/policyandnotification/list', "", "get")
            .then((result) => {
                // setPageLoading(false)
                if (result && result.data && result.data.policyAndNotifications ) {
                    setPolicy(result && result.data && result.data.policyAndNotifications)
                }
            }).catch((err) => {
                console.log(err);
                
            });
    }

    return (
        <>
            <div className="back-color1">
                <Navbar />
                <div className="back-layer"></div>
                <div className="back-layer1"></div>
                <div className="top-layer"></div>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {/* <Container> */}
                        <div className="main-title">
                            <span className='res-bg'>Resources</span>
                            <h3 className='res-h3 mb-4'>Policies and Notifications</h3>
                            <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '80%', margin: '0px auto' }}>
                                <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                                    <TableHead sx={{ position: 'relative' }} >
                                        <TableRow sx={{
                                            position: 'relative',
                                            '&:after': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                borderRadius: '20px',
                                                pointerEvents: 'none',
                                                marginBottom: '-1px',
                                            },
                                            '& > *': {
                                                padding: '18px',
                                                backgroundColor: '#0C0249',
                                                textAlign: 'center',
                                                '&:first-of-type': { borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' },
                                                '&:last-of-type': { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' },
                                            }
                                        }}>
                                            <TableCell sx={{ color: 'white', textAlign: 'center' }}>No</TableCell>
                                            {/* <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell> */}
                                            <TableCell sx={{ color: 'white' }}>Description</TableCell>
                                            <TableCell sx={{ color: 'white', textAlign: 'center' }}>View</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ position: 'relative' }}>
                                        {policy.length > 0 ? (
                                            policy.map((row, index) => (
                                                <TableRow
                                                    sx={{
                                                        position: 'relative',
                                                        '&:after': {
                                                            content: '""',
                                                            position: 'absolute',
                                                            top: 0,
                                                            bottom: 0,
                                                            left: 0,
                                                            right: 0,
                                                            // border: '1px solid rgba(224, 224, 224, 1)',
                                                            borderRadius: '20px',
                                                            pointerEvents: 'none',
                                                            marginBottom: '-1px',
                                                        },
                                                        '& > *': {
                                                            // padding: '10px',
                                                            backgroundColor: '#fff',
                                                            '&:first-of-type': { borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' },
                                                            '&:last-of-type': { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' },
                                                        },
                                                        marginBottom: '10px',
                                                    }}
                                                    key={index}
                                                    className="Sup-list-res"
                                                // onClick={() => handleDetails(list.id)}
                                                >
                                                    <TableCell style={{ textAlign: 'center' }}> {index + 1} </TableCell>
                                                    {/* <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell> */}
                                                    <TableCell style={{ textAlign: 'left' }}> {row.name} </TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}>
                                                        <a href={row.fileName} target="_blank" rel="noopener noreferrer">
                                                            <SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} />
                                                        </a>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    No reports available
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <h3 className='res-h3 mb-4'>Government Orders</h3>
                            <TableContainer className='mb-3' component={Paper} sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '80%', margin: '0px auto' }}>
                                <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                                    <TableHead sx={{ position: 'relative' }} >
                                        <TableRow className='government-table-list' sx={{
                                            position: 'relative',
                                            '&:after': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                borderRadius: '20px',
                                                pointerEvents: 'none',
                                                marginBottom: '-1px',
                                            },
                                            '& > *': {
                                                padding: '18px',
                                                backgroundColor: '#0C0249',
                                                textAlign: 'center',
                                                '&:first-of-type': { borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' },
                                                '&:last-of-type': { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' },
                                            }
                                        }}>
                                            {/* <TableCell sx={{ color: 'white', textAlign: 'center' }}>No</TableCell> */}
                                            <TableCell sx={{ color: 'white',paddingLeft:'25px' }}>Policies Launched</TableCell>
                                            <TableCell sx={{ color: 'white' }}>Launch Date</TableCell>
                                            <TableCell sx={{ color: 'white' }}>Nodal Department</TableCell>
                                            <TableCell sx={{ color: 'white' }}>Document Type and Docs</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ position: 'relative' }}>
                                        <TableRow className="Sup-list-res" >
                                            {/* <TableCell style={{ textAlign: 'center' }}>1</TableCell> */}
                                            <TableCell className='Government-Orders-list' >Procurement relaxation for Startups</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>22-07-2021</TableCell>
                                            <TableCell >Micro. Small and Medium Enterprises Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank"
                                                    href="/pdf/Procurement.pdf"
                                                ><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} />
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >Low Value Procurement from Startups</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>05-12-2022</TableCell>
                                            <TableCell >Finance Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/LowValue.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >Additional Capital Subsidy-
                                                The announcement made by Hon’ble Chief Minister under rule 110 on the floor of the Assembly on 24.03.2020 Inclusion of Electrical Vehicle component and charging infrastructure and its components manufacturing MSMEs under Thrust sector category and to provide Special Incentive for MSMEs.</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>02-07-2020</TableCell>
                                            <TableCell >Micro. Small and Medium Enterprises Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/Additional.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >Information Technology department policy note</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>-</TableCell>
                                            <TableCell >Information Technology Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/Information.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >GO on Special packages for Green Tech Startups, Rural Impact Startups and Women Led Startups 2022</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>26.12.2022</TableCell>
                                            <TableCell >Micro. Small and Medium Enterprises Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/GOon.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >Industries, Investment Promotion & Commerce Department – Operational Guidelines and Clarification to the Tamil Nadu FinTech Policy 2021</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>14.12.2022</TableCell>
                                            <TableCell >Industries, Investment Promotion & Commerce Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/Industries.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >Guidelines for Tamil Nadu Startup Seed Grant Fund</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>18.06.2022</TableCell>
                                            <TableCell >Micro. Small and Medium Enterprises Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/Guidelines.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >Creation of Corpus of Rs.30.00 Crore with Institutional structure, Type of Assistance, Eligibility conditions and Plan of Implementation – To assist Startups by SC/ST Entrepreneurs</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>06.07.2022</TableCell>
                                            <TableCell >Micro. Small and Medium Enterprises Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/Creation.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >Establishment of Warehouse for Start-ups at Chennai	</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>29.08.2019</TableCell>
                                            <TableCell >Information Technology Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/Establishment.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >Digital Accelerator Programme by American Tamil Entrepreneurs Association</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>08.01.2021</TableCell>
                                            <TableCell >Industries Department	</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/Digital.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >Innovation Voucher Program</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>19.06.2018</TableCell>
                                            <TableCell >Micro. Small and Medium Enterprises Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/Innovation.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >Implementation of the Tamil Nadu Electric Vehicle Policy	</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>9.10.2019</TableCell>
                                            <TableCell >Industries Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/Implementation.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >TN Emerging Sector Seed Fund</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>19.11.2020</TableCell>
                                            <TableCell >Industries Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/TNEmerging.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="Sup-list-res" >
                                            <TableCell className='Government-Orders-list' >TiDEL Startup Fund</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>18.07.2019</TableCell>
                                            <TableCell >Industries Department</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <a target="_blank" href="/pdf/TiDEL.pdf"><SimCardDownloadIcon style={{ color: '#2b377a', cursor: 'pointer' }} /> </a>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                        {/* <div className="main-title-head">
                                <center><span className='res-bg'>Resources</span></center>
                                <h3 className='res-h3 mb-4 text-center'>Public Procurements</h3>

                                <div className='public-box'>
                                    <h4 className='ml-3'><b>Concessions to the registered Startups for Procurement by a Procuring Entity:</b></h4>
                                    <p className='m-3'>To achieve this, the Government of Tamil Nadu provided relaxations in bidding parameters for public procurement by the State Government through<a href='' style={{color: 'blue', textDecoration: 'none'}}> Government Order No.40 MSME (A) Department dated 22 July 2021.</a></p>
                                    <p className='m-3'>The Government after careful examination has decided to provide the following concessions to the registered Startups for public procurements by a Procuring Entity (as defined in Transparency in Tenders Act, 1998) for procurements that are below Rs.20 Lakh in value to improve the Startup ecosystem in the State.</p>

                                    <h5 className='mt-5 ml-3'><b>As per the Government Order stated above, the following concessions have been provided to Startups:</b></h5>

                                    <ol>
                                        <li>Exemption from Payment of Earnest Money Deposit</li>
                                        <li>Exemption from the condition of prior turnover.</li>
                                        <li>Exemption from the condition of prior experience.</li>
                                        <li>Waiver of tender cost.</li>
                                    </ol>

                                    <h5 className='mt-5 ml-3'><b>Procurement Entity shall mean the following:</b></h5>

                                    <ol>
                                        <li>Government Departments.</li>
                                        <li>Public Sector Undertakings of the Government.</li>
                                        <li>Local Bodies in the State.</li>
                                        <li>Co-operative Institutions in the State.</li>
                                        <li>Universities</li>
                                        <li>Societies formed by the Government.</li>
                                    </ol>
                                </div>
                            </div> */}

                        {/* <div className="main-title-head">
                                <h3 className='res-h3 mb-4 text-center'>Contact Us</h3>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                    spacing={2}
                                    className='my-5'
                                >
                                    <Grid item xs={12} sm={12} md={4} lg={4} >
                                        <div className='white-box text-center'>
                                            <center><img src='/images/CS-Ph.png' className='img-fluid mb-3' alt='' /></center>
                                            <h6 className='belu-clr mt-2'><b>Contact - 155343</b></h6>
                                            <h6 className='belu-clr'><b>(+91) 44 22256789</b></h6>
                                            <p className='gru-clr'>Dedicated helpline for startups</p>
                                            <p>Monday - Friday<br />10.00 AM - 06.00 PM</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <div className='white-box text-center'>
                                            <center><img src='/images/CS-loc.png' className='img-fluid mb-3' alt='' /></center>
                                            <h6 className='belu-clr mt-2'><b>Office Address</b></h6>
                                            <p className='gru-clr'>Office Space, 10th Floor, Chennai Metro Rail Limited, Metro Station, Tamil Nadu Startup and Innovation Mission (StartupTN, near Nandanam, Nandanam, Chennai, Tamil Nadu 600035)</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <div className='white-box text-center'>
                                            <center><img src='/images/CS-supp.png' className='img-fluid mb-3' alt='' /></center>
                                            <p className='gru-clr'>For Support:</p>
                                            <h6 className='belu-clr mt-2'><b><img src='/images/Cs-Mail.png' alt='' className='img-fluid mr-2' />tech@startuptn.in</b></h6>
                                            <p className='gru-clr'>Quick Links</p>
                                            <center>
                                                <div className='d-flex justify-content-center'>
                                                    <img src='/images/Cs-fb.png' className='img-fluid' alt='' />
                                                    <img src='/images/Cs-insta.png' className='img-fluid' alt='' />
                                                    <img src='/images/Cs-link.png' className='img-fluid' alt='' />
                                                    <img src='/images/Cs-twitter.png' className='img-fluid' alt='' />
                                                    <img src='/images/cs-whatsapp.png' className='img-fluid' alt='' />
                                                    <img src='/images/Cs-yu.png' className='img-fluid' alt='' />
                                                </div>
                                            </center>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <img src='/images/cs-map.png' className='img-fluid mb-5' alt='' />
                                    </Grid>
                                </Grid>
                            </div> */}
                        {/* </Container> */}
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    )
}

export default ResourcePage