import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Divider,
  Grid,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Bilingualcallpage = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [activeTab, setActiveTab] = useState("0");

  const [value, setValue] = useState("0");
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };

  const handlenow = () => {
    navigate('/login')
  }

  return (
    <div className="smartCard-page">
      {/* <Navbar /> */}
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="banner-text">
                <div className="sm-banner-chip-text1">
                  <span>{`Support    >  Bi - Lingual Call Centre `}</span>
                  <h1 className="banner-text-gradient">
                    Bi - Lingual Call Centre{" "}
                  </h1>
                  <h6 className="fund-title">
                    Around-the-clock support for Entrepreneurs
                  </h6>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="smart-card-info1">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            className="tanseed-info1"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage1">
                <h4>What is Bi-Lingual Call Centre ?</h4>
                <p>
                The Bi-Lingual helpline is designed to support and empower founders all around the clock. Whether you’re navigating the struggling phase of entrepreneurship or steering your venture towards new horizons, StartupTN is your trusted ally, providing expert guidance, timely assistance, and unwavering support, 24/7.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="smartcrdimag img-fluid"
                  src={"/images/startupLogo.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>
          {/* <div className="smartcrd-details-div ">
            <div className="">
              <div className="">
                <img
                  src="/images/TANSEED 6.0.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className=""></div>
            </div>
          </div> */}
        </Container>
        <div className="join-us-now-sec1">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h4>Bi-Lingual Call Centre</h4>
                    <p>Around-the-clock support for Entrepreneurs</p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Bilingualcallpage;
