import { Button, Dialog, DialogContent, DialogTitle, FormHelperText, Grid, TablePagination, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";
import Servicepartnersfilter from "./Servicepartnersfilter";
import { Controller, useForm } from "react-hook-form";
import { FaRegEye } from "react-icons/fa";
import Viewimage from "../../../api/Viewimage";

const Servicepartnerslist = () => {
  const { navigator, setPageLoading, isWhitespace } = useContext(DataContext);
  const { handleSubmit, control, getValues, setValue, clearErrors, reset, formState: { errors } } = useForm({});

  const [searchInputValue, setSearchInputValue] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [servicePartnerList, setServicePartnerList] = useState([]);
  const [page, setPage] = useState(0);
  const [filterVal, setFilterVal] = useState(0)
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [servicePartneremail, setServicePartneremail] = useState("")
  const [selectedPersonas, setSelectedPersonas] = useState("")
  const [feedback, setFeedback] = useState("")
  const [servicePartnerId, setServicePartnerId] = useState("")
  const [reasonDialog, setReasonDialog] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const servicePartnerDatas = [
    { id: 1, status: "Pending" },
    { id: 2, status: "Approved" },
    { id: 3, status: "Rejected" }
  ];
  useDidMountEffect(() => {
    listApi();
  }, [page, searchInputValue, filterVal, selectedPersonas, rowsPerPage]);

  const listApi = () => {
    setServicePartnerList([])
    setPageLoading(true);
    var data = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      searchString: searchInputValue,
      persona: selectedPersonas,
      status: filterVal
    };
    apiService("smartcard/admin/servicepartner/list", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setCount(result.data.count)
          // setTotalPage(result.data.totalPages);
          if (result.data.servicePartnerUserList) {
            var data = result.data.servicePartnerUserList

            data.forEach(element => {
              if (element.email) {
                element.email = cryptodecryptJS(element.email);
                setServicePartneremail(element.email)
              }
              if (element.phone) {
                element.phone = cryptodecryptJS(element.phone);
              }
            });
            setServicePartnerList(data);
          }
        }
      })
      .catch((err) => { });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleUpdateStatus = (id, value) => {
    setPageLoading(true);

    const data = {
      status: value,
      smartcardId: id,
    };

    if (value === 3) {
      data.feedback = feedback;
    }
    apiService("smartcard/status/update", data, "post")
      .then((res) => {
        if (res && res.data && res.data.responseStatus === "Success") {
          setPageLoading(false);
          handleUpdateClose()
          listApi();
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateClose = () => {
    setReasonDialog(false)
    reset()
  }
  const handleUpdaterejectStatus = (id) => {
    setServicePartnerId(id);
    setReasonDialog(true);
  };
  const resontosubmit = (data) => {
    setFeedback(data.feedback);
    handleUpdateStatus(servicePartnerId, 3);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");
  const [open, setOpen] = useState(false);
  const handleClickOpen = (value) => {
    // setViewImage(name);
    setViewImageName(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Servicepartnersfilter
        setSearchInputValue={setSearchInputValue}
        filterVal={filterVal}
        setFilterVal={setFilterVal}
        page={page}
        servicePartnerList={servicePartnerList}
        searchInputValue={searchInputValue}
        selectedPersonas={selectedPersonas}
        setSelectedPersonas={setSelectedPersonas}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
      />
      <div className="event-datas-list py-5">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={4}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="table_main_div">
              {servicePartnerList.length > 0 ? (
                <>
                <div className="ml-auto">
                <TablePagination
                      component="div"
                      count={count}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      style={{ float: 'right', margin: '0px' }}
                      sx={{ margin: 0 }}
                    />
                </div>
                  <TableContainer component={Paper}>
                    <Table sx={{ borderCollapse: "separate", borderSpacing: "0 10px" }}>
                      <TableHead>
                        <TableRow className="titles1">
                          <TableCell >Name</TableCell>
                          <TableCell >Mail ID</TableCell>
                          <TableCell>Phone Number</TableCell>
                          <TableCell align="center">EOI Document</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center" >Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {servicePartnerList && servicePartnerList.map((list, i) => (
                          <>
                            <TableRow
                              key={i}
                              sx={{
                                position: 'relative',
                                '&:after': {
                                  content: '""',
                                  position: 'absolute',
                                  top: 0,
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  border: '1px solid rgba(224, 224, 224, 1)',
                                  borderRadius: '20px',
                                  pointerEvents: 'none',
                                  marginBottom: '-1px',
                                },
                                '& > *': {
                                  padding: '10px',
                                  backgroundColor: '#fff',
                                  '&:last-of-type': { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' },
                                },
                                marginBottom: '10px',
                              }}
                              className="Sup-list"
                            >
                              <TableCell className='list-1' >
                                <div
                                // onClick={() => handleProfileNavigate(list.id, list.userId)}
                                >
                                  <span className="list-1-clr"  >
                                    {/* {list.name ? list.name : '-'} */}
                                    {list.name ? (
                                      list.name.length > 20 ? (
                                        <>{list.name.slice(0, 20)}...</>
                                      ) : (
                                        <>{list.name}</>
                                      )
                                    ) : (
                                      <p>-</p>
                                    )}
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <span className="list-ran">
                                  {list.email ? list.email : '-'}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span className="list-ran">
                                  {list.phone ? list.phone : '-'}
                                </span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="list-ran">
                                  <img src="/images/Banner/eye.gif" alt="view_Document" style={{ width: '100%', maxWidth: '35px' }} onClick={() => handleClickOpen(list.eoiDoc)} />
                                </span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="list2-ran">
                                  {/* {(list.smartcardstatus === 0) && (
                                    <p >-</p>
                                  )} */}
                                  {(list.status === 1) && (
                                    <p className="list-6-clr-smcr">Pending</p>
                                  )}
                                  {(list.status === 3) && (
                                    <p className="list-5-clr-smcr">Rejected</p>
                                  )}
                                  {(list.status === 2) && (
                                    <p className="list-5-clr-smc">Approved</p>)}
                                </span>
                              </TableCell>
                              {
                                (list.status === 1) ? (
                                  <TableCell align="center">
                                    <span className="list2-ran">

                                      <button className="button-smart1" variant="contained" onClick={() => handleUpdateStatus(list.smartCardId, 2)}>Approve</button>
                                      <button className="button-smart2" variant="contained" onClick={() => handleUpdaterejectStatus(list.smartCardId, 3)}>Reject</button>

                                    </span>
                                  </TableCell>
                                ): (
                                  <>
                                    <TableCell align="center">
                                      -
                                    </TableCell>
                                  </>
                                )
                              }

                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>

                    <TablePagination
                      component="div"
                      count={count}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      style={{ float: 'right', margin: '0px' }}
                      sx={{ margin: 0 }}
                    />
                  </TableContainer>
                </>
              ) : (
                <>

                  <div className='text-center nodatacss_admin'>
                    <h5 className='mb-4'>Service Partners List not found</h5>
                    <div>
                      <img src="/images/blue horizontal.png" alt="blue horizontal.png" className='logo_dash img-fluid' />
                    </div>
                    <div className='mt-5'>
                      <h3 className='title_p opa'>
                        StartupTN 3.0
                      </h3>
                    </div>
                  </div>
                </>
              )
              }
            </div>
          </Grid>
        </Grid>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={reasonDialog}
        // onClose={handleUpdateClose}
        >
          <DialogContent>
            <form onSubmit={handleSubmit(resontosubmit)}>
              <DialogTitle>Reason to Reject</DialogTitle>
              <DialogContent>
                <div>
                  <div className='my-3'>
                    <Controller
                      name="feedback"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: 'purpose is required', validate: {
                          noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                        },

                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="outlined-multiline-static"
                          label="Enter your Reason *"
                          fullWidth
                          multiline
                          rows={4}
                          inputProps={
                            { maxLength: 500 }
                          }
                          defaultValue=""
                          error={Boolean(errors.feedback && errors.feedback)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.feedback && errors.feedback.message}
                    </FormHelperText>
                  </div>
                </div>
              </DialogContent>
              <div className="d-flex align-items-center justify-content-center">
                <div className='text-center pb-3'>
                  <Button
                    variant="outlined"
                    className="edit-btn-profile"
                    sx={{
                      textTransform: "none",
                      color: "#fff",
                      padding: "6px 20px",
                      background: "#020671",
                      marginRight: '5px',
                      '&:hover': {
                        background: "#020671",
                        color: "#fff",
                      }
                    }}
                    onClick={handleUpdateClose}
                  >
                    Close
                  </Button>
                </div>
                <div className='text-center pb-3'>
                  <Button
                    variant="outlined"
                    className="edit-btn-profile"
                    sx={{
                      textTransform: "none",
                      border: "1px solid",
                      borderColor: "#020671",
                      color: "#020671",
                      padding: "6px 20px",
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>

      </div>
      <Viewimage
        viewImage={viewImage}
        viewImageName={viewImageName}
        open={open}
        handleClose={handleClose}
      />
    </div>
  )
}
export default Servicepartnerslist