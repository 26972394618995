import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { CiGlobe } from "react-icons/ci";
import { Button, Dialog, DialogContent, FormHelperText, Grid, List, Rating, TextField } from "@mui/material";
import { NavLink, Outlet, useParams } from "react-router-dom";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import { useState } from "react";
import { useContext } from "react";
import DataContext from "../../../context/DataContext";
import { FaArrowLeft } from "react-icons/fa6";
import CreateIcon from '@mui/icons-material/Create';
import { Controller, useForm } from "react-hook-form";

const SmartProfileLayout = () => {
  const { navigator, setPageLoading, getApi, userProfileId, formData, listDatas, ratingData, ratinglist, ratelist, products, setProducts, showHighlights, setshowHighlights } = useContext(DataContext)
  const { handleSubmit, control, formState: { errors }, clearErrors, reset, setError } = useForm({});
  const { id } = useParams()

  let smartCardId
  if (id !== null) {
    smartCardId = atob(id)
  }

  useDidMountEffect(() => {
    getApi();
  }, [userProfileId]);
  let smartCardKey = localStorage.getItem('smartCard')
  let userIds = localStorage.getItem('id')
  let persId = localStorage.getItem("userpersonaId");
  let token = localStorage.getItem("jwttoken");
  let joinStatus = localStorage.getItem('joinasstatus')
  let useid = localStorage.getItem('id')
  let smaridss = localStorage.getItem('joinusId')

  useDidMountEffect(() => {
    getSmartCardApi()
    if(token){
    listApi()
  }
    setProducts([])
  }, [])

  const listApi = () => {
      setProducts([])
      setPageLoading(true);
      var req = {
          pageNumber: 1,
          listSize: 20,
          requestedUserId: Number(useid),
          smartCardId: Number(smaridss)
      }
      apiService('smartcard/servicerequest/cart/list', req, "post")
          .then((res) => {
              setPageLoading(false);
              if (res && res.data) {
                  if (res.data.carts) {
                      setProducts(res.data.carts)
                  }
              }
          }).catch((err) => {
              console.log(err);

          });
  }

  const [selectedStar, setSelectedStar] = useState(0);
  const [selProf, setSelProf] = useState("")
  const [smartCardData, setSmartCardData] = useState([])
  const [rateModal, setRateModal] = useState(false)


  const getSmartCardApi = () => {
    setPageLoading(true)
    apiService(`smartcard/get?id=${smartCardId}`, '', 'get').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        if (result.data) {
          setSmartCardData(result.data);
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  const handleShowHighlights = (value) => {
    setshowHighlights(value)
  }
  const MAX_LENGTH = 20; 

  const truncateText = (text) => {
    if (text.length > MAX_LENGTH) {
      return text.substring(0, MAX_LENGTH) + '...';
    }
    return text;
  };
  const handlerateModalOpen = () => {
    if (token) {
      setRateModal(true)
      clearErrors("rating");
    } else {
      localStorage.setItem('smartCard', '')
      const currentPath = window.location.pathname;
      localStorage.setItem('currentPath', currentPath);
      navigator('/login')
    }
  }
  const handlerateModalClose = () => {
    setRateModal(false);
    clearErrors("rating");
    reset();
    setSelectedStar(0);
  };
  const onSubmit = (form) => {
    setPageLoading(true)
    if (selectedStar === 0) {
      setError("rating", { type: "manual", message: "Rating is required" });
      return;
    }
    var req = {
      ...form,
      userId: persId,
      raterUserId: userIds,
    }
    apiService('smartcard/rating/save', req, "post")
      .then((res) => {
        setPageLoading(false)
        if (res && res.data && res.data.responseStatus === "Success") {
          handlerateModalClose()
          ratelist()
        }
      }).catch((err) => {
        console.log(err)
      });
  }
  const handlecartpage = () => {
    if(token){
    navigator(`/smartcard/${id}/addcart`)
  } else {
    localStorage.setItem('smartCard', '')
    const currentPath = window.location.pathname;
    localStorage.setItem('currentPath', currentPath);
    navigator('/login')
  }
  }
  const handleBackBtn = () => {
    localStorage.removeItem('smartCard')
    localStorage.removeItem('userpersonaId')
    navigator('/smartcard/service')
  }
  return (
    <div className="backgrnd-color-service-prof">
      <div className="container-fluid flu-class">
        <div className="smartcard-prof-imgs">
          <div className="ml-5 mt-3">
            <Button className="back-btns" onClick={handleBackBtn}>
              <FaArrowLeft />
              <span style={{ marginLeft: "5px" }}>Back</span>
            </Button>
          </div>
        </div>
        <div className="user-profile">
          <div className="user-boxs">
            <div className="first-box">
              <div className="all-userdetails1">
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      className="user-brandlogo img-fluid"
                      src={
                        formData.imageName
                          ? formData.imageName
                          : "/images/user.png"
                      }
                      alt="logo"
                    />
                  </div>
                  <div className="brand-details ml-3">
                    <h4 className="font-weight-bold">
                      {formData.name}
                      <span>
                        <img src={"/images/verified-tick.png"} alt="verified" />
                      </span>
                    </h4>
                    <p className="para mb-0">
                      <span>
                        {formData.sector}
                      </span>
                    </p>
                    <span style={{ fontSize: "12px", color: "gray" }}>
                      {formData.state}, {formData.country}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="mr-2">
                      <span style={{ fontSize: "10px" }}>
                        Engagement Level :
                        <span
                          style={{
                            color: "#020671",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                        >
                          ACE
                        </span>
                      </span>
                    </div>
                    <div>
                      <span style={{ fontSize: "10px" }}>
                        Member Since :
                        <span
                          style={{
                            color: "#020671",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                        >
                          {formData.memberSince}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="det-flex mt-3">
                    <div className="mobile-resp">
                      <div>
                        <FaPhoneAlt
                          className="ml-2"
                          style={{
                            color: "#020671",
                          }}
                        />
                      </div>
                      <div>
                        <span className="ml-2" style={{ fontSize: "14px" }}>
                          +91 {formData.phone}
                        </span>
                      </div>
                    </div>
                    <div className="mobile-resp">
                      <div>
                        <MdOutlineMail
                          className="ml-2"
                          style={{
                            color: "#020671",
                          }}
                        />
                      </div>
                      <div>
                        <span className="ml-2" style={{ fontSize: "14px" }}>
                          {formData.email}
                        </span>
                      </div>
                    </div>
                    <div className="mobile-resp">
                      <div>
                        <CiGlobe
                          className="ml-2"
                          style={{
                            color: "#020671",
                          }}
                        />
                      </div>

                      <div>
                        <span className="ml-2" style={{ fontSize: "14px" }}>
                          {formData.website}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="profile-btn  mt-3">
                    {
                      smartCardKey !== 'join' && (
                        <div className="mr-3 edit-btn-res">
                          <Button
                            variant="contained"
                            className="cart-btn-profile"
                            startIcon={<img src="/images/join-us/Cart.png" alt="cart" />}
                            onClick={handlecartpage}
                          >
                            Cart
                          {products.length > 0 && token && ( <span className="badge-prof">{products.length}</span>)}
                          </Button>
                        </div>)}
                    {
                      (smartCardKey === 'join' && joinStatus === "1") && (<span className="apprevi">Application Under Review</span>)
                    }
                     {
                      (smartCardKey === 'join' && joinStatus === "2") && (<span className="apprevi-green">Application Approved</span>)
                    }
                     {
                      (smartCardKey === 'join' && joinStatus === "3") && (<span className="apprevi-red">Application Rejected</span>)
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-5">
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              {
                smartCardKey === 'join' && (
                  <div className="profile-box-filter mb-3">
                    <List
                      style={{
                        border: "1px solid gray",
                        padding: "0px",
                        display: "grid",
                      }}
                      className="Lists-prof-det"
                    >

                      <NavLink
                        to={`/smartcard/${id}/profile`}
                        className={`custom-button`}
                        onClick={() => { handleShowHighlights(true); setSelProf('Profile') }}
                      >
                        Profile
                      </NavLink>

                      <NavLink
                        to={`/smartcard/${id}/product`}
                        className={`custom-button`}
                        onClick={() => { handleShowHighlights(true); setSelProf('Products / Service') }}
                      >
                        Products / Service
                      </NavLink>
                      <NavLink to={`/smartcard/${id}/service`} className={`custom-button`}
                        onClick={() => { handleShowHighlights(false); setSelProf('Service Request') }}>
                        Service Request
                      </NavLink>
                      <NavLink
                        to={`/smartcard/${id}/rate-review`}
                        className={`custom-button`}
                        onClick={() => { handleShowHighlights(false); setSelProf('Ratings & Reviews') }}
                      >
                        Ratings & Reviews
                      </NavLink>
                    </List>
                  </div>
                )}
              {
                showHighlights === true && (
                  <div className="profile-box1 ">
                    <div>
                      <h6 style={{ fontWeight: "600" }}>Key Highlights</h6>
                      <ul className="p-0">
                        <li className="d-flex align-items-start justify-content-start">
                          <div className="key-img mt-2">
                            <img src={"/images/pie-chart.png"} alt="pieChart" />
                          </div>
                          <div className="ml-3">
                            <>
                              <span className="list-span">Specialized in Sector</span>
                            </>
                            <>
                              <p className="list-para ">{formData.sector ? formData.sector : 'NA'}</p>
                            </>
                          </div>
                        </li>
                        <li className="d-flex align-items-start justify-content-start">
                          <div className="key-img mt-2">
                            <img src={"/images/grow-up.png"} alt="pieChart" />
                          </div>
                          <div className="ml-3">
                            <>
                              <span className="list-span">Product / Service</span>
                            </>
                            {listDatas.slice(0, 3).map((a, i) => (
                              <div key={i}>
                                <p className="list-para mb-0">
                                  {truncateText(a.name)}
                                  {i < 2 && listDatas.length > 1 && ', '}
                                </p>
                              </div>
                            ))}
                            {listDatas.length > 3 && <p>+ more</p>}
                          </div>
                        </li>

                        <li className="d-flex align-items-start justify-content-start">
                          <div className="key-img mt-2">
                            <img src={"/images/star.png"} alt="pieChart" />
                          </div>
                          <div className="ml-3">
                            <>
                              <span className="list-span">Ratings</span>
                            </>
                            <>
                              <p className="list-para">
                                {ratingData > 0 ? (
                                  <>
                                    <span className="mr-2">
                                      <img
                                        src={"/images/star-rate.png"}
                                        alt="pieChart"
                                      />
                                    </span>
                                    {`${ratingData} / 5 (${ratinglist.length})`}
                                  </>
                                ) : (
                                  "No Ratings Available"
                                )}
                              </p>
                            </>
                          </div>
                        </li>
                        {
                          smartCardKey !== 'join' && (
                            <li className="d-flex align-items-start justify-content-center">
                              <Button variant="contained" startIcon={<CreateIcon />} sx={{ textTransform: 'none', background: '#020671', '&:hover': { background: '#020671' } }} size="small" onClick={() => handlerateModalOpen()}>
                                Write a Review
                              </Button>
                            </li>
                          )}
                      </ul>
                    </div>
                  </div>)}
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Outlet></Outlet>
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={rateModal}
      >
        <DialogContent>
          <div className="text-center">
            <img src="/images/join-us/9848264.jpg" alt="rate" className="persons-rate" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-center">
              <h4 className="mb-3">Rate your experience with  {formData.name}!</h4>
              <Controller
                name="rating"
                control={control}
                defaultValue=""
                rules={{
                  required: "Rating is Required",
                }}
                render={({ field }) => (
                  <Rating
                    {...field}
                    size="large"
                    name="simple-controlled"
                    value={selectedStar}
                    onChange={(event, newValue) => {
                      setSelectedStar(newValue);
                      if (newValue !== null) {
                        field.onChange(newValue);
                        clearErrors("rating");
                        console.log(newValue);
                      }
                    }}
                  />
                )}
              />
              <FormHelperText className="text-danger" sx={{ textAlign: 'center' }}>
                {errors.rating && errors.rating.message}
              </FormHelperText>
            </div>
            {/* <p>How was your experience about our product?</p> */}
            <div className='my-3'>
              <Controller
                name="comment"
                control={control}
                defaultValue=""
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <TextField
                    id="outlined-multiline-static"
                    placeholder="Enter your Feedback "
                    label="Feedback"
                    fullWidth
                    multiline
                    {...field}
                    rows={4}
                    inputProps={
                      { maxLength: 500 }
                    }
                    defaultValue=""
                    error={Boolean(errors.comment && errors.comment)}
                  />
                )}
              />
              <FormHelperText className="text-danger">
                {errors.comment && errors.comment.message}
              </FormHelperText>
            </div>
            <div className="text-center">
              <button onClick={() => handlerateModalClose()} className="rate-clo-btn">Close</button>
              <button type="submit" className="rate-sub-btn">Rate Now</button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SmartProfileLayout;
