import React, { useContext, useState } from "react";
import { Grid, Dialog, DialogContent, IconButton, DialogTitle, Container, Stack, Pagination } from "@mui/material";
import SmartproductAdd from "./SmartproductAdd";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { useParams } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import CloseIcon from "@mui/icons-material/Close";

const SmartProductList = () => {
  const { navigator, setPageLoading, validateEmail, listDatas, setListDatas, setProdName } = useContext(DataContext)

  const { id } = useParams()
  let ids
  if (id !== null) {
    ids = atob(id)
  }
  const [opened, setOPened] = useState();
  const [offerOpened, setOfferOpened] = useState();
  const [reasonOpen, setReasonOpen] = useState(false)
  const [privilegesData, setPrivilegesData] = useState([])
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  let useIds = localStorage.getItem('id')

  const handlepageChange = (event, value) => {
    setPage(value);
  };

  useDidMountEffect(() => {
    getSmartCardApi()
    listApi()
  }, [page])
  const getSmartCardApi = () => {
    apiService(`smartcard/get?id=${ids}`, '', 'get').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        if (result.data) {
          if (result.data.privileges) {
            setPrivilegesData(result.data.privileges)
          }
        }
      }
    }).catch((err) => {

    });
  }
  let smartCardKey = localStorage.getItem('smartCard')
  const handleReasonOpen = () => {
    setReasonOpen(true)
  }
  const handleReasonClose = () => {
    setReasonOpen(false)
  }
  const idsmartcrd = localStorage.getItem('joinusId')
  const handleOpened = () => {
    // setOPened(true);
    navigator(`/smartcard/joinuspartner/${btoa(ids)}/add/${btoa(idsmartcrd)}`)
  };

  const handleClosed = () => {
    setOPened(false);
  };

  const handleAvai = (id) => {
    const smartpath = window.location.pathname
    localStorage.setItem('smartpath', smartpath)
    debugger
    navigator(`/smartcard/addCart/${btoa(ids)}`)
  }




  const listApi = () => {
    setPageLoading(true)

    setPageLoading(true)
    var req = {
      listSize: 6,
      smartcardId: Number(idsmartcrd),
      pageNumber: page,
      requestedUserId: Number(useIds),
    }
    apiService(`smartcard/product/list`, req, "post")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          setTotalPage(result.data.totalPages)
          if (result.data.products) {
            var data = result.data.products;
            console.log(data)
            setProdName(data.name)
            setListDatas(data)
          }
        }

      })
      .catch((err) => {
        console.log(err)
      });

  };
    const [isFullTextVisible, setIsFullTextVisible] = useState({});
    const truncateTexts = (text, charLimit) => {
      return text.length > charLimit ? text.substring(0, charLimit) + "..." : text;
    };
    // Toggle function for each item
    const toggleText = (index) => {
      setIsFullTextVisible(prevState => ({
        ...prevState,
        [index]: !prevState[index], // Toggle visibility for the specific item
      }));
    };
  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={3}
      >
        <Grid item xs={12} sm={12}>
          {
            listDatas.length > 0 ? (
              <div className="pb-5 ">
                <div className="profile-box-joincrd-3">
                  <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="join-grey-box">
                        <span>Products / Services</span>
                      </div>
                    </Grid>
                    {listDatas && listDatas.map((item, index) => (
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
                          <div className={'join-cards'}>
                            <div className="crd-j-tit">
                              <span><b>{item.name}</b></span>
                              <p>{item.description}</p>
                            </div>
                            <div className="pricing-details my-3">
                              {item.priceRangeTo ? (<p>Market Price: <span>Rs. &nbsp;{item.priceRangeFrom} - {item.priceRangeTo}</span></p>) : (<p>Market Price: <span>Rs. &nbsp;{item.priceRangeFrom}</span></p>)}
                             
                              {
                                item.priceType === "Fixed" && item.offerType === "Discount" && (
                                  <>
                                    <p>
                                      Smart Card Price:
                                      <span className='free-join'>
                                        {
                                          `Rs. ${item.discountedPriceFrom}`}
                                      </span>
                                    </p>
                                  </>
                                )
                              }
                              {
                                item.priceType === "Range" && item.offerType === "Discount" && (
                                  <>
                                    <p>
                                      Discount :
                                      <span className='free-join'>
                                        <span className='free-join'>

                                          {item.discount} %

                                        </span>
                                      </span>
                                    </p>
                                  </>
                                )
                              }
                              {
                                item.priceType === "Fixed" && item.offerType === "Price Cut" && (
                                  <>
                                    <p>
                                      Smart Card Price:
                                      <span className='free-join'>
                                        {
                                          `Rs. ${item.discountedPriceFrom}`}
                                      </span>
                                    </p>
                                  </>
                                )
                              }
                              {
                                item.priceType === "Range" && item.offerType === "Price Cut" && (
                                  <>
                                    {item.discountedPriceTo > 0 ? (<p>
                                      Smart Card Price:
                                      <span className='free-join'>

                                        Rs. {item.discountedPriceFrom} - {item.discountedPriceTo}
                                      </span>
                                    </p>) : (<p>
                                      Smart Card Price:
                                      <span className='free-join'>

                                        Rs. {item.discountedPriceFrom}
                                      </span>
                                    </p>)}
                                  </>
                                )
                              }
                              {
                                item.offerType === "Free" && (
                                  <>
                                    <p>
                                      Smart Card Price:
                                      <span className='free-join'>
                                        Free
                                      </span>
                                    </p>
                                  </>
                                )
                              }
                               <p>
                                Offer Duration: {item.enableOfferDuration !== false ? (
                                  <span>{item.offerTime}&nbsp;{item.offerDuration}</span>
                                ) : (
                                  <span>-</span>
                                )}
                              </p>

                              <p style={{ borderBottom: 'none', height: '70px' }}>
                                {/* {item.additionalChargeDesc ? `${item.additionalChargeDesc} (Additional Charges)` : "Additional Charges"} */}
                                {item.additionalChargeDesc ?  (
                                  <>
                                    <p style={{ borderBottom: 'none' }}>
                                      <div style={{width:'177px'}}>
                                      {isFullTextVisible[index] ? item.additionalChargeDesc : truncateTexts(item.additionalChargeDesc, 10)}
                                      <span>(Additional Charges)</span>
                                      <div>
                                      {item.additionalChargeDesc.length > 10 &&(<span
                                        onClick={() => toggleText(index)}
                                        style={{ cursor: "pointer", color: "#7F9AD2", }}
                                      >
                                        {isFullTextVisible[index] ? " Read Less" : " Read More"}
                                      </span>)}
                                      </div>
                                      </div>
                                    </p>
                                  </>
                                ):("Additional Charges")}
                                {item.additionalCharges === false && (<span>
                                  {"NA"}
                                </span>)}
                                {item.additionalChargePrice ? (
                                  <div className='text-right'>
                                    {item.additionalChargeDesc && item.additionalChargePrice ? `Rs.${item.additionalChargePrice}` : ""}
                                  </div>) : (<></>)}
                              </p>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                  <div className="event-pagination">
                    <Stack spacing={2}>
                      <Pagination
                        count={totalPage}
                        page={page}
                        onChange={handlepageChange}
                      />
                    </Stack>
                  </div>
                </div>
              </div>
            ) : (
              <div className="profile-box3 mt-2">
                <div className="text-center">
                  <h5 style={{ fontWeight: "600" }}>No Data Found</h5>
                </div>
              </div>
            )
          }
        </Grid>
      </Grid>
      <Dialog
        open={opened}
        // onClose={handleClosed}
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            p: 2,
            borderRadius: 3,
          },
        }}
      >
        <SmartproductAdd open={opened} onClose={handleClosed} getSmartCardApi={getSmartCardApi} />
      </Dialog>
      <Dialog
        open={reasonOpen}
        onClose={handleReasonClose}
        maxWidth="xs"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            p: 2,
            borderRadius: 3,
          },
        }}
      >
        <DialogTitle>
          Rejection Reason
          <IconButton
            aria-label="close"
            onClick={handleReasonClose}
            sx={{
              position: "absolute",
              right: 44,
              top: 30,
              backgroundColor: "red",
              borderRadius: "50%",
              color: "white",
              padding: "1px",
              '&:hover': {
                backgroundColor: "red",
                color: "white",
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="text-center mt-2">
            <h6>Offer High</h6>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SmartProductList;