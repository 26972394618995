import React, { useContext, useState } from 'react'
import AdminLayoutsidebar from './AdminLayoutsidebar'
import { Box, Dialog, Drawer, Menu, MenuItem, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DataContext from '../../context/DataContext';
import Changepassword from '../common-pages/Changepassword';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { FaArrowLeft } from "react-icons/fa";
const AdminLayoutNavbar = () => {
    const { navigator, checkSessionTimout } = useContext(DataContext);
    useDidMountEffect(() => {
        checkSessionTimout()
    }, [])
    const [open, setOpen] = useState(false);
    let continueWith = localStorage.getItem('continueWith')
    let PersonaType = localStorage.getItem('role')
    let name = 'Admin'
    name = localStorage.getItem('name')
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    const [anchorElUser, setAnchorElUser] = useState(null);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleDashboardPage = () => {
        navigator('/admin')
    }
    const logoutBtn = () => {
        handleCloseUserMenu()
        localStorage.clear()
        sessionStorage.clear()
        navigator('/')
    }
    const [openPassword, setOpenPassword] = useState(false);
    const handlePasswordOpen = () => {
        setOpenPassword(true);
    }
    const handlePasswordClose = () => {
        setOpenPassword(false);
    }

    // const handleNavigate = ()=>{
    //     navigator('/')
    // }

    return (
        <>
            <nav className='admin-navbar'>
                <div className='set-flex'>
                    <Box sx={{ display: { md: 'none', xs: 'block' }, color: 'white', alignItems: 'center' }}>
                        <MenuIcon onClick={toggleDrawer(true)}
                            className="mr-3" />
                        {/* <img 
                        src="/images/menu_icon.png"
                        alt=""
                    /> */}
                    </Box>
                    {/* <Box sx={{marginRight:'15px',cursor:'pointer'}}>

                        <span style={{color:'#fff'}}><FaArrowLeft style={{marginRight:'5px'}}/>Back</span>
                    </Box> */}

                    {/* <Box className='search_div' sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <img src='/images/admin/search-white.png' alt='' className='navsearch_icon' />
                        <input placeholder='Search' className='navbar-search' />
                    </Box>  */}
                    <div className='ml-auto nav_menu_div'>
                        <ul>
                            {/* <li>
                                <img src='/images/admin/setting.png' alt='' className='nav_menu_icon' />
                            </li> 
                            <li>
                                <img src='/images/admin/bell.png' alt='' className='nav_menu_icon' />
                            </li> */}
                            <li>


                                <span className='username_span'>Welcome {name}</span>
                                <div className='menu-divider'></div>
                                {/* <img src='/images/admin/profile.png' alt='' className='nav_menu_icon c-pointer' onClick={handleOpenUserMenu} /> */}
                                {PersonaType !== "SUPER-ADMIN" && (
                                    <div onClick={handleDashboardPage} className='c-pointer'>
                                        <span className='username_span' >Dashboard</span>
                                    </div>)}
                                {
                                    continueWith === 'false' && (
                                        <div onClick={handlePasswordOpen} className='c-pointer'>
                                            <span className='username_span'>Change Password</span>
                                        </div>
                                    )
                                }
                                 <div className='menu-divider'></div>
                                
                                <div onClick={logoutBtn} className='c-pointer'>
                                    <span className='username_span'>Logout</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    {PersonaType !== "SUPER-ADMIN" && (<MenuItem onClick={handleDashboardPage}>
                        <Typography textAlign="center">Dashboard</Typography>
                    </MenuItem>)}
                    {
                        continueWith === 'false' && (
                            <MenuItem onClick={handlePasswordOpen}>
                                <Typography textAlign="center">Change Password</Typography>
                            </MenuItem>
                        )
                    }
                    <MenuItem onClick={logoutBtn} >
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                </Menu>
                <Drawer open={open} onClose={toggleDrawer(false)}>
                    <AdminLayoutsidebar onClose={toggleDrawer(false)} />
                </Drawer>
            </nav >
            <Dialog open={openPassword} maxWidth="md">
                <Changepassword handlePasswordClose={handlePasswordClose} />
            </Dialog>
        </>

    )
}

export default AdminLayoutNavbar