import React from "react";
import {
  Container,
  Grid,
  Button,
  Card,
  CardMedia,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Resourcestab = () => {
  const navigate = useNavigate();

  const handlenow = () => {
    navigate('/login')
  }
  
  const cardDatas = [
    {
      logos: "/images/tncatayst_logos/idex.png",
      desc: "iDEX aims at creation of an ecosystem to foster innovation and technology development in Defence and Aerospace by engaging Industries including MSMEs, start-ups, individual innovators, R&D institutes & academia and provide them grants/funding and other support to carry out R&D which has good potential for future adoption for Indian defence and aerospace needs. Currently6 Incubators are partnering with...",
    },
    {
      logos: "/images/tncatayst_logos/biroc.png",
      desc: "BIRAC is a industry-academia interface and implements its mandate through a wide range of impact initiatives, be it providing access to risk capital through targeted funding, technology transfer, IP management and handholding schemes that help bring innovation excellence to the biotech firms and make them globally competitive. Tamil Nadu is equipped ...",
    },
    {
      logos: "/images/tncatayst_logos/entrep.png",
      desc: "Entrepreneurs-in- Residence (EIR) Programme is one of the programs introduced under NIDHI to inspire the best talents to be entrepreneurs, to minimise the risk involved in pursuing start- ups, and to partially set off their opportunity costs of high paying jobs. Tamil Nadu is equipped with10 EIR Centres.",
    },
    {
      logos: "/images/tncatayst_logos/nidhi.png",
      desc: "NIDHI-PRAYAS a pre-incubation initiative that would be implemented through a Program Management Unit (PMU) and the incubators selected for implementing the PRAYAS progam will be the PRAYAS Centres (PCs). The program will also help incubators source the potential incubatee companies. Tamil Nadu is equipped with 11 PRAYAS Centres.",
    },
    {
      logos: "/images/tncatayst_logos/seed.png ",
      desc: "DPIIT has created Startup India Seed Fund Scheme (SISFS) with an outlay of INR 945 Crore to provide financial assistance to startups for Proof of Concept, prototype development, product trials, market entry, and commercialization.It will support an estimated 3,600 entrepreneurs through 300 incubators in the next 4 years. Currently 22 Tamil Nadu Incubators are implementing SISFS.",
    },
    {
      logos: "/images/tncatayst_logos/govt.png",
      desc: "The Department of Science & Technology (DST) was established in May 1971, with the objective of promoting new areas of Science & Technology and to play the role of a nodal department for organising, coordinating and promoting S&T activities in the country.29 incubators in Tamil Nadu are recognised and supported as TBI’s, i-TBIs by DST so far.",
    },
    {
      logos: "/images/tncatayst_logos/g20.png ",
      desc: "Atal Innovation Mission (AIM) is Government of India’s flagship initiative to create and promote a culture of innovation and entrepreneurship across the length and breadth of our country. 10 incubators in Tamil Nadu are recognised and supported by AIM as AICs and ACICs.",
    },
  ];

  return (
    <div>
      <div className="inc-tab-bgimg">
        <div className="resource-crds mt-5  ">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={5}
            >
              {cardDatas &&
                cardDatas.map((a, i) => (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div className="resource-card-divs">
                      <div className="text-center">
                        <img
                          src={a.logos}
                          title="logos"
                          className="res-car-img"
                          alt=""
                        />
                      </div>
                      <Divider />
                      <div className="resource-paras mt-2">
                        <p>{a.desc}</p>
                      </div>
                      <div className="text-center mt-5">
                        <Button
                          variant="contained"
                          className="smnew-crd-actions"
                          // onClick={() =>
                          //   handleKnowMoreClick(service.smartCardId)
                          // }
                        >
                          Know More
                        </Button>
                      </div>
                    </div>
                  </Grid>
                ))}
            </Grid>
          </Container>
        </div>
        <div>
        <Container>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={5}
          className="mt-5"
        >
            <Grid  item sm={12} md={4} lg={4} xl={4}>
            </Grid>
        </Grid>
       </Container>
      </div>
        </div>
      <div className="join-us-now-sec">
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="join-us-now-box">
                <div className="join-us-now-content">
                  <h3>Let’s Get Started</h3>
                  {/* <p>
                    Smart Card support our startup community, we are seeking
                    service partners to provide discounts on their products and
                    services.
                  </p> */}
                  <Button
                    variant="contained"
                    size="large"
                    className="join-now-btn"
                    onClick={handlenow}
                  >
                    Join us Now
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Resourcestab;
