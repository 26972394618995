import {
  Button,
  Card,
  Grid,
  Container,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import React, { useContext, useState } from "react";
import Map from "./MapPage";
import { useNavigate } from "react-router-dom";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DataContext from "../../../../context/DataContext";
import { Controller, useForm } from "react-hook-form";

const IncubationTab = () => {
  const { navigator, setPageLoading } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });
  const navigate = useNavigate();
  useDidMountEffect(() => {
    dropdownApi();
    dropdistrictApi();
  }, []);
  const [sectors, setSectors] = useState([]);
  const dropdownApi = () => {
    apiService(`home/sector/list`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.sectors) {
              setSectors(result.data.sectors);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [district, setDistrict] = useState([]);
  const dropdistrictApi = () => {
    var req={
      lang:"ENG"
    }
    apiService(`home/district/list1`, req, "unauthpost")
      .then((result) => {

        if (result) {
          if (result.data) {
            if (result.data.districts) {
              var values = result.data.districts;
              setDistrict(values);
            }
          }
        }
      })
      .catch((err) => { });
  };

  const onSubmit = (data) => {
    setPageLoading(true)
    const sector = sectors.filter(val => val.name === data.sector);
    data.sectorId = sector[0].id.toString()
    debugger
    apiService("userprofile/save", data, "post")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data && result.data.responseStatus === "Success") {
          navigator("/startups/address");
        }
      })
      .catch((err) => { });
  };

  const catlystCrdimage = [
    {
      images: "/images/tncatayst_logos/acic-logo.png",
    },
    {
      images: "/images/tncatayst_logos/ACIC MAR.png",
    },
    {
      images: "/images/tncatayst_logos/AUIF Logo 1.png",
    },
    {
      images: "/images/tncatayst_logos/AIC.png ",
    },
    {
      images: " /images/tncatayst_logos/AIC RAISE.png",
    },
    {
      images: "/images/tncatayst_logos/AIHT.png",
    },
  ];

  const handleSelectSector = (value) => {
    setValue("sector", value);
    clearErrors("sector")
  };

  const handleSelectDistrict = (value) => {
    setValue("district", value);
    clearErrors("district");
  };

  const handlenow = () => {
    navigate('/login')
  }

  return (
    <div>
      <div className="inc-tab-bgimg">
        <Container>
          <div className="main-tab-crd-div">
            <div className="inc-tab-title">
              <h3>Discover Incubation Centers</h3>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="search-bar-tn ">
                <div className="search-tab-img">
                  <img
                    src={"/images/sech.png"}
                    alt="search"
                    className="inc-tn-serac"
                  />
                  <input
                    type="text"
                    placeholder="Search Incubator Name"
                    className="search-input"
                  />
                </div>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ background: "#4870BF" }}
                />
                {/* <FormControl>
                  <Select
                    className="tn-cat-dropdown"
                    sx={{ border: "none", "& fieldset": { border: "none" } }}
                    displayEmpty
                    defaultValue=""

                  >
                    <MenuItem value="">
                      <em>District</em>
                    </MenuItem>
                    <MenuItem value="district1">District 1</MenuItem>
                    <MenuItem value="district2">District 2</MenuItem>
                  </Select>
                </FormControl> */}
                <Controller
                  name="district"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl sx={{ width: 150 }}>
                      <Select
                        {...field}
                        displayEmpty
                        className="tn-cat-dropdown"
                        sx={{ border: "none", "& fieldset": { border: "none" } }}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          handleSelectDistrict(event.target.value);
                        }}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <em>District</em>; // Placeholder text
                          }
                          return selected;
                        }}
                      >
                        {/* <MenuItem value="">
                          <em>District</em>
                        </MenuItem> */}
                        {district.map((district) => (
                          <MenuItem key={district.id} value={district.name}>
                            {district.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && <FormHelperText>District is required</FormHelperText>}
                    </FormControl>
                  )}
                />
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ background: "#4870BF" }}
                />
                {/* <FormControl>
                  <Select
                    className="tn-cat-dropdown"
                    sx={{ border: "none", "& fieldset": { border: "none" } }}
                    displayEmpty
                    defaultValue=""

                  >
                    <MenuItem value="">
                      <em>Sector</em>
                    </MenuItem>
                    <MenuItem value="district1">Sector</MenuItem>
                    <MenuItem value="district2">Sector</MenuItem>
                  </Select>
                </FormControl> */}
                <Controller
                  name="sector"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl sx={{ width: 150 }}>
                      <Select
                        {...field}
                        displayEmpty
                        className="tn-cat-dropdown"
                        sx={{ border: "none", "& fieldset": { border: "none" } }}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <em>Sector</em>; // Placeholder text
                          }
                          return selected;
                        }}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          handleSelectSector(event.target.value);  // If you want to handle the selection separately
                        }}
                      >
                        {/* <MenuItem value="">
                          <em>Sector</em>
                        </MenuItem> */}
                        {sectors.map((sector) => (
                          <MenuItem key={sector.id} value={sector.name}>
                            {sector.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && <FormHelperText>Sector is required</FormHelperText>}
                    </FormControl>
                  )}
                />

              </div>
            </form>
            <div className="card-catlyst py-5">
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={5}
              >
                {catlystCrdimage &&
                  catlystCrdimage.map((a, i) => (
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={i}>
                      <Card className="sup-sm-crds1-tncat">
                        <div className="service-card-back-layers1">
                          <div className="layer-crd-back-img1">
                            <div className="service-card-back-profss1">
                              <img
                                src={a.images}
                                alt="crd-img1"
                                className="service-card-back-image1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt-5">
                          <Button
                            variant="contained"
                            className="smnew-crd-actions"
                          // onClick={() =>
                          //   handleKnowMoreClick(service.smartCardId)
                          // }
                          >
                            Know More
                          </Button>
                        </div>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </div>
        </Container>
      </div>
      <div className="catlyst-map-div">
        <div className="map-sect-tn">
          <Map />
        </div>
      </div>
      <div className="join-us-now-sec">
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="join-us-now-box">
                <div className="join-us-now-content">
                  <h3>Let’s Get Started</h3>
                  {/* <p>
                    Smart Card support our startup community, we are seeking
                    service partners to provide discounts on their products and
                    services.
                  </p> */}
                  <Button
                    variant="contained"
                    size="large"
                    className="join-now-btn"
                    onClick={handlenow}
                  >
                    Join us Now
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default IncubationTab;
